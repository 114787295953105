import {ProductModel} from "../model/product.model";

export class ProductTransformer {
  static transformProduct(prodJson: any): ProductModel {
    return {
      id: prodJson.id,
      name: prodJson.name,
      description: prodJson.description,
      companyName: prodJson.company.name,
      companyId: prodJson.company.id,
      productCategory: {id: prodJson.productCategory.id, name: prodJson.productCategory.name,}
    }
  }

  static transformProducts(prodJson: any[]): ProductModel[] {
    let arr: ProductModel[] = [];
    for (let p of prodJson) {
      arr.push(ProductTransformer.transformProduct(p));
    }
    return arr;
  }
}
