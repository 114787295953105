import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from "rxjs";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {UserService} from "../../../service/user/user.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {ActivatedRoute, Router} from "@angular/router";
import {faDotCircle} from "@fortawesome/free-solid-svg-icons";
import {TextFormatService} from "../../../service/text-format/text-format.service";
import {ForumTypeEnum} from "../../../model/forum-type.enum";
import {ActivityService} from "../../../service/activity/activity.service";
import {ActivityModel} from "../../../model/activity.model";

@Component({
  selector: 'app-user-community-home',
  templateUrl: './user-community-home.component.html',
  styleUrls: ['./user-community-home.component.scss']
})
export class UserCommunityHomeComponent implements OnInit {

  userViewableData: UserDataModel;
  userViewableId: number;
  faDotCircle: any;
  forumTypeId: ForumTypeEnum = ForumTypeEnum.ALL;
  forumSub: Subscription;
  forumPostRevisionCounter: number;
  userSub: Subscription;
  userData: UserDataModel;
  pagination = 0;
  activities: ActivityModel[] = [];
  userBeingViewedData: UserDataModel;
  constructor(public userService: UserService,
              private store: Store<AppState>,
              private activatedRoute: ActivatedRoute,
              private activityService: ActivityService,
              public textFormatService: TextFormatService) {

    /*icons*/
    this.faDotCircle = faDotCircle;
    this.forumSub = this.store.select(state => state.forum.postRevisionCounter)
      .subscribe(revisionCounter => {
        if (revisionCounter > this.forumPostRevisionCounter && userService.isUserAuthenticated()) {
          this.pagination = 0;
          this.userViewableId = this.activatedRoute.snapshot.parent.params.id as number;
          this.getActivities(this.userViewableId, this.pagination, true);
        }
        this.forumPostRevisionCounter = revisionCounter;
      });

    this.userSub = this.store.select('user')
      .subscribe((user: UserModel) => {
        if (user.user) {
          this.userData = user.user;
        }
        if (user.userInView) {
          this.userBeingViewedData = user.userInView;
        }
      });

    activatedRoute.params.subscribe(value => {
      this.userViewableId = value.id;
      if (userService.isUserAuthenticated()) {
        this.getActivities(this.userViewableId, this.pagination, false);
      }
    })
  }

  ngOnInit() {
    // this.userViewableId = this.activatedRoute.snapshot.parent.params.id as number;
    // this.getActivities(this.userViewableId, this.pagination, true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.userBeingViewedData) {
      this.getActivities(this.userBeingViewedData.id, this.pagination, true);
    }
  }

  ngOnDestroy(): void {
  }

  getActivities(userId: number, page: number, includeNetworkUsers: boolean = true) {
    this.activityService.getActivities(userId, page, includeNetworkUsers, ForumTypeEnum.ALL)
      .subscribe((activities: ActivityModel[]) => {
        if (this.pagination == 0) {
          this.activities = activities;
        }else{
          this.activities = this.activities.concat(activities);
        }
      })
  }

  nextActivitiesPage() {
    this.pagination++;
    this.getActivities(this.userViewableId, this.pagination, true);
  }

}
