import {UserDataModel, UserPicture} from "../model/userModel";
import {FollowModel} from "../model/follow.model";

export class UserTransformer{
  public static convertToUser(userJson: any): UserDataModel {
    const user: UserDataModel =   {
      id: userJson.id,
      email: userJson.email,
      name: userJson.name,
      password: null,
      authority: userJson.authority,
      newsNotification: userJson.newsNotification,
      prayerNotification: userJson.prayerNotification,
      editorialNotification: userJson.editorialNotification,
      userHeaderPic: userJson.picUrl ? userJson.picUrl : null,
      aboutMe: userJson.aboutMe,
      location: {
        id: userJson.locationId,
        name: userJson.locationName
      },
      regDate: userJson.regDate,
      pictures: userJson.pictures ? UserTransformer.convertToPictureArray(userJson.pictures) : null,
      singlesNetworkStatus: userJson.singlesNetworkStatus,
      dob: userJson.dob,
      genderId: userJson.gender? userJson.gender.id : undefined,
      followers: userJson.followers ? UserTransformer.convertToFollowModelArray(userJson.followers) : undefined,
      following: userJson.following ? UserTransformer.convertToFollowModelArray(userJson.following) : undefined,
      activities: userJson.activities,
      interests: userJson.interests,
      faithInfluence: userJson.faithInfluence,
      faithStatement: userJson.faithStatement,
      lifeLesson: userJson.lifeLesson,
      personalStatement: userJson.personalStatement,
      favScripture: userJson.favScripture,
      viewCount: userJson.viewCount
    };
    return user;
  }

  public static convertToUsers(usersJson: any[]): UserDataModel[]{
    return usersJson.map(value => {
      return UserTransformer.convertToUser(value);
    })
  }

  private static convertToPictureArray(picJsonArray): UserPicture[] {
    let picArr: UserPicture[] = [];
    for (let json of picJsonArray) {
      picArr.push({
        id: json.id,
        url: json.url
      })
    }
    return picArr;
  }

  public static convertToFollowModel(json: any): FollowModel {
    return {
      id: json.id,
      userId: json.userId,
      followingId: json.followingId,
      name: json.userName,
      userPic: json.userPicUrl
    }
  }

  public static convertToFollowModelArray(jsonArr: any[]): FollowModel[] {
    return jsonArr.map(value => UserTransformer.convertToFollowModel(value));
  }

}
