import { Component, OnInit } from '@angular/core';
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {UserService} from "../../../service/user/user.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import * as moment from 'moment'
import {Moment} from "moment";
import {SetUserAction} from "../user.action";
import {SnackBarService} from "../../../service/snackbar/snack-bar.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-dating-profile-edit',
  templateUrl: './dating-profile-edit.component.html',
  styleUrls: ['./dating-profile-edit.component.scss']
})
export class DatingProfileEditComponent implements OnInit {

  loggedInUserSub: Subscription;
  loggedInUser: UserDataModel;
  isEditMode = false;
  isJoiningSingles: boolean = false;
  form: FormGroup = new FormGroup(
    {
      name: new FormControl('', [Validators.required]),
      about: new FormControl('', [Validators.required]),
      genderId: new FormControl('', [Validators.required]),
      dob: new FormControl(moment().format(), [Validators.required]),
      activities: new FormControl('', [Validators.required, Validators.minLength(20)]),
      interests: new FormControl('', [Validators.required, Validators.minLength(20)]),
      favScripture: new FormControl('', [Validators.required, Validators.minLength(20)]),
      faithInfluence: new FormControl('', [Validators.required, Validators.minLength(20)]),
      faithStatement: new FormControl('', [Validators.required, Validators.minLength(20)]),
      lifeLesson: new FormControl('', [Validators.required, Validators.minLength(20)]),
      personalStatement: new FormControl('', [Validators.required, Validators.minLength(20)])
    }
  );
  isSaving: boolean = false;
  faSpinner: any;

  constructor(private store: Store<AppState>,
              private userService: UserService,
              private snackBarService: SnackBarService) {
    this.faSpinner = faSpinner;

    this.loggedInUserSub = store.select('user', "user")
      .subscribe((loggedInUser: UserDataModel) => {
        this.loggedInUser = loggedInUser;
        this.setFormValues(this.loggedInUser);
        this.isEditMode = this.loggedInUser.singlesNetworkStatus === 1;
      })
  }

  ngOnInit() {
  }

  onLeaveSinglesClick() {
    this.userService
      .leaveSinglesNetwork(this.loggedInUser.id)
      .subscribe((res: UserDataModel) => {
        this.store.dispatch(new SetUserAction({user: res}))
      })
  }

  private setFormValues(user: UserDataModel) {
    if (user) {
      this.name = user.name;
      this.about = user.aboutMe;
      if (user && user.regDate) {
        this.dob = moment.unix(user.dob);
      }
      if (user && user.genderId) {
        this.genderId = user.genderId;
      }
      this.interests = user.interests;
      this.activities = user.activities;
      this.favScripture = user.favScripture;
      this.faithInfluence = user.faithInfluence;
      this.faithStatement = user.faithStatement;
      this.lifeLesson = user.lifeLesson;
      this.personalStatement = user.personalStatement;
    }
  }

  get nameCtl(): AbstractControl {
    return this.form.get('name')
  }

  get name(): string {
    return this.form.get('name').value.trim();
  }

  get nameCtrl(): AbstractControl {
    return this.form.get('name');
  }

  set name(val: string){
    this.form.get('name').setValue(val);
  }

  get about(): string {
    return this.form.get('about').value.trim();
  }

  get aboutCtrl(): AbstractControl {
    return this.form.get('about');
  }

  set about(val: string){
    this.form.get('about').setValue(val);
  }

  set dob(val: Moment) {
    this.form.get('dob').setValue(val);
  }

  get dob(): Moment {
    return this.form.get('dob').value;
  }

  get dobCtrl(): AbstractControl {
    return this.form.get('dob');
  }

  get genderId(): number {
    return this.form.get('genderId').value;
  }

  get genderCtrl(): AbstractControl {
    return this.form.get('genderId');
  }

  set genderId(id: number) {
    this.form.get('genderId').setValue(id);
  }

  get activitiesCtrl(): AbstractControl {
    return this.form.get('activities');
  }

  get activities(): string {
    return this.activitiesCtrl.value.trim();
  }

  set activities(value: string) {
    this.activitiesCtrl.setValue(value);
  }

  get interestsCtrl(): AbstractControl {
    return this.form.get('interests');
  }

  get interests(): string {
    return this.interestsCtrl.value.trim();
  }

  set interests(value: string) {
    this.interestsCtrl.setValue(value);
  }

  get lifeLessonCtrl(): AbstractControl {
    return this.form.get('lifeLesson');
  }

  get lifeLesson(){
    return this.lifeLessonCtrl.value.trim();
  }

  set lifeLesson(value: string) {
    this.lifeLessonCtrl.setValue(value);
  }

  get personalStatementCtrl(): AbstractControl {
    return this.form.get('personalStatement');
  }

  get personalStatement(){
    return this.personalStatementCtrl.value.trim();
  }

  set personalStatement(value: string) {
    this.personalStatementCtrl.setValue(value);
  }

  get faithStatementCtrl(): AbstractControl {
    return this.form.get('faithStatement');
  }

  get faithStatement(): string {
    return this.faithStatementCtrl.value.trim();
  }

  set faithStatement(value: string) {
    this.faithStatementCtrl.setValue(value);
  }

  get faithInfluenceCtrl(): AbstractControl {
    return this.form.get('faithInfluence');
  }

  get faithInfluence(): string {
    return this.faithInfluenceCtrl.value.trim();
  }

  set faithInfluence(value: string) {
    this.faithInfluenceCtrl.setValue(value);
  }

  get favScriptureCtrl(): AbstractControl {
    return this.form.get('favScripture');
  }

  get favScripture(): string {
    return this.favScriptureCtrl.value.trim();
  }

  set favScripture(value: string) {
    this.favScriptureCtrl.setValue(value);
  }

  onSubmitClick(){
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }
    const data = {
      name: this.name,
      about: this.about,
      locationId: this.loggedInUser.location.id,
      dob: this.dob.unix(),
      singlesNetworkStatus: 1,
      genderId: this.genderId,
      activities: this.activities,
      interests: this.interests,
      personalStatement: this.personalStatement,
      lifeLesson: this.lifeLesson,
      faithStatement: this.faithStatement,
      faithInfluence: this.faithInfluence,
      favScripture: this.favScripture
    };
    this.isSaving = true;
    this.userService.updateUserProfile(this.loggedInUser.id, data)
      .subscribe((res: UserDataModel) => {
          this.store.dispatch(new SetUserAction({user: res}))
        this.isSaving = false;
        this.snackBarService.open('Data successfully saved');
      }, error => {
        this.isSaving = false;
        this.snackBarService.open('Error occurred');
      });
  }

  onJoinSinglesClick() {
    this.isEditMode = true;
  }
}
