import {Type} from "@angular/core";
import {Action} from "@ngrx/store";
import {CompanyModel} from "../company/model/company.model";

export let SetUserOrganisationsType = '[SetUserOrganisations]'

export class SetUserOrganisations implements Action{
  readonly type = SetUserOrganisationsType
  constructor(public payload: {companies: CompanyModel[]}) {
  }
}

export type DirectoryAction = SetUserOrganisations
