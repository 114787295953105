<div class="row">

  <!--community content-->
  <div class="col-sm-12 col-md-12">
    <app-spinner *ngIf="isFetching" class="d-flex flex-row justify-content-center mt-3"
    [spinner-options]="{isSpinning: isFetching, size: '2x', classPrimary: true}"></app-spinner>
    <!--fellowship-->
    <div class="mt-3">
      {{forum}}
      <div *ngFor="let activity of activities">
        <app-activity-item [activityItem]="activity" (delete)="onDeleteHandler($event)"></app-activity-item>
      </div>
      <app-spinner *ngIf="fellowshipPageCounter>0 && isFetching" class="d-flex flex-row justify-content-center mt-3"
                   [spinner-options]="{isSpinning: isFetching, size: '2x', classPrimary: true}"></app-spinner>
      <div class=" d-flex justify-content-center my-5">

<!--        <button class="btn btn-lg btn-secondary" (click)="nextFellowshipItemsPage()">More</button>-->
        <div>
          <a *ngIf="pageNumber > 0" mat-button mat-raised-button [href]="'/community/fellowship/'+forumTypeId+'/'+prevPage" class="mr-3">Back</a>
          <a  *ngIf="activities.length > 0" mat-button mat-raised-button [href]="'/community/fellowship/'+forumTypeId+'/'+nextPage">Next</a>
        </div>

      </div>
    </div>
  </div>
</div>
