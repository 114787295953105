import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ArticleService} from "../../../service/article/article.service";
import {ArticleModel} from "../../../model/article.model";
import {DomSanitizer, Meta, Title} from "@angular/platform-browser";
import {Nl2BrPipeModule} from 'nl2br-pipe';
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {ArticleReplyModel} from "../../../model/article-reply.model";
import {Subscription} from "rxjs";
import {UserDataModel} from "../../../model/userModel";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {TextFormatService} from "../../../service/text-format/text-format.service";

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  article: ArticleModel;
  articleReply: any;
  userSub: Subscription;
  userData: UserDataModel;
  showInvalidEntry = false;
  faSpinner: any;
  isSpinning = true;
  isSpinnerShowing = false;

  constructor(private activatedRoute: ActivatedRoute,
              private articleService: ArticleService,
              private sanitizer: DomSanitizer,
              private store: Store<AppState>,
              public textFormatService: TextFormatService,
              private titleService: Title,
              private meta: Meta) {
    this.userSub = store.select('user').subscribe(user => {
      this.userData = user.user;
    });
    this.faSpinner = faSpinner;
  }

  ngOnInit() {
    this.getArticle(this.activatedRoute.snapshot.params.id);
  }

  isNotEmpty(videoUrl: string): boolean{
    if (videoUrl && videoUrl.length > 0) {
      return true;
    }
    return false;
  }

  sanitiseVideoUrl(vid){
    return this.sanitizer.bypassSecurityTrustResourceUrl(vid);
  }

  private getArticle(id: number) {
    this.articleService.getArticle(id)
      .subscribe((article: ArticleModel) => {
        this.article = article;
        this.addMetaTags();
      })
  }

  submitReply() {
    if (!this.articleReply) {
      this.showInvalidEntry = true;
      return;
    }
    this.showInvalidEntry = false;
    this.isSpinnerShowing = true;
    this.articleService.postArticleReply(this.article.id, this.userData.id, this.articleReply)
      .subscribe((response: ArticleReplyModel) => {
        this.articleReply = '';
        this.isSpinnerShowing = false;
        this.article.articleReplies.unshift(response);
      });
  }

  private addMetaTags(){
    this.titleService.setTitle(`British Christian ${this.article.articleTypeName} | ${this.article.subject}`);
    this.meta.updateTag({ name: 'keywords', content: `British Christian ${this.article.articleTypeName}. ${this.article.subject}` });
    this.meta.updateTag({name: 'description', content: `${this.article.summary}`});

    /*face book open graph*/
    this.meta.updateTag({property: 'og:title', content: `British Christian ${this.article.articleTypeName} | ${this.article.subject}`});
    this.meta.updateTag({property: 'og:description', content: `${this.article.summary}`});
    this.meta.updateTag({property: 'og:image', content: `${this.article.editorialPic}`});
    this.meta.updateTag({property: 'og:url', content: `https://www.britishchristian.com/article/${this.article.id}`})

    /*for twitter card*/
    this.meta.updateTag({property: 'twitter:card', content: `summary`});
    this.meta.updateTag({property: 'twitter:title', content: `British Christian ${this.article.articleTypeName} | ${this.article.subject}`});
    this.meta.updateTag({property: 'twitter:description', content: `${this.article.summary.substr(0,200)}`});
    this.meta.updateTag({property: 'twitter:image', content: `${this.article.editorialPic}`});
  }
}
