import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserSearchComponent} from "./user-search/user-search.component";
import {MatCardModule} from "@angular/material/card";
import {MomentModule} from "ngx-moment";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import {StoreModule} from "@ngrx/store";
import {reducer} from './search.reducer';
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [
    UserSearchComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MomentModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    StoreModule.forFeature('search', reducer),
    MatButtonModule,
    RouterModule,
    FontAwesomeModule
  ],
  exports:[UserSearchComponent]
})
export class SearchModule { }
