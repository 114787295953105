import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private matSnackBar: MatSnackBar) {  }

  open(message: string, action: string = null, config: MatSnackBarConfig = {duration: 4000, horizontalPosition: 'center', verticalPosition: 'top'}) {
    this.matSnackBar.open(message,action, config);
  }
}
