<mat-card class="my-3">
  <mat-card-title>About</mat-card-title>
  <mat-card-content>
    <span>{{userBeingViewedData?.aboutMe}}</span>
    <mat-grid-list class="mt-2" cols="3" rowHeight="30px">
      <mat-grid-tile colspan="1">
              <span class="left-align-forced">
              <mat-icon aria-hidden="false" aria-label="Location" class="valign-icon-sub"
                        color="primary">home</mat-icon>
              Location
            </span>
      </mat-grid-tile>
      <mat-grid-tile colspan="2"><span class="left-align-forced">{{userBeingViewedData?.location.name}}</span>
      </mat-grid-tile>

      <!--Gender-->
      <!--<mat-grid-tile colspan="1"><span style="width: 100%">Gender</span></mat-grid-tile>
      <mat-grid-tile colspan="2"><span style="width: 100%">{{userViewableData?.genderId === 1 ? 'Male' : 'Female'}}</span></mat-grid-tile>-->

      <!--Member Since-->
      <mat-grid-tile colspan="1">

              <span class="left-align-forced">
                <mat-icon aria-hidden="false" aria-label="Joined" class="valign-icon-sub"
                          color="primary">badge</mat-icon>
                Joined</span>
      </mat-grid-tile>
      <mat-grid-tile colspan="2"><span
        class="left-align-forced">{{userBeingViewedData?.regDate | amFromUnix | amDateFormat:'LL'}}</span>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>

<mat-card class="mb-3" *ngIf="this.isUserSingleNetworkMember()">
  <mat-card-title>Singles Network Profile</mat-card-title>
  <mat-card-content class="mat-typography">
    <div class="form-group">
      <h3>Age:</h3>
      <p>{{this.getAge() ? this.getAge() : '-'}}</p>
    </div>
    <div class="form-group">
      <h3>Gender:</h3>
      <p>{{this.userBeingViewedData?.genderId === 1 ? 'Male' : 'Female'}}</p>
    </div>
    <div class="form-group">
      <h3>Church activities:</h3>
      <p>{{this.userBeingViewedData.activities ? this.userBeingViewedData.activities : '-'}}</p>
    </div>
    <div class="form-group">
      <h3>General interests:</h3>
      <p>{{this.userBeingViewedData.interests ? this.userBeingViewedData.interests : '-'}}</p>
    </div>
    <div class="form-group">
      <h3>How does your faith influence your life:</h3>
      <p>{{this.userBeingViewedData.faithInfluence ? this.userBeingViewedData.faithInfluence : '-'}}</p>
    </div>
    <div class="form-group">
      <h3>Favourite scriptures:</h3>
      <p>{{this.userBeingViewedData.favScripture ? this.userBeingViewedData.favScripture : '-'}}</p>
    </div>
    <div class="form-group">
      <h3>What does faith mean to you:</h3>
      <p>{{this.userBeingViewedData.faithStatement ? this.userBeingViewedData.faithStatement : '-'}}</p>
    </div>
    <div class="form-group">
      <h3>Life lessons:</h3>
      <p>{{this.userBeingViewedData.lifeLesson ? this.userBeingViewedData.lifeLesson : '-'}}</p>
    </div>
    <div class="form-group">
      <h3>What are you you looking for in a partner:</h3>
      <p>{{this.userBeingViewedData.personalStatement ? this.userBeingViewedData.personalStatement : '-'}}</p>
    </div>
  </mat-card-content>
</mat-card>

