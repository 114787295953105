import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ProductCategoryModel} from "../../model/product-category.model";
import {ProductModel} from "../../model/product.model";
import {ProductTransformer} from "../../transformer/product-transformer";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private productApi = "/web/api";
  constructor(private httpClient: HttpClient) {
  }

  getProductCategories(): Observable<ProductCategoryModel[]> {
    return this.httpClient.get<ProductCategoryModel[]>(`${this.productApi}/product-category`);
  }

  postProduct(data: {
    name: string;
    description: string;
    companyId: number;
    productCategoryId: number;
  }): Observable<any> {
    return this.httpClient
      .post(`${environment.webApiNamespace}/product`, data);
  }

  getProductsForOrganisation(organisationId: number): Observable<ProductModel[]> {
    const url = `${environment.webApiNamespace}/product/organisation/${organisationId}`
    return this.httpClient.get<ProductModel[]>(url);
  }

  getProduct(id: number): Observable<ProductModel> {
    const url = `${environment.webApiNamespace}/product/${id}`
    return this.httpClient.get<ProductModel>(url)
      .pipe(
        map(res => {
          return ProductTransformer.transformProduct(res);
        })
      )
  }

  editProduct(data: {
    id: number,
    name: string;
    description: string;
    companyId: number;
    productCategoryId: number;
  }): Observable<ProductModel> {
    return this.httpClient
      .put(`${environment.webApiNamespace}/product`, data)
      .pipe(
        map(res => {
          return ProductTransformer.transformProduct(res);
        })
      )
  }
}
