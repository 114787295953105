import {
  AddSinglesSearchResultType,
  SetSinglesSearchResultType, AddNonSinglesSearchResultType, SetNonSinglesSearchResultType
} from "./seach.action";
import {SearchModel} from "../../model/search.model";
import {Action} from "rxjs/internal/scheduler/Action";
import {tassign} from "tassign";
import {UserDataModel} from "../../model/userModel";
import * as SearchActions from './seach.action';

export let initialState: SearchModel = {
  singlesSearch: {users: [], pageNumber: 0, searchCriteria: {name: '', location: {id: 0, name: 'All'}}},
  nonSingleSearch: {users: [], pageNumber: 0, searchCriteria: {name: '', location: {id: 0, name: 'All'}}},
  activitySearch: []
}

export function reducer(state = initialState, actions: SearchActions.SearchActions) {
  switch (actions.type) {
    case AddSinglesSearchResultType:
      return tassign(state, {
        singlesSearch: {
          pageNumber: actions.payload.pageNumber,
          users: state.singlesSearch.users.concat(actions.payload.users),
          searchCriteria: {
            name: actions.payload.searchCriteria.name,
            location: actions.payload.searchCriteria.location}
        }
      });
    case SetSinglesSearchResultType:
      return tassign(state, {singlesSearch: {pageNumber: actions.payload.pageNumber, users: actions.payload.users,
          searchCriteria: {
            name: actions.payload.searchCriteria.name,
            location: actions.payload.searchCriteria.location}}
      }
            );
    case AddNonSinglesSearchResultType:
      return tassign(state, {
        nonSingleSearch: {
          pageNumber: actions.payload.pageNumber,
          users: state.nonSingleSearch.users.concat(actions.payload.users),
          searchCriteria: {
            name: actions.payload.searchCriteria.name,
            location: actions.payload.searchCriteria.location}
        }
      });
    case SetNonSinglesSearchResultType:
      return tassign(state, {nonSingleSearch: {pageNumber: actions.payload.pageNumber, users: actions.payload.users,
          searchCriteria: {
            name: actions.payload.searchCriteria.name,
            location: actions.payload.searchCriteria.location}}});
    default:
      return state;
  }
}
