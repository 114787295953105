import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FellowshipModel} from "../../../model/fellowship.model";
import {FellowshipreplyModel} from "../../../model/fellowshipreply.model";
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {TextFormatService} from "../../../service/text-format/text-format.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {Subscription} from "rxjs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {ForumTypeEnum} from "../../../model/forum-type.enum";
import {SetNewForumPost} from "../forum.action";
import {ForumModel} from "../../../model/forum.model";
import {ActivityService} from "../../../service/activity/activity.service";
import {ActivityModel} from "../../../model/activity.model";

@Component({
  selector: 'app-forum-stream',
  templateUrl: './forum-stream.component.html',
  styleUrls: ['./forum-stream.component.scss']
})
export class ForumStreamComponent implements OnInit, OnDestroy, OnChanges {

  forum: string;

  activeFellowshipItem: number = null;
  comment: string;
  isSavingReply: boolean = false;
  isSaving: boolean = false;
  faSpinner: any;
  userSub: Subscription;
  userData: UserDataModel;
  fellowshipListItems: FellowshipModel[] = [];
  fellowshipPageCounter = 0;
  // activeForumType: ForumTypeEnum = ForumTypeEnum.FELLOWSHIP;

  // forumSub: Subscription;
  forumStoreData: ForumModel;

  isFetching: boolean = false;
  isRefresh: boolean = false;

  activeForumType: ForumTypeEnum;
  communitySub: Subscription;
  activities: ActivityModel[] = [];
  @Input('forumTypeId') forumTypeId: ForumTypeEnum;
  forumSub: Subscription;
  pageNumber:number = 0
  nextPage: number = 0;
  prevPage: number = 0;
  forumType: number = 1;
  constructor(private activatedRoute: ActivatedRoute,
              private fellowshipService: FellowshipService,
              public textFormatService: TextFormatService,
              private store: Store<AppState>,
              private activityService: ActivityService) {
    this.faSpinner = faSpinner;

    this.userSub = this.store.select('user').subscribe((user: UserModel) => {
      if (user.user) {
        this.userData = user.user;
      }
    });

    this.forumSub = this.store.select('forum')
      .subscribe((value: ForumModel) => {
      })
  }

  ngOnInit(): void {
    const p = this.activatedRoute.snapshot.params['page'];
    this.pageNumber = Number.isNaN(Number.parseInt(p)) ? 0 : Number.parseInt(p);
    this.nextPage = this.pageNumber + 1;
    this.prevPage = this.pageNumber > 0 ? this.pageNumber -1 : 0;
    this.forumTypeId = Number.parseInt(this.activatedRoute.snapshot.params['forumType']);
    if (this.forumTypeId == undefined) {
      this.forumTypeId =  ForumTypeEnum.ALL;
    }
    this.getActivities(this.pageNumber, this.forumTypeId);
  }

  ngOnChanges() {
    // this.fellowshipPageCounter = 0;
    this.isFetching = true;
    this.getActivities(this.pageNumber, this.forumTypeId);
  }

  ngOnDestroy() {
    this.forumSub.unsubscribe();
  }

  showReplyBox(fellowshipId: number): boolean {
    return this.activeFellowshipItem && this.activeFellowshipItem === fellowshipId;
  }

  /*setActiveFellowshipItem(fellowshipId: number) {
    this.comment = null;
    this.activeFellowshipItem = fellowshipId;
  }*/

  /*submitReply(fellowshipItem: FellowshipModel) {
    this.comment.trim();

    if(!this.comment || this.comment.length == 0){
      return;
    }
    this.isSavingReply = true;
    this.fellowshipService.postFellowshipReply(this.comment, this.userData.id, fellowshipItem.id)
      .subscribe((response: FellowshipreplyModel) => {
          this.isSavingReply = false;
          fellowshipItem.replies.push(response);
          this.comment = null;
        },
        error => {
          this.isSavingReply = false;
        })
  }*/

  nextFellowshipItemsPage() {
    this.pageNumber++;
    this.isFetching = true;
    this.getActivities(this.pageNumber, this.forumTypeId);
  }

  /*
    getFellowshipItems(page: number, type: ForumTypeEnum) {
      this.isFetching = true;
      this.fellowshipService.getFellowshipList(page, type)
        .subscribe((fellowshipArray: FellowshipModel[]) => {
          if (page === 0) {
            this.fellowshipListItems = [];
            this.store.dispatch(new SetNewForumPost({newPost: null}))
          }
          this.fellowshipListItems = this.fellowshipListItems.concat(fellowshipArray);
          this.isFetching = false;
        })
    }*/

  /*  getAggregateFellowshipItems(page: number, type: ForumTypeEnum = ForumTypeEnum.ALL) {
      this.isFetching = true;
      this.fellowshipService.getAggregateFellowshipList(page, type)
        .subscribe((aggregateFellowshipModels: AggregateFellowshipModel[]) => {
          if (page === 0) {
            this.aggregateFellowshipItems = [];
            this.store.dispatch(new SetNewForumPost({newPost: null}))
          }
          this.aggregateFellowshipItems = this.aggregateFellowshipItems.concat(aggregateFellowshipModels);
          /!*if (!this.isRefresh) {
            this.aggregateFellowshipItems = this.aggregateFellowshipItems.concat(aggregateFellowshipModels);
          }else{
            this.aggregateFellowshipItems = aggregateFellowshipModels;
          }*!/
          this.isFetching = false;
        })
    }*/

  setForumType(forumPageArg: string) {
    switch (forumPageArg) {
      case 'Fellowship':
        this.activeForumType = ForumTypeEnum.FELLOWSHIP;
        break;
      case 'Prayer':
        this.activeForumType = ForumTypeEnum.PRAYER;
        break;
      case 'Testimony':
        this.activeForumType = ForumTypeEnum.TESTIMONY;
        break;
      case 'Dating':
        this.activeForumType = ForumTypeEnum.DATING;
        break;
      default:
        this.activeForumType = ForumTypeEnum.ALL;
        break;
    }
  }

  /*onAggregateRefresh(event) {
    this.getAggregateFellowshipItems(this.fellowshipPageCounter, this.activeForumType);
  }*/

  getActivities(page: number, forumTypeId: ForumTypeEnum) {
    this.activityService.getActivities(null, page, false, forumTypeId)
      .subscribe(value => {
        if (this.fellowshipPageCounter == 0) {
          this.activities = value;
        } else {
          this.activities = this.activities.concat(value);
        }
        this.isFetching = false;
      })
  }

  onDeleteHandler($event: { forumTypeId: number; originRowId: number }) {
    this.activities = this.activities.filter(value => {
      if (value.forumTypeId === 1 || value.forumTypeId === 2 || value.forumTypeId === 3 || value.forumTypeId === 5) {
        const isPost = `${value.originRowId}-${value.forumTypeId}` == `${$event.originRowId}-${$event.forumTypeId}`;
        const isPostReply = `${value.originalForumId}-${value.activityTypeId}` == `${$event.originRowId}-2`
        if (isPost || isPostReply) {
          return false;
        }
        return true;
      }
      return true;
    });
  }
}
