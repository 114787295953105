

<div class="row">
  <div class="col-sm-0 col-md-1"></div>



  <div class="col-sm-12 col-md-10">
    <nav  mat-tab-nav-bar mat-align-tabs="center" animationDuration="0ms">

      <a routerLink="." mat-tab-link
         (click)="activeLink = 'home'"
         [active]="activeLink == 'home'">
        <fa-icon [icon]="faNewspaper"></fa-icon>
        <span class="ml-1">Home</span>
      </a>
      <a routerLink="about" mat-tab-link
         (click)="activeLink = 'about'"
         [active]="activeLink == 'about'">
        <fa-icon [icon]="faAddressCard"></fa-icon>
        <span class="ml-1">About</span>
      </a>
      <a routerLink="photos" mat-tab-link
         (click)="activeLink = 'photos'"
         [active]="activeLink == 'photos'">
        <fa-icon [icon]="faImages"></fa-icon>
        <span class="ml-1">Photos</span>
      </a>
<!--      <a routerLink="search" mat-tab-link
         (click)="activeLink = 'search'"
         [active]="activeLink == 'search'">
&lt;!&ndash;        <fa-icon [icon]="faImages"></fa-icon>&ndash;&gt; &lt;!&ndash;app-user-search&ndash;&gt;
        <span class="ml-1">Search</span>
      </a>-->
    </nav>

  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>


<div class="row">
  <div class="col-sm-12">
    <router-outlet></router-outlet>
  </div>
</div>







<!--<div class="row mx-3 mx-md-0">
  <div class="col-sm-12">
    <div class="mt-5">
      <app-forum-post-panel *ngIf="userData?.id === userBeingViewedData?.id" [includeSinglesOptions]="userService.isUserInSinglesGroup(userData)"></app-forum-post-panel>
      <div *ngFor="let activity of activities">
        <app-activity-item [activityItem]="activity"></app-activity-item>
      </div>
      <div class=" d-flex justify-content-center my-5">
        <button class="btn btn-lg btn-secondary" (click)="nextActivitiesPage()">More</button>
      </div>
    </div>
  </div>
</div>-->
