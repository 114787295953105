import { Component, OnInit } from '@angular/core';
import {UserDataModel} from "../../../model/userModel";
import {UserService} from "../../../service/user/user.service";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-user-message-home',
  templateUrl: './user-message-home.component.html',
  styleUrls: ['./user-message-home.component.scss']
})
export class UserMessageHomeComponent implements OnInit {

  messageSenders: UserDataModel[];
  userSub: Subscription;
  user: UserDataModel;
  constructor(private userService: UserService,
              store: Store<AppState>) {
    this.userSub = store.select(state => state.user.user)
      .subscribe((user:UserDataModel) => {
        this.user = user;
      })
  }

  ngOnInit(): void {
    this.getMessageParticipants();
  }

  getMessageParticipants(){
    this.userService.getUserMessageParticipants(this.user.id)
      .subscribe((users: UserDataModel[]) => {
        this.messageSenders = users;
      })
  }

}
