import {ActivityModel} from "../model/activity.model";

export class ActivityTransformer {
  static convertToActivityModel(json: any): ActivityModel {
    return {
      originRowId: json.originRowId,
      forumTypeId: json.forumTypeId,
      activityTypeId: json.activityTypeId,
      datePosted: json.datePostedInMillis,
      userId: json.userId,
      userName: json.userName,
      forumMessage: json.forumMessage,
      forumPicId: json.forumPicId,
      forumPicUrl: json.forumPicUrl,
      forumReplyId: json.forumReplyId,
      originalForumId: json.originalForumId,
      userPicUrl: json.userPicUrl,
      originUserId: json.originUserId,
      originUserName: json.originUserName,
      originUserPicUrl: json.originUserPicUrl
    }
  }

  static convertArrayToActivityModel(jsonAr: any[]): ActivityModel[] {
    return jsonAr.map(value => ActivityTransformer.convertToActivityModel(value));
  }
}
