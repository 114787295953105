import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PeopleRoutingModule} from './people-routing.module';
import {PeopleGalleryComponent} from './people-gallery/people-gallery.component';
import {SearchModule} from "../search/search.module";

@NgModule({
  declarations: [PeopleGalleryComponent],
  imports: [
    CommonModule,
    PeopleRoutingModule,
    SearchModule
  ],
  exports: [
    PeopleGalleryComponent,
  ]
})
export class PeopleModule {
}
