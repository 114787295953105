import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CompanyModel} from "../model/company.model";
import {map} from "rxjs/operators";
import {CompanyTransformer} from "../../../transformer/company.transformer";
import {EmailModel} from "../model/email.model";
import {CompanyEditModel} from "../model/company-edit.model";
import {PicTypeEnum} from "../../../service/article/article.service";
import {ProductTransformer} from "../../../transformer/product-transformer";
import {LocationModel} from "../../../model/location.model";
import {BizCategoryModel} from "../../../model/biz-category.model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient: HttpClient) {


  }

  postCompany(data): Observable<any> {
    return this.httpClient.post(`${environment.webApiNamespace}/company`, data);
  }

  getCountries(): Observable<{ name: string, id: number }[]> {
    return this.httpClient.get<{ name: string, id: number }[]>('/country/list');
  }

  getCompanies(userId: number): Observable<CompanyModel[]> {
    return this.httpClient.get(`${environment.webApiNamespace}/company/list/${userId}`)
      .pipe(
        map((request: any[]) => {
          let arr: CompanyModel[] = [];
          for (let d  of request){
            let company: CompanyModel = CompanyTransformer.transformCompanyJsonToCompanyModel(d);
            arr.push(company);
          }
          return arr;
        })
      );
  }

  getCompany(companyId: number): Observable<CompanyModel> {
    return this.httpClient.get<CompanyModel>(`${environment.webApiNamespace}/company/${companyId}`)
      .pipe(
        map((request: any) => {
          let company: CompanyModel = CompanyTransformer.transformCompanyJsonToCompanyModel(request);
          return company;
        })
      );
  }

  getAllCompanies(): Observable<CompanyModel[]> {
    return this.httpClient.get(`${environment.webApiNamespace}/company/list`)
      .pipe(
        map((request: any[]) => {
          let arr: CompanyModel[] = [];
          for (let d  of request){
            let company: CompanyModel = CompanyTransformer.transformCompanyJsonToCompanyModel(d);
            arr.push(company);
          }
          return arr;
        })
      );
  }

  sendEmailEnquiry(email: EmailModel, companyId: number): Observable<any> {
    const url = `${environment.webApiNamespace}/company/email`;
    return this.httpClient.post(url, email);
  }

  editCompany(company: CompanyEditModel): Observable<CompanyModel>{
    const url = `${environment.webApiNamespace}/company`;
    return this.httpClient.put<CompanyModel>(url, company)
      .pipe(
        map((request: any) => {
          return CompanyTransformer.transformCompanyJsonToCompanyModel(request)
        })
      );
  }

  editHeaderPhoto(companyId: number, formData: FormData, picType: PicTypeEnum): Observable<CompanyModel> {
    const url = `${environment.webApiNamespace}/company/photo/${picType}/${companyId}`;
    return this.httpClient.put<CompanyModel>(url, formData);
  }

  public getBizCategories(): Observable<BizCategoryModel[]> {
    const url = "${environment.webApiNamespace}/bizcategory/all";
    return this.httpClient.get<BizCategoryModel[]>(url);
  }
}
