<div>
  <div class="d-flex flex-row justify-content-center">
    <div class="nav-button mr-2">
      <a class="" routerLinkActive="about" routerLink="/about">About</a>
    </div>
    <div class="nav-button mr-2">
      <a class="" routerLinkActive="dating" routerLink="/singles">Singles</a>
    </div>
    <div class="nav-button">
      <a class="" routerLinkActive="advertise" routerLink="/advertise">Advertise</a>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center">
    <span class="mat-h3"> &copy; British Christian Network 2022</span>
  </div>
</div>



