import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FellowshipModel} from "../../model/fellowship.model";
import {Observable} from "rxjs";
import {FellowshipTransformer} from "../../transformer/fellowship-transformer";
import {map, take} from "rxjs/operators";
import {FellowshipreplyModel} from "../../model/fellowshipreply.model";
import {ForumTypeEnum} from "../../model/forum-type.enum";

@Injectable({
  providedIn: 'root'
})
export class FellowshipService {
  private fellowshipApi = "/web/api";
  constructor(private httpClient: HttpClient) {

  }

  getFellowshipList(page: number, type: ForumTypeEnum): Observable<FellowshipModel[]>{
    let url = `${this.fellowshipApi}/fellowship/list/${type}/${page}`;
    if (type == ForumTypeEnum.ALL) {
      url = `${this.fellowshipApi}/fellowship/list/${page}`;
    }

    return this.httpClient.get<FellowshipModel[]>(url)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowshipModelArray(response)
        })
      );
  }

  getFellowshipListForUser(userId: number, page: number): Observable<FellowshipModel[]>{
    const url = `${this.fellowshipApi}/fellowship/user/${userId}/list/${page}`;
    return this.httpClient.get<FellowshipModel[]>(url)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowshipModelArray(response)
        })
      );
  }

  getFellowshipListForOrganisation(orgId: number): Observable<FellowshipModel[]>{
    const url = `${this.fellowshipApi}/fellowship/org/${orgId}`;
    return this.httpClient.get<FellowshipModel[]>(url)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowshipModelArray(response)
        })
      );
  }

  postFellowship(formData: FormData): Observable<FellowshipModel> {
    const url = `${this.fellowshipApi}/fellowship`;
    return this.httpClient.post<FellowshipModel>(url, formData)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowship(response)
        })
      );
  }

  postFellowshipReply(comment: string, userId: number, fellowshipId: number): Observable<FellowshipreplyModel> {
    const url = `${this.fellowshipApi}/fellowshipreply`;
    return this.httpClient.post<FellowshipModel>(url, {
      userId: userId,
      fellowshipId: fellowshipId,
      comment: comment

    })
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowshipReply(response)
        })
      );
  }


  getForumItem(forumId: number): Observable<FellowshipModel> {
    const url = `${this.fellowshipApi}/fellowship/${forumId}`;
    return this.httpClient.get<FellowshipModel>(url)
      .pipe(
        map(response => {
          return FellowshipTransformer.transformFromJsonToFellowship(response)
        })
      );
  }

  deleteForumItem(id: number): Observable<boolean>{
    const url = `${this.fellowshipApi}/fellowship/${id}`;
    return this.httpClient.delete(url, {
      observe: 'response'
    }).pipe(
      map(response => response.ok)
    )
  }
  deleteForumReplyItem(id: number): Observable<boolean>{
    const url = `${this.fellowshipApi}/fellowshipreply/${id}`;
    return this.httpClient.delete(url, {
      observe: 'response'
    }).pipe(
      map(response => response.ok)
    )
  }
}
