import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ForumRoutingModule } from './forum-routing.module';
import { ForumStreamComponent } from './forum-stream/forum-stream.component';
import {Nl2BrPipeModule} from "nl2br-pipe";
import {MomentModule} from "ngx-moment";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ForumPostPanelComponent } from './forum-post-panel/forum-post-panel.component';
import {SpinnerModule} from "../spinner/spinner.module";
import {MatRadioModule} from "@angular/material/radio";
import {StoreModule} from "@ngrx/store";
import {reducer} from './forum.reducer'
import {MatCardModule} from "@angular/material/card";
import { ForumItemComponent } from './forum-item/forum-item.component';
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import { ActivityItemComponent } from './activity-item/activity-item.component';
import {HttpClientModule} from "@angular/common/http";
import {MatTabsModule} from "@angular/material/tabs";
import {CommunityHomeComponent} from "./community-home/community-home.component";
import {MatChipsModule} from "@angular/material/chips";
import {DatingHomeComponent} from "./dating-home/dating-home.component";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {PeopleModule} from "../people/people.module";
import { FilterButtonDirective } from './filter-button.directive';
import {MatTooltipModule} from "@angular/material/tooltip";
import {SearchModule} from "../search/search.module";

@NgModule({
  declarations: [ForumStreamComponent, ForumPostPanelComponent, ForumItemComponent, ActivityItemComponent,
  CommunityHomeComponent, DatingHomeComponent, FilterButtonDirective],
    imports: [
        CommonModule,
        ForumRoutingModule,
        Nl2BrPipeModule,
        MomentModule,
        FontAwesomeModule,
        FormsModule,
        SpinnerModule,
        ReactiveFormsModule,
        MatRadioModule,
        StoreModule.forFeature('forum', reducer),
        SpinnerModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        HttpClientModule,
        MatTabsModule,
        MatChipsModule,
        MatTableModule,
        MatIconModule,
        PeopleModule,
        MatTooltipModule,
        SearchModule
    ],
    exports: [
        ForumPostPanelComponent,
        ForumItemComponent,
        ForumStreamComponent,
        ActivityItemComponent,
      DatingHomeComponent
    ]
})
export class ForumModule { }
