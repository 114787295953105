import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../service/user/user.service";
import {UserDataModel} from "../../../model/userModel";
import {ActivatedRoute} from "@angular/router";
import {MessageModel} from "../../../model/message.model";

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss']
})
export class UserMessageComponent implements OnInit {

  messages: MessageModel[];
  user1Id: number;
  user2Id: number;
  constructor(private userService: UserService,
              private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.user1Id = this.activatedRoute.snapshot.params.user1Id;
    this.user2Id = this.activatedRoute.snapshot.params.user2Id;
    this.getMessage(this.user1Id, this.user2Id);
  }

  getMessage(user1, user2){
    this.userService.getUserMessages(user1, user2)
      .subscribe((messages: MessageModel[]) => {
        this.messages = messages;
      })
  }

}
