<div class="row bg-primary-dark">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <nav class="navbar navbar-dark bg-primary-dark">
        <a class="navbar-brand mt-2" [routerLink]="'/home'">
          <img src="https://s3.amazonaws.com/britishchristian-system/bc-logo-rounded.png"
               width="60" height="60" class="d-inline-block align-middle" alt="">
          <span class="navbar-text text-light pl-2">British Christian</span>
        </a>
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="!isLoggedIn else showLoggedInNavs" class="nav-item d-flex flex-row justify-content-center align-items-center">
          <div class="nav-button nav-button-white-text mr-2">
            <a class="nav-link btn-link mr-2" routerLink="/advertise">Advertise</a>
          </div>
          <div class="nav-button nav-button-white-text mr-2">
            <a class="nav-link btn-link mr-2" routerLink="/signup">Sign up</a>
          </div>
          <div>
            <a class="btn btn-block btn-secondary" routerLinkActive="current" routerLink="/login">Login</a>
          </div>
        </li>
        <ng-template #showLoggedInNavs>
          <li class="nav-item  d-flex flex-row align-items-center">
            <a mat-flat-button class="nav-btn mr-2" routerLink="/user/{{userData.id}}">
              <fa-icon [icon]="faHouseUser"></fa-icon>
              Home
            </a>
            <div class="nav-button nav-button-white-text mr-2">
              <a class="nav-link btn-link" routerLinkActive="logout" (click)="logout()" routerLink="/">Logout</a>
            </div>
            <div class="nav-button nav-button-white-text mr-2">
              <a class="nav-link btn-link mr-2" routerLink="/advertise">Advertise</a>
            </div>
          </li>
        </ng-template>
      </ul>
    </nav>
  </div>
  <div class="col-sm-1"></div>
</div>
<div class="row bg-primary">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <nav class="navbar navbar-expand navbar-dark custom-bg-primary">
        <ul class="navbar-nav mr-auto ml-auto">
          <li class="nav-item">
            <a mat-button mat-flat-button class="nav-link" routerLinkActive="active-link" routerLink="/home">Home<span
              class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a mat-button mat-flat-button [matMenuTriggerFor]="menu" class="nav-link">Community</a>
            <mat-menu #menu="matMenu">
              <a mat-menu-item routerLink="/community/fellowship/1/0">
<!--                <fa-icon [icon]="faHouseUser"></fa-icon>-->
                Fellowship
              </a>
              <a mat-menu-item routerLink="/community/people">People</a>
            </mat-menu>
          </li>
          <li class="nav-item">
            <a mat-button mat-flat-button [matMenuTriggerFor]="singles_menu" class="nav-link">Singles</a>
            <mat-menu #singles_menu="matMenu">
              <a mat-menu-item routerLink="/singles/fellowship/5/0">Fellowship</a>
              <a mat-menu-item routerLink="/singles/people">People</a>
            </mat-menu>
          </li>
          <li class="nav-item">
            <a mat-button mat-flat-button class="nav-link" routerLinkActive="active-link" routerLink="/directory">Directory <span
              class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a mat-button mat-flat-button class="nav-link" routerLinkActive="active-link" routerLink="/articles/0">Articles </a>
          </li>
        </ul>
    </nav>
  </div>
  <div class="col-sm-1"></div>
</div>

