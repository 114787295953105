<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row px-xs-5">
      <div class="col-md-1"></div>
      <div class="col-sm-12 col-md-10">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center">
            <h1 class="display-4">Password Reset</h1>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-sm-12">
            <p>We have sent a password reset code to your email inbox (Please also check in your junk folder).</p>
            <p>Enter this code in the box below and submit to reset your password. </p>
            <p>We will send your new password to your email</p>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-12">
            <form [formGroup]="form">
              <div class="form-group">
                <div class="label">Enter reset code</div>
                <div>
                  <input class="form-control" type="number" formControlName="code"/>
                </div>
                <div *ngIf="code.touched && code.invalid" class="alert-danger">
                  <span>Enter the reset code that was emailed to you</span>
                </div>
              </div>
              <div class="form-group">
                <div>
                  <input class="form-control" formControlName="email" hidden/>
                </div>
              </div>

              <div class="form-group d-flex flex-row">
                <div>
                  <button class="btn btn-secondary btn-lg" (click)="submit()">Submit</button>
                </div>
                <div class="ml-2">
                  <fa-icon *ngIf="spinnerOn" [icon]="faSpinner" [spin]="true" [size]="'3x'" class="text-secondary"></fa-icon>
                </div>
              </div>

              <div class="form-group" *ngIf="resetFail">
                <p class="text-danger">There was a problem resetting your password. Ensure your follow all the instructions or start afresh</p>
              </div>
              <div class="form-group" *ngIf="resetSuccess">
                <p class="text-success">Your password has been successfully reset. An email has been sent to you.</p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>




