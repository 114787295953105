import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ArticleCreateModel} from "../../../model/article-create.model";
import {ArticleService} from "../../../service/article/article.service";
import {typeSourceSpan} from "@angular/compiler";
import {ArticleTypeModel} from "../../../model/article-type.model";
import {UserDataModel} from "../../../model/userModel";
import {UserService} from "../../../service/user/user.service";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {ArticleModel} from "../../../model/article.model";

@Component({
  selector: 'app-article-add',
  templateUrl: './article-add.component.html',
  styleUrls: ['./article-add.component.scss']
})
export class ArticleAddComponent implements OnInit {

  formGroup: FormGroup;
  articleTypes: ArticleTypeModel[] = [];
  users: UserDataModel[] = [];
  article: ArticleModel;
  mode;
  articleId;
  constructor(private articleService: ArticleService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.formGroup = new FormGroup({
      articleTypeId: new FormControl(0, [Validators.min(1)]),
      userId: new FormControl(0, [Validators.min(1)]),
      emailForSearch: new FormControl('', []),
      title: new FormControl('', [Validators.required]),
      summary: new FormControl('', [Validators.required]),
      headerVideoEmbedUrl: new FormControl('', []),
      sectionOneText: new FormControl('', []),
      sectionOneVideoEmbedSrc: new FormControl('', []),
      sectionTwoText: new FormControl('', []),
      sectionTwoVideoEmbedSrc: new FormControl('', []),
      editorialPic: new FormControl('', []),
      displayPic: new FormControl('', []),
      section2ImageUrl: new FormControl('', []),
      section1ImageUrl: new FormControl('', [])

    })
  }

  ngOnInit() {
    this.getArticleTypes();
    this.mode = this.activatedRoute.snapshot.params.mode
    this.articleId = this.activatedRoute.snapshot.params.id;
    if (this.mode && this.mode === 'edit') {
      this.getArticle(this.articleId);
    }
  }

  submit() {
    if (this.formGroup.valid) {
      const article: ArticleCreateModel = this.transformArticleCreateData();

      this.articleService.postArticles(article)
        .subscribe();
    } else {

    }
  }

  getArticleTypes() {
    this.articleService.getArticleTypes()
      .subscribe((res: ArticleTypeModel[]) => {
        this.articleTypes = res;
      });
  }

  findUsers() {
    this.userService.getUserByEmail(this.formGroup.get('emailForSearch').value)
      .subscribe(user => {
        this.users.push(user);
      })
  }

  private getArticle(id: number) {
    this.articleService.getArticle(id)
      .subscribe((article: ArticleModel) => {
        this.article = article;
        this.getAuthor();
        this.setValuesInFields();
      })
  }

  private setValuesInFields(){
    this.formGroup.get('title').setValue(this.article.subject);
    this.formGroup.get('summary').setValue(this.article.summary);
    this.formGroup.get('headerVideoEmbedUrl').setValue(this.article.headerVideoEmbedSrc);
    this.formGroup.get('sectionOneVideoEmbedSrc').setValue(this.article.section1VideoEmbedSrc);
    this.formGroup.get('sectionTwoVideoEmbedSrc').setValue(this.article.section2VideoEmbedSrc);
    this.formGroup.get('sectionOneText').setValue(this.article.comment);
    this.formGroup.get('sectionTwoText').setValue(this.article.section2Body);
    this.formGroup.get('articleTypeId').setValue(this.article.articleTypeId);
    this.formGroup.get('editorialPic').setValue(this.article.editorialPic);
    this.formGroup.get('displayPic').setValue(this.article.displayPic);
    this.formGroup.get('section1ImageUrl').setValue(this.article.section1ImageUrl);
    this.formGroup.get('section2ImageUrl').setValue(this.article.section2ImageUrl);
  }

  private getAuthor(){
    this.userService.getUser(this.article.authorId)
      .subscribe((user: UserDataModel) => {
        this.users.push(user);
        this.formGroup.get('userId').setValue(user.id);
      })
  }

  edit() {
    const article: ArticleCreateModel = this.transformArticleCreateData();
    this.articleService.editArticle(this.articleId, article)
      .subscribe(article => {
        this.router.navigate(['article', this.articleId]);
      })
  }

  private transformArticleCreateData(): ArticleCreateModel{
    const article: ArticleCreateModel =
      {
        articleTypeId: this.formGroup.get('articleTypeId').value,
        userId: this.formGroup.get('userId').value,
        title: this.formGroup.get('title').value,
        summary: this.formGroup.get('summary').value,
        headerVideoEmbedSrc: this.formGroup.get('headerVideoEmbedUrl').value,
        section1VideoEmbedSrc: this.formGroup.get('sectionOneVideoEmbedSrc').value,
        section2VideoEmbedSrc: this.formGroup.get('sectionTwoVideoEmbedSrc').value,
        section3VideoEmbedSrc: null,
        section1Text: this.formGroup.get('sectionOneText').value,
        section2Text: this.formGroup.get('sectionTwoText').value,
        section3Text: null,
        section1ImageUrl: this.formGroup.get('section1ImageUrl').value,
        section2ImageUrl: this.formGroup.get('section2ImageUrl').value,
        section3ImageUrl: null,
        displayPic: this.formGroup.get('displayPic').value,
        editorialPic: this.formGroup.get('editorialPic').value
      };

    return article;
  }
}
