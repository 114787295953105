import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserRoutingModule} from './user-routing.module';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserViewComponent} from './user-view/user-view.component';
import {StoreModule} from "@ngrx/store";
import * as UserReducer from './user.reducer';
import {UserSettingComponent} from './user-setting/user-setting.component'
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {UserDeactivateComponent} from './user-deactivate/user-deactivate.component';
import {UserSettingNotificationComponent} from './user-setting-notification/user-setting-notification.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MomentModule} from "ngx-moment";
import {UserProfileEditComponent} from './user-profile-edit/user-profile-edit.component';
import {UserPictureEditComponent} from './user-picture-edit/user-picture-edit.component';
import {UserGalleryComponent} from './user-gallery/user-gallery.component';
import {UserPasswordSettingComponent} from './user-password-setting/user-password-setting.component';
import {UserAccountComponent} from './user-account/user-account.component';
import {ArticleModule} from "../article/article.module";
import {DirectoryModule} from "../directory/directory.module";
import {UserViewVidsComponent} from './user-view-vids/user-view-vids.component';
import {UserViewHomeComponent} from './user-view-home/user-view-home.component';
import {Nl2BrPipeModule} from "nl2br-pipe";
import {UserSettingHomeComponent} from './user-setting-home/user-setting-home.component';
import {UserCommunityHomeComponent} from "./user-community-home/user-community-home.component";
import {SpinnerModule} from "../spinner/spinner.module";
import {DatingProfileEditComponent} from './dating-profile-edit/dating-profile-edit.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule
} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
  MomentDateModule
} from "@angular/material-moment-adapter";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import { UserHeaderPrivateComponent } from './user-header-private/user-header-private.component';
import {MatTabNav, MatTabsModule} from "@angular/material/tabs";
import {ForumStreamComponent} from "../forum/forum-stream/forum-stream.component";
import {ForumModule} from "../forum/forum.module";
import {RouterModule} from "@angular/router";
import {PeopleModule} from "../people/people.module";
import {PeopleGalleryComponent} from "../people/people-gallery/people-gallery.component";
import {UserViewAboutComponent} from "./user-view-about/user-view-about.component";
import {UserMessageCreateComponent} from "./user-message-create/user-message-create.component";
import {UserMessageHomeComponent} from "./user-message-home/user-message-home.component";
import {UserMessageComponent} from "./user-message/user-message.component";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { UserSearchComponent } from '../search/user-search/user-search.component';
import {SearchModule} from "../search/search.module";
import { UserNetworkComponent } from './user-network/user-network.component';
import {MatTableModule} from "@angular/material/table";

@NgModule({
  declarations: [
    UserProfileComponent, UserViewComponent, UserSettingComponent, UserDeactivateComponent, UserSettingNotificationComponent,
    UserProfileEditComponent, UserPictureEditComponent, UserGalleryComponent, UserPasswordSettingComponent,
    UserAccountComponent,
    UserViewVidsComponent,
    UserViewHomeComponent,
    UserSettingHomeComponent,
    UserCommunityHomeComponent,
    DatingProfileEditComponent,
    UserHeaderPrivateComponent,
    UserViewAboutComponent,
    UserMessageCreateComponent,
    UserMessageHomeComponent,
    UserMessageComponent,
    UserNetworkComponent
  ],
    imports: [
        CommonModule,
        UserRoutingModule,
        StoreModule.forFeature('user', UserReducer.reducer),
        FontAwesomeModule,
        FormsModule,
        MomentModule,
        ReactiveFormsModule,
        ArticleModule,
        DirectoryModule,
        Nl2BrPipeModule,
        SpinnerModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatInputModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        MatTabsModule,
        ForumModule,
        PeopleModule,
        MatCardModule,
        MatIconModule,
        MatGridListModule,
        MatSnackBarModule,
        SearchModule,
        MatTableModule
    ],
  entryComponents: [
    PeopleGalleryComponent,
    ForumStreamComponent
  ],
  exports: [
    UserSearchComponent
  ],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue:
        {
          parse: {dateInput: 'LLL'},
          useUtc: true
        }
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ]
})
export class UserModule {
}
