<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="jumbotron">
      <h1 class="display-4">Cost effective advertising and promotional services</h1>
    </div>

    <div>
      <p>
          <span class="mat-h1">
            Many small businesses, creators, artists, writers and ministries as well as organisations suffer from one common hindrance, visibility.
          </span>
      </p>

      <p>
          <span class="mat-h1">
            We have put together a technology enhanced promotional service that will help shine the light on your products and services.
          </span>
      </p>

      <p>
          <span class="mat-h1">
            Designed for small businesses, our cost effective advertisement service gives you visibility at the local and national levels by
            leveraging our unique localised user base and national digital foot print though our website and social media platforms
          </span>
      </p>
    </div>


    <section class="mt-5">
      <div><span class="mat-h1">What you get</span></div>
      <table class="ml-sm-0 ml-md-3">
        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'cyan', 'color': 'cyan'}"></fa-icon>
          </td>
          <td class="pl-3">
            <span class="mat-body">A managed web page which can host videos, a picture gallery and contact form for an engaging presentation</span>
          </td>
        </tr>

        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'brown', 'color': 'brown'}">
            </fa-icon>
          </td>
          <td class="pl-3">
            <span class="mat-body">Leverage a vibrant UK Christian digital community with about 7000 members and growing daily</span>
          </td>
        </tr>

        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'blue', 'color': 'blue'}">
            </fa-icon>
          </td>
          <td class="pl-3">
            <span class="">Include your advert in our weekly digital newsletter</span>
          </td>
        </tr>

        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'indigo', 'color': 'indigo'}">
            </fa-icon>
          </td>
          <td class="pl-3">
            <span class="">Our audience is engaged and local</span>
          </td>
        </tr>

        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'grey', 'color': 'grey'}">
            </fa-icon>
          </td>
          <td class="pl-3">
            <span>In addition to listing in our directory you get placement on all our forums and pages on a rotating basis</span>
          </td>
        </tr>

        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'grey', 'color': 'yellow'}">

            </fa-icon>
          </td>
          <td class="pl-3">
            <span>Our engaging web platform has a monthly visitor count of over a thousand with average user duration of over one minute</span>
          </td>
        </tr>

        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'brown', 'color': 'teal'}"></fa-icon>
          </td>
          <td class="pl-3">
            <span>An ever expanding facebook and twitter presence</span>

          </td>
        </tr>


      </table>
    </section>

    <section class="mt-5">
      <div><span class="mat-h1">What we advertise</span></div>
      <table class="ml-sm-0 ml-md-3">
        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'cyan', 'color': 'green'}">
            </fa-icon>
          </td>
          <td class="pl-3">
            <span>Products and services</span>
          </td>
        </tr>
        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'brown', 'color': 'brown'}">
            </fa-icon>
          </td>
          <td class="pl-3">
            <span>Jobs and events</span>
          </td>
        </tr>
        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'blue', 'color': 'blue'}">
            </fa-icon>
          </td>
          <td class="pl-3">
            <span>Announcements, christenings, obituaries and memorials</span>
          </td>
        </tr>
        <tr>
          <td>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"
                     [styles]="{'stroke': 'indigo', 'color': 'purple'}"></fa-icon>
          </td>
          <td class="pl-3">
            <span>News distribution and circulation for organisations</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="mat-h6">All advertisement requests are subject to our audience suitability criteria</span>
          </td>
        </tr>
      </table>
    </section>

    <section class="mt-5">
      <div><span class="mat-h1">Service levels</span></div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <mat-card class="example-card">
            <img mat-card-image src="https://britishchristian-system.s3.amazonaws.com/gold-vip.png" alt="Gold level service">
            <mat-card-content>
              <h2>Gold level</h2>
              <ul>
                <li>Banner ad placement on website</li>
                <li>Social media placement (X and Facebook)</li>
                <li>Create and host product/services web page</li>
                <li>Listing in products and services directory</li>
                <li>Inclusion in weekly E-Newsletter distribution</li>
                <li>Production and distribution of monthly promotional vodcast</li>
                <li>£100 + VAT per month</li>

              </ul>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-card class="example-card">
            <img mat-card-image src="https://britishchristian-system.s3.amazonaws.com/silver-member.png" alt="Silver membership">
            <mat-card-content>
              <h2>Silver level</h2>
              <ul>
                <li>Create and host product/services web page</li>
                <li>Listing in products and services directory</li>
                <li>Inclusion in weekly E-Newsletter distribution</li>
                <li>Production and distribution of monthly promotional vodcast</li>
                <li>£50 + VAT per month</li>
              </ul>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-card class="example-card">
            <img mat-card-image src="https://britishchristian-system.s3.amazonaws.com/bronze-member.png" alt="Photo of a bronze member">
            <mat-card-content>
              <h2>Bronze level</h2>
              <ul>
                <li>Create and host product/services web page</li>
                <li>Listing in products and services directory</li>
                <li>£20 + VAT per month</li>
              </ul>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </section>

    <section class="mt-5">
      <div><span class="mat-h1">Contact</span></div>
      <div>
        <p>
          Email: admin@britishchristian.com
        </p>
        <p>
          Telephone: 07939313045
        </p>
      </div>
    </section>

  </div>
  <div class="col-sm-1"></div>
</div>





