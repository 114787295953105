import { Injectable } from '@angular/core';
import {Autolinker} from "autolinker";

@Injectable({
  providedIn: 'root'
})
export class TextFormatService {

  constructor() { }

  autoLinkText(text: string): string{
    return Autolinker.link( text)
  }
}
