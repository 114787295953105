import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivityModel} from "../../../model/activity.model";
import {Router} from "@angular/router";
import {TextFormatService} from "../../../service/text-format/text-format.service";
import {faTrash, faHeart, faPen, faComment, faBible, faPray, faBullhorn} from "@fortawesome/free-solid-svg-icons"
import {UserService} from "../../../service/user/user.service";
import {FellowshipService} from "../../../service/fellowship/fellowship.service";

@Component({
  selector: 'app-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.scss']
})
export class ActivityItemComponent implements OnInit {

  @Input('activityItem') activity: ActivityModel;
  @Output('delete') delete: EventEmitter<{ forumTypeId: number, originRowId: number; }> = new EventEmitter<{ forumTypeId: number; originRowId: number; }>();
  faTrash; faHeart; faPen; faComment; faBible; faPray; faBullHorn: any;
  constructor(private router: Router,
              public textFormatService: TextFormatService,
              private userService: UserService,
              private fellowshipService: FellowshipService) {
    this.faTrash = faTrash;
    this.faHeart = faHeart;
    this.faPen = faPen;
    this.faComment = faComment;
    this.faBible = faBible;
    this.faPray = faPray;
    this.faBullHorn = faBullhorn;
  }

  ngOnInit(): void {
  }


  routeToForumItem(originalForumId: number) {
    this.router.navigate([`forum`, originalForumId]);
  }

  routeToUserView(userId: number) {
    this.router.navigate([`user`, userId]);

  }

  isViewerOwner(posterId: number) {
    if (!this.userService.isUserAuthenticated()) {
      return false;
    }
    return this.userService.getSignedInUserId() === posterId;
  }

  onDeleteClick(originRowId: number, forumTypeId: number) {
    this.fellowshipService
      .deleteForumItem(originRowId)
      .subscribe( {
        next: (value) => {
          this.delete.emit({originRowId, forumTypeId});
        },
        error: (error) => {console.log('error',error)}
      })
  }
}
