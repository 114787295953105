<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row px-xs-5">
      <div class="col-md-1"></div>
      <div class="col-sm-12 col-md-10">
        <div class="row">
          <div class="col-sm-12">
            <h1 class="display-4">Login</h1>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-sm-12">
            <form [formGroup]="form">
              <div class="form-group">
                <div class="label">Enter email address</div>
                <div>
                  <input class="form-control" formControlName="email"/>
                </div>
                <div *ngIf="email.touched && email.invalid" class="alert-danger">
                  <span>Valid email required</span>
                </div>
              </div>
              <div class="form-group">
                <div class="label">Enter password</div>
                <div>
                  <input class="form-control" type="password" formControlName="password"/>
                </div>
                <div *ngIf="password.touched && password.invalid" class="alert-danger">
                  <span>Password required except when resetting the password</span>
                </div>
              </div>
              <div class="form-group">
                <div>
                  <button class="btn btn-secondary btn-lg" (click)="submit()">Login</button>
                  <button class="btn btn-secondary ml-3" (click)="resetPw()">Reset password</button>
                </div>
                <div class="form-group mt-2" *ngIf="loginFail">
                  <p class="text-danger">Login failed</p>
                </div>
                <div class="mt-3">
                  <fa-icon *ngIf="spinnerOn" [icon]="faSpinner" [spin]="true" [size]="'4x'" class="text-secondary"></fa-icon>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>




