import {Component, Input, OnInit} from '@angular/core';
import {faArrowLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {UserDataModel, UserModel, UserPicture} from "../../../model/userModel";
import {Subscription} from "rxjs";
import {UserService} from "../../../service/user/user.service";
import {SetUserAction} from "../user.action";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-user-gallery',
  templateUrl: './user-gallery.component.html',
  styleUrls: ['./user-gallery.component.scss']
})
export class UserGalleryComponent implements OnInit {
  faArrowLeft: any;
  faSpinner: any;
  userBeingViewedData: UserDataModel;
  userViewableId: number;
  userSub: Subscription;

  constructor(private store: Store<AppState>,
              private userService: UserService,
              private activatedRoute: ActivatedRoute) {
    this.faArrowLeft = faArrowLeft;
    this.faSpinner = faSpinner;
    this.userSub = this.store.select(state => state.user.userInView)
      .subscribe(user => this.userBeingViewedData = user)
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    this.userSub.unsubscribe();
  }
}
