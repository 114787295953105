<div *ngIf="forumItem" class="row">
  <div class=" col-sm-0 col-md-1"></div>
  <div class=" col-sm-12 col-md-10">
    <mat-card color="primary">
      <mat-card-header [routerLink]="'/user/'+forumItem?.userId" class="pointer">
          <img mat-card-avatar [src]="forumItem?.userPicUrl"/>
        <mat-card-title>{{forumItem.userName}}</mat-card-title>
        <mat-card-subtitle>{{forumItem.datePosted  | amFromUnix | amDateFormat:'LL'}}</mat-card-subtitle>
      </mat-card-header>
      <div class="col-sm-12 d-flex justify-content-center mt-2">
        <img *ngIf="forumItem.picUrl?.length>1" mat-card-image [src]="forumItem.picUrl" alt="comment picture"
             class="custom-img-fluid"/>
      </div>

      <mat-card-content>
        <p [innerHTML]="textFormatService.autoLinkText(forumItem.body) | nl2br"></p>

        <!--statistics section-->
        <div class="d-flex flex-row justify-content-between mb-2">
          <!--        <button mat-button color="primary" (click)="toggleShowComments()">Write a comment</button>-->
          <!--        <button mat-button (click)="toggleShowComments()">{{forumItem.replies ? forumItem.replies.length : 0}} Comments</button>-->
        </div>



        <!--comment and replies section-->
        <!--      <div *ngIf="showComments">-->
        <!--line divider-->
        <mat-divider [inset]="true"></mat-divider>

        <div *ngIf="userService.isUserAuthenticated()">
          <mat-form-field class="w-100" [formGroup]="formGroup">
            <mat-label>Enter a reply</mat-label>
            <textarea matInput formControlName="reply" rows="10"></textarea>
          </mat-form-field>
          <div class="d-flex flex-row justify-content-center">
            <button mat-button mat-raised-button color="accent" (click)="submitReply()">Submit</button>
          </div>
        </div>

        <!--replies-->
        <div class="my-2" >
          <div class="mb-2">Previous comments</div>
          <mat-card class="mb-2" *ngFor="let reply of forumItem.replies">
            <mat-card-header [routerLink]="'/user/'+reply?.userId" class="pointer">
              <img  mat-card-avatar [src]="reply?.userImageUrl"/>
              <mat-card-title>{{reply.userName}}</mat-card-title>
              <mat-card-subtitle>{{reply.datePosted  | amFromUnix | amDateFormat:'LL'}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p [innerHTML]="textFormatService.autoLinkText(reply.comment) | nl2br"></p>
              <div class="item-interaction-container">
                <div *ngIf="isViewerOwner(reply.userId)" class="d-flex flex-row justify-content-start mr-3">
                  <div  class="item-interaction-btn mr-3" matTooltip="Delete" (click)="onDeleteClick(reply.id)">
                    <a mat-flat-button >
                      <fa-icon [icon]="faTrash"></fa-icon> Delete</a>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <!--      </div>-->
      </mat-card-content>
    </mat-card>
  </div>
  <div class=" col-sm-0 col-md-1"></div>
</div>

