import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ProductService} from "../../../service/product/product.service";
import {CompanyService} from "../../company/service/company.service";
import {ProductModel} from "../../../model/product.model";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  organisationId: number;
  products: ProductModel[];
  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
  ) { }

  ngOnInit() {
    this.organisationId = this.activatedRoute.snapshot.parent.params.id;
    this.productService.getProductsForOrganisation(this.organisationId);
    this.getProducts(this.organisationId);
  }

  getProducts(organisationId) {
    this.productService.getProductsForOrganisation(organisationId)
      .subscribe((products: ProductModel[]) => {
        this.products = products;
      })
  }


}
