<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row mt-5">
      <div class="col-sm-12">
        <a [routerLink]="'general'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">General profile</span>
        </a>
        <a [routerLink]="'dating-profile'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">Dating profile</span>
        </a>
        <a [routerLink]="'pictures'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">Pictures</span>
        </a>
        <a [routerLink]="'password'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">Password</span>
        </a>
<!--        <button class="btn btn-lg btn-secondary btn-block mt-5"><span class="text-white">Singles profile</span></button>-->
        <a [routerLink]="'notifications'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">Notifications</span>
        </a>
        <a [routerLink]="'deactivate'" class="btn btn-lg btn-secondary btn-block mt-5">
          Deactivate
        </a>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>
