<div class="d-flex flex-column justify-content-center mt-5">
  <div>Please note there is a sending quota to discourage spamming. You cannot send more than 2 messages per hour</div>
  <mat-form-field [formGroup]="messageForm" appearance="outline" [hintLabel]="'Type message'">
    <mat-label>Private message</mat-label>
    <textarea matInput style="height: 100px" formControlName="message"></textarea>
    <mat-error *ngIf="messageForm.dirty && messageForm.invalid">{{errorMsg}}</mat-error>
  </mat-form-field>
  <div class="d-flex flex-row justify-content-center">
    <button mat-flat-button color="primary" (click)="postMessage()">Post</button>
    <button class="ml-2" mat-flat-button color="warn" (click)="close()">Close</button>
  </div>
</div>
