import { Component, OnInit } from '@angular/core';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent implements OnInit {
  faCheck: any;
  constructor(
    private meta: Meta,
    private titleService: Title
  ) {
    this.faCheck = faCheck;
  }

  ngOnInit() {
    this.addMetaTags();
  }

  private addMetaTags(){
    this.titleService.setTitle(`Advertise with British Christian. The UK Christian network`);
    this.meta.updateTag({ name: 'keywords', content: `Advertise products and services to the UK Christian community. We advertise books, music, events, announcements etc` });
    this.meta.updateTag({name: 'description', content: `Advertise products and services to the UK Christian community. We advertise books, music, events, announcements etc`});

    /*face book open graph*/
    this.meta.updateTag({property: 'og:title', content: `Advertise products and services on British Christian. The UK Christian network`});
    this.meta.updateTag({property: 'og:description', content: `Advertise products and services to the UK Christian community. We advertise books, music, events, announcements etc`});
    // this.meta.updateTag({property: 'og:image', content: `Advertise products and services to the UK Christian community`});
    this.meta.updateTag({property: 'og:url', content: `https://www.britishchristian.com/advertise`})

    /*for twitter card*/
    this.meta.updateTag({property: 'twitter:card', content: `summary`});
    this.meta.updateTag({property: 'twitter:title', content: `Advertise products and services on British Christian. The UK Christian network`});
    this.meta.updateTag({property: 'twitter:description', content: `Advertise products and services to the UK Christian community. We advertise books, music, events, announcements etc`});
    // this.meta.updateTag({property: 'twitter:image', content: ``})
  }


}
