<div class="row">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <span class="mat-h1">Private messages</span>
    <div class="my-3" *ngFor="let sender of messageSenders">
      <a [routerLink]="'../message/'+user?.id+'/'+sender.id">{{sender.name}}</a>
    </div>
  </div>
  <div class="col-md-1"></div>
</div>
