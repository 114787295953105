import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from "./component/home/home.component";

const routes: Routes = [
  {
    path: 'article',
    loadChildren: () => import('./component/article/article.module').then(mod => mod.ArticleModule)
  },
  {
    path: 'directory',
    loadChildren: () => import('./component/directory/directory.module').then(mod => mod.DirectoryModule)
  },
  { path: '**', component: HomeComponent },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes,{ useHash: true, relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(routes,)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
