<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="d-flex justify-content-around my-3">
      <span class="d-block display-4 shadow p-5">{{product?.name}}</span>
    </div>
    <p [innerHTML]="product?.description | nl2br: true"></p>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
