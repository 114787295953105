import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserSearchMode} from "../../search/user-search/user-search.component";

@Component({
  selector: 'app-people-gallery',
  templateUrl: './people-gallery.component.html',
  styleUrls: ['./people-gallery.component.scss']
})
export class PeopleGalleryComponent implements OnInit, OnDestroy {

  singleSearchMode = UserSearchMode.SINGLE;
  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }


}
