import {Component, OnInit} from '@angular/core';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "../../../service/user/user.service";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {UserDataModel} from "../../../model/userModel";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-password-setting',
  templateUrl: './user-password-setting.component.html',
  styleUrls: ['./user-password-setting.component.scss']
})
export class UserPasswordSettingComponent implements OnInit {

  faArrowLeft: any;
  pw: string;
  cpw: string;
  user: UserDataModel;
  userSub: Subscription;
  errMsg: string = '';

  constructor(private userService: UserService,
              private store: Store<AppState>,
              private router: Router) {
    this.faArrowLeft = faArrowLeft;
    this.userSub = this.store.select('user')
      .subscribe(user => {
        this.user = user.user;
      });
  }

  ngOnInit() {
  }

  submit() {
    this.errMsg = '';
    if (this.pw === this.cpw) {
      this.userService
        .updatePassword(this.pw, this.user.id)
        .subscribe((user: UserDataModel) => {
            this.userService.deleteUserCookies();
            this.router.navigate(['/login']);
          },
          error => {
            this.errMsg = 'There was a problem updating your password. Please try again';
          })
    } else {
      this.errMsg = 'Password does not match'
    }
  }
}
