import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../login.service";
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {UserService} from "../../../service/user/user.service";
import {AuthenticationModel, UserDataModel, UserModel} from "../../../model/userModel";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {SetAuthenticationAction, SetAuthenticationToken} from "../../user/user.action";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });
  spinnerOn = false;
  faSpinner;
  loginFail = false;
  constructor(private loginService: LoginService,
              private router: Router,
              private store: Store<AppState>,
              private cookieService: CookieService,
              private userService: UserService) {
    this.faSpinner = faSpinner;
  }

  ngOnInit() {
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.loginFail = false;
      this.spinnerOn = true;
      this.loginService.postLogin(this.form.value)
        .subscribe((authentication: AuthenticationModel) => {
            this.spinnerOn = false;
            this.store.dispatch(new SetAuthenticationAction({authentication: authentication}));
            this.userService.writeAuthTokenCookie(authentication.token);
            this.getUserData(authentication.id);
          },
          error => {
            this.loginFail = true;
            this.spinnerOn = false;
          })
    }
  }

  private goToUserProfile(userId) {
    this.router.navigate(['/user', userId])
  }

  private getUserData(userId: Number) {
    this.userService.getUser(userId)
      .subscribe((user: UserDataModel) => {
        this.userService.storeUserAndWriteCookie(user);
        this.goToUserProfile(userId);
      })
  }

  resetPw() {
    if (this.email.invalid) {
      return false
    }
    this.spinnerOn = true;
    this.loginService.getResetPasswordCode(this.email.value.trim())
      .subscribe( res => {
        this.spinnerOn = false;
        this.router.navigate(['reset', this.email.value.trim()]);
      })
  }
}
