<div *ngIf="loggedInUser" class="mb-3">
  <div class="d-flex flex-row justify-content-center singles-membership-btn-container mt-3 p-5">
    <div class="row">
      <!--<div class="col-sm-12 col-md-4">
        images here
      </div>-->
      <div class="col-sm-12 col-md-8">
        Use this form to set you profile for the christian dating network
      </div>
    </div>
  </div>
  <hr/>
  <div>
    <div class="d-flex flex-row justify-content-between"
         *ngIf="loggedInUser && loggedInUser.singlesNetworkStatus === 1; else nonDating">
      <button class="btn btn-primary btn-lg"
              (click)="onLeaveSinglesClick()">Leave singles network
      </button>
      <span class="ml-3">You are a member of the christian dating network. Use the button to leave the group</span>
    </div>

    <ng-template #nonDating>
      <div class="d-flex flex-row justify-content-between">
        <button class="btn btn-lg btn-primary" (click)="onJoinSinglesClick()">Join dating network</button>
        <span class="ml-3"> You are not a member of the christian dating network. Use the button start the process of joining</span>
      </div>

    </ng-template>
  </div>
  <div *ngIf="isEditMode">
    <hr/>

    <div>
      Complete the form below to setup your profile
    </div>
    <form class="mt-3" [formGroup]="form">
      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput id="name" class="form-control" type="text" formControlName="name">
          <mat-error *ngIf="nameCtl.hasError('required')">You must set a user name</mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>About me</mat-label>
          <textarea matInput id="about-me" class="form-control" formControlName="about"></textarea>
          <mat-error *ngIf="aboutCtrl.hasError('required')">Enter some information about yourself</mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label>Date of birth</mat-label>
            <input id="dob" matInput [matDatepicker]="picker" [formControl]="form.get('dob')" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
            <mat-error *ngIf="dobCtrl.hasError('required')">Enter your date of birth</mat-error>
          </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill">
          <mat-label>Select gender</mat-label>
          <mat-select formControlName="genderId"
                      required>
            <mat-option [value]="1">Male</mat-option>
            <mat-option [value]="2">Female</mat-option>
          </mat-select>
          <mat-error *ngIf="genderCtrl.hasError('required')">Select your gender</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>What are your regular Christian/Church activities</mat-label>
        <textarea matInput cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="50"
        formControlName="activities"></textarea>
        <mat-error *ngIf="this.activitiesCtrl.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="this.activitiesCtrl.hasError('minlength')">This field requires minimum of 20 characters</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>What are your general interests</mat-label>
        <textarea matInput cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="50"
        formControlName="interests"></textarea>
        <mat-error *ngIf="this.interestsCtrl.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="this.interestsCtrl.hasError('minlength')">This field requires minimum of 20 characters</mat-error>
      </mat-form-field>


      <mat-form-field appearance="fill">
        <mat-label>What are your favourite scriptures</mat-label>
        <textarea matInput cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="50"
                  formControlName="favScripture"></textarea>
        <mat-error *ngIf="this.favScriptureCtrl.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="this.favScriptureCtrl.hasError('minlength')">This field requires minimum of 20 characters</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>What does faith mean to you</mat-label>
        <textarea matInput cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="50"
                  formControlName="faithStatement"></textarea>
        <mat-error *ngIf="this.faithStatementCtrl.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="this.faithStatementCtrl.hasError('minlength')">This field requires minimum of 20 characters</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>How does your faith influence your life</mat-label>
        <textarea matInput cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="50"
                  formControlName="faithInfluence"></textarea>
        <mat-error *ngIf="this.faithInfluenceCtrl.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="this.faithInfluenceCtrl.hasError('minlength')">This field requires minimum of 20 characters</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>What are some of your life lessons</mat-label>
        <textarea matInput cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="50"
                  formControlName="lifeLesson"></textarea>
        <mat-error *ngIf="this.lifeLessonCtrl.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="this.lifeLessonCtrl.hasError('minlength')">This field requires minimum of 20 characters</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>What qualities do you seek in a partner</mat-label>
        <textarea matInput cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="50"
                  formControlName="personalStatement"></textarea>
        <mat-error *ngIf="this.personalStatementCtrl.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="this.personalStatementCtrl.hasError('minlength')">This field requires minimum of 20 characters</mat-error>
      </mat-form-field>

      <div class="d-flex flex-row justify-content-center">
        <button mat-flat-button color="primary" (click)="onSubmitClick()" *ngIf="isSaving === false">Save</button>
        <fa-icon [icon]="faSpinner" spin="true" *ngIf="isSaving === true"></fa-icon>
      </div>

    </form>
  </div>
</div>
