<div class="row px-xs-5">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row px-sm-3">
      <div class="col-sm-12">
        <div class=" d-flex flex-column justify-content-center mt-5">
          <div class="border border-primary rounded p-4 d-flex justify-content-center">
            <h5 class="card-title">Update your picture gallery</h5>
          </div>

          <app-user-header-private class="mt-3"></app-user-header-private>

          <div class="row mt-3">
            <div *ngFor="let picture of userData.pictures" class="mb-3 d-flex flex-column justify-content-between col-sm-12 col-md-6">
              <img  class="img-fluid rounded mx-auto shadow-lg custom-img-fluid"
                   [src]="picture.url"
                   alt="pic">
              <div class="d-flex flex-row justify-content-center mt-1">
                <button class="btn btn-primary" *ngIf="!isProfilePic(picture.url)"
                (click)="updateProfilePic(picture.url)">
                  Use as profile pic
                  <fa-icon *ngIf="isSpinAndVisible(picture.url)" [icon]="faSpinner" [spin]="isSpinAndVisible(picture.url)"></fa-icon>
                </button>
                <button class="btn btn-secondary ml-3"
                (click)="deletePicture(picture.id)">
                  Delete
                  <fa-icon *ngIf="isSpinAndVisibleForDelete(picture.id)" [icon]="faSpinner" [spin]="isSpinAndVisibleForDelete(picture.id)"></fa-icon>
                </button>
              </div>
            </div>
          </div>




        </div>

      </div>
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
