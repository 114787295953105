import {Component, Input, OnInit} from '@angular/core';
import {FellowshipModel} from "../../../model/fellowship.model";
import {TextFormatService} from "../../../service/text-format/text-format.service";
import {ActivatedRoute} from "@angular/router";
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {UserService} from "../../../service/user/user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FellowshipreplyModel} from "../../../model/fellowshipreply.model";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-forum-item',
  templateUrl: './forum-item.component.html',
  styleUrls: ['./forum-item.component.scss']
})
export class ForumItemComponent implements OnInit {

  private forumId: number;
  forumItem: FellowshipModel;
  formGroup = new FormGroup({
    reply: new FormControl('', [Validators.required])
  })
  userSub: Subscription;
  userData: UserDataModel;
  isSaving: boolean;
  faTrash: any

  constructor(public textFormatService: TextFormatService,
              private activatedRoute: ActivatedRoute,
              private fellowshipService: FellowshipService,
              public userService: UserService,
              private store: Store<AppState>,
              private meta: Meta,
              private titleService: Title) {
    this.userSub = this.store.select('user').subscribe((user: UserModel) => {
      if (user.user) {
        this.userData = user.user;
      }
    });
    this.faTrash = faTrash;
  }

  ngOnInit(): void {
    this.forumId = this.activatedRoute.snapshot.params.forumId;
    this.getForumItem(this.forumId);
  }

  getForumItem(forumId: number) {
    this.fellowshipService.getForumItem(forumId)
      .subscribe(value => {
        this.forumItem = value;
        this.addMetaTags();
      });
  }


  get reply() {
    return this.formGroup.get('reply').value.trim();
  }

  set reply(val: string) {
    this.formGroup.get('reply').setValue(val);
  }

  submitReply() {
    if(!this.reply || this.reply.length == 0){
      return;
    }
    this.isSaving = true;
    this.fellowshipService.postFellowshipReply(this.reply, this.userData.id, this.forumItem.id)
      .subscribe((response: FellowshipreplyModel) => {
          this.isSaving = false;
          this.forumItem.replies.push(response)
          this.reply = '';
          // this.emitRefresh();
        },
        error => {
          this.isSaving = false;
        })
  }

  isViewerOwner(userId: number) {
    if (!this.userService.isUserAuthenticated()) {
      return false;
    }
    return this.userService.getSignedInUserId() === userId;
  }

  onDeleteClick(id: number) {
    this.fellowshipService
      .deleteForumReplyItem(id)
      .subscribe({
        next: value => {
          this.forumItem.replies = this.forumItem.replies.filter(value => value.id !== id);
        },
        error: err => {}
      })
  }

  private addMetaTags(){
    this.titleService.setTitle(`British Christian Network. UK Christian community fellowship | ${this.forumItem.userName} | ${this.forumItem.userLocationName}`);
    this.meta.updateTag({ name: 'keywords', content: `British UK christian community fellowship | ${this.forumItem.body} | ${this.forumItem.userLocationName}` });
    this.meta.updateTag({name: 'description', content: `British Christian community fellowship. ${this.forumItem.userName}.
    This could be a testimony, prayer or bible discussion`});

    /*face book open graph*/
    this.meta.updateTag({property: 'og:title', content: `British Christian Network. UK Christian community fellowship | ${this.forumItem.userName} | ${this.forumItem.userLocationName}`});
    this.meta.updateTag({property: 'og:description', content: `British christian community fellowship | ${this.forumItem.userName}.
    This could be a testimony, prayer or bible discussion`});
    this.meta.updateTag({property: 'og:image', content: `${this.forumItem.picUrl ? this.forumItem.picUrl : this.forumItem.userPicUrl}`});
    this.meta.updateTag({property: 'og:url', content: `https://www.britishchristian.com/community/forum/${this.forumItem.id}`})

    /*for twitter card*/
    this.meta.updateTag({property: 'twitter:card', content: `summary`});
    this.meta.updateTag({property: 'twitter:title', content: `British Christian Network. UK Christian community fellowship | ${this.forumItem.userName} | ${this.forumItem.userLocationName}`});
    this.meta.updateTag({property: 'twitter:description', content: `British christian community fellowship | ${this.forumItem.userName} | ${this.forumItem.userLocationName}.
    This could be a testimony, prayer or bible discussion`});
    this.meta.updateTag({property: 'twitter:image', content: `${this.forumItem.picUrl ? this.forumItem.picUrl : this.forumItem.userPicUrl}`})
  }

}
