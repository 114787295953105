import {Component, Input, OnInit} from '@angular/core';
import {LocationModel} from "../../../model/location.model";
import {LocationService} from "../../../service/location/location.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../service/user/user.service";
import {UserDataModel} from "../../../model/userModel";
import {Router} from "@angular/router";
import {
  AddNonSinglesSearchResult,
  AddSinglesSearchResult,
  SetNonSinglesSearchResult,
  SetSinglesSearchResult
} from "../seach.action";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {SearchModel} from "../../../model/search.model";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export enum UserSearchMode {
  SINGLE,
  NON_SINGLE
}

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {

  locations: LocationModel[] = [{id: 0, name: 'All'}];
  genders: {id: number; name: string}[] = [{id: 1, name: 'Male'}, {id: 2, name: 'Female'}]
  form: FormGroup = new FormGroup({
    name: new FormControl(''),
    location: new FormControl(this.locations[0]),
    gender: new FormControl(this.genders[0]),
    ageFrom : new FormControl('', [Validators.min(18)]),
    ageTo : new FormControl('')
  });
  users: UserDataModel[] = [];
  pageNumber = 0;
  @Input('searchMode') searchMode: UserSearchMode = UserSearchMode.NON_SINGLE;
  singleSearchMode = UserSearchMode.SINGLE;
  nonSingleSearchMode = UserSearchMode.NON_SINGLE;
  searchSub: Subscription;
  searchResults: SearchModel;
  isEndOfSearchResult: boolean = false;
  isSearching: boolean = false;
  faSpinner: any;
  constructor(private locationService: LocationService,
              private userService: UserService,
              private router: Router,
              private store: Store<AppState>) {
    //fa-icons
    this.faSpinner = faSpinner;


    //listeners
    this.searchSub = this.store.select(state => state.search)
      .subscribe(value => {
        this.searchResults = value;
        if (this.searchMode == UserSearchMode.SINGLE) {
          this.users = value.singlesSearch.users;
          this.pageNumber = value.singlesSearch.pageNumber;
          this.name = value.singlesSearch.searchCriteria.name;
          this.location = value.singlesSearch.searchCriteria.location;

        } else if (this.searchMode == UserSearchMode.NON_SINGLE) {
          this.users = value.nonSingleSearch.users;
          this.pageNumber = value.nonSingleSearch.pageNumber;
          this.name = value.nonSingleSearch.searchCriteria.name;
          this.location = value.nonSingleSearch.searchCriteria.location;
        }
      })

  }

  ngOnInit(): void {
    this.getLocations();
    if (this.searchMode == UserSearchMode.SINGLE) {
      this.users = this.searchResults.singlesSearch.users;
      this.pageNumber = this.searchResults.singlesSearch.pageNumber;
      this.name = this.searchResults.singlesSearch.searchCriteria.name;
      this.location = this.searchResults.singlesSearch.searchCriteria.location;
    } else if (this.searchMode == UserSearchMode.NON_SINGLE) {
      this.users = this.searchResults.nonSingleSearch.users;
      this.pageNumber = this.searchResults.nonSingleSearch.pageNumber;
      this.name = this.searchResults.nonSingleSearch.searchCriteria.name;
      this.location = this.searchResults.nonSingleSearch.searchCriteria.location;
    }

    if (this.users.length == 0) {
      this.search();
    }
  }

  getLocations() {
    this.locationService.getLocations()
      .subscribe((res: LocationModel[]) => {
        this.locations = this.locations.concat(res);
        if (this.searchMode == UserSearchMode.SINGLE) {
          const loc: LocationModel = this.locations.filter(value => value.id == this.searchResults.singlesSearch.searchCriteria.location.id)[0];
          if (loc) {
            this.location = loc;
          }else {
            this.location = this.locations[0];
          }
        }else if(this.searchMode == UserSearchMode.NON_SINGLE){
          const loc: LocationModel = this.locations.filter(value => value.id == this.searchResults.nonSingleSearch.searchCriteria.location.id)[0];
          if (loc) {
            this.location = loc;
          }else {
            this.location = this.locations[0];
          }
        }
      });
  }

  getName(): string {
    return this.form.get('name').value.trim();
  }

  set name(value: string) {
    this.form.get('name').setValue(value);
  }

  getLocation(): LocationModel {
    return this.form.get('location').value;
  }

  set location(val: {name: string; id: number}){
    this.form.get('location').setValue(val);
  }

  getAgeFromCtrl(): AbstractControl {
    return this.form.get('ageFrom');
  }

  get ageFrom(){
    return this.getAgeFromCtrl().value;
  }

  getAgeToCtrl(): AbstractControl {
    return this.form.get('ageTo');
  }

  get ageTo(): number {
    return this.getAgeToCtrl().value;
  }

  getGenderCtrl(): AbstractControl {
    return this.form.get('gender');
  }

  get genderId(): number {
    return this.getGenderCtrl().value.id;
  }


  onSearch() {
    this.pageNumber = 0;
    this.isEndOfSearchResult = false;
    this.search();
  }

  search(){
    this.isSearching = true;
    const singlesOnly: boolean = this.searchMode == UserSearchMode.SINGLE;
    this.userService.getUsers({name: this.getName(), locationId: this.getLocation().id, singlesOnly: singlesOnly, pageNumber: this.pageNumber,
    ageFrom: this.searchMode === this.singleSearchMode ? this.ageFrom : undefined,
      ageTo: this.searchMode === this.singleSearchMode ? this.ageTo : undefined,
      genderId: this.searchMode === this.singleSearchMode ? this.genderId : undefined})
      .subscribe((value: UserDataModel[]) => {
        this.isSearching = false;
        if (value.length > 0 || this.pageNumber == 0) {
          if (this.searchMode == UserSearchMode.SINGLE) {
            if (this.pageNumber == 0) {
              this.store.dispatch(new SetSinglesSearchResult({users: value, pageNumber: this.pageNumber,
                searchCriteria: {
                  name: this.getName(),
                  location: this.getLocation()}
              }));
            }else{
              this.store.dispatch(new AddSinglesSearchResult({users: value, pageNumber: this.pageNumber,
                searchCriteria: {
                  name: this.getName(),
                  location: this.getLocation()}
              }));
            }
          }else if (this.searchMode == UserSearchMode.NON_SINGLE) {
            if (this.pageNumber == 0) {
              this.store.dispatch(new SetNonSinglesSearchResult(({users: value, pageNumber: this.pageNumber,
                searchCriteria: {
                  name: this.getName(),
                  location: this.getLocation()}})));
            }else{
              this.store.dispatch(new AddNonSinglesSearchResult({users: value, pageNumber: this.pageNumber,
                searchCriteria: {
                  name: this.getName(),
                  location: this.getLocation()}}));
            }
          }
        }else {
          this.isEndOfSearchResult = true;
        }
      })
  }

  routeToUserView(userId: any) {
    this.router.navigate(['user', userId]);
  }

  onNext(){
    this.pageNumber++;
    this.search();
  }

  onCancel() {
    this.isEndOfSearchResult = false;
    this.form.get('name').setValue('');
    this.form.get('location').setValue(this.locations[0]);
    if (this.searchMode == UserSearchMode.SINGLE) {
      this.store.dispatch(new SetSinglesSearchResult({users: [], pageNumber:0, searchCriteria: {
          name: this.getName(),
          location: this.getLocation()}}));
    }else if (this.searchMode == UserSearchMode.NON_SINGLE) {
      this.store.dispatch(new SetNonSinglesSearchResult({users: [], pageNumber:0, searchCriteria: {
        name: this.getName(),
          location: this.getLocation()}}));
    }

    this.search();
  }
}
