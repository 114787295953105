import { Component, OnInit } from '@angular/core';
import {faArrowLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {Subscription} from "rxjs";
import {UserService} from "../../../service/user/user.service";
import {SetUserAction} from "../user.action";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackBarService} from "../../../service/snackbar/snack-bar.service";

@Component({
  selector: 'app-user-picture-edit',
  templateUrl: './user-picture-edit.component.html',
  styleUrls: ['./user-picture-edit.component.scss']
})
export class UserPictureEditComponent implements OnInit {
  faArrowLeft: any;
  faSpinner: any;
  userSub: Subscription;
  userData: UserDataModel;
  selectedPic: string
  private selectedPicForDelete: number;
  constructor(private store: Store<AppState>,
              private userService: UserService,
              private router: Router,
              private snackBarService: SnackBarService) {
    this.faArrowLeft = faArrowLeft;
    this.faSpinner = faSpinner;
    this.userSub = this.store.select('user').subscribe((user: UserModel) => {
      if (user.user) {
        this.userData = user.user;
      }
    })
  }

  ngOnInit() {
  }

  isProfilePic(pic: string): boolean {
    return this.userData.userHeaderPic === pic
  }

  updateProfilePic(url: string) {
    this.selectedPic = url;
    this.userService.updateUserProfilePicture(url, this.userData.id)
      .subscribe((user: UserDataModel) => {
        this.store.dispatch(new SetUserAction({user: user}));
        this.snackBarService.open("Profile picture updated!")
      })
  }

  isSpinAndVisible(url: string): boolean {
    return this.selectedPic === url;
  }

  isSpinAndVisibleForDelete(id: number): boolean {
    return this.selectedPicForDelete === id;
  }

  deletePicture(picId: number){
    this.selectedPicForDelete = picId;
    this.userService.deleteUserPicture(picId, this.userData.id)
      .subscribe((user: UserDataModel) => {
        this.store.dispatch(new SetUserAction({user: user}));
        // this.router.navigate(['/user'])
      })
  }
}
