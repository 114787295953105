<div class="d-flex flex-row justify-content-center">
  <div class="jumbotron">
    <h1 class="mat-h1">Directory of products and services</h1>
    <p class="mat-body">
      Used this page to find products, services, events, announcements and ministries.
    </p>
    <p>
      Promote your products, services, jobs, events, memorials etc with your very own dedicated web page for the community to locate you.
      <button routerLink="/advertise" mat-raised-button color="accent">Find out more</button>
    </p>
  </div>
</div>


<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row my-3 px-sm-3">
      <div *ngFor="let company of companies" class="col-sm-12 col-md-4">

        <div class="card mb-4 box-shadow border-0">
          <a [routerLink]="'/directory/'+company.id+'/'+company.systemName">
            <img class="card-img-top custom-img-fluid shadow"
                 alt="pic"
                 [src]="company.logo"
            >
          </a>
          <div class="card-body pl-0 pr-0 pt-2 pb-0">
            <a [routerLink]="'/directory/'+company.id+'/'+company.systemName">
              <h5 class="card-title">{{company.name}}</h5>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>





