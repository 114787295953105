<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div *ngFor="let product of products" class="d-flex border-dark">
      <a [routerLink]="'../product/'+product.id">
        <div>
          <!--name-->
          <div><span class="display-4">{{product.name}}</span></div>
        </div>
      </a>

    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
