import {AuthenticationModel} from "../model/userModel";

export class AuthenticationTransformer {
  static convertJsonToAuthentication(json: any): AuthenticationModel{
    return {
      id: Number.parseInt(json.id),
      roles: json.roles,
      token: json.access_token
    }
  }
}
