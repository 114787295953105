import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompanyService} from "../../company/service/company.service";
import {CompanyModel} from "../../company/model/company.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {Subscription} from "rxjs";
import {UserModel} from "../../../model/userModel";
import {CompanyModule} from "../../company/company.module";

@Component({
  selector: 'app-directory-home',
  templateUrl: './directory-home.component.html',
  styleUrls: ['./directory-home.component.scss']
})
export class DirectoryHomeComponent implements OnInit, OnDestroy {

  companies: CompanyModel[];
  userSub: Subscription;
  user: UserModel;
  constructor(private companyService: CompanyService,
              private store: Store<AppState>) {
    this.userSub = store.select('user').subscribe(((user: UserModel) => {
      this.user = user;
    }))
  }

  ngOnInit() {
    this.getAllCompanies();
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  getAllCompanies(){
    this.companyService.getAllCompanies()
      .subscribe((companies: CompanyModel[]) => {
        this.companies = companies;
      })
  }

}
