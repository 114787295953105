<div class="row px-xs-5">
  <div class="col-sm-1"></div>
  <div class="col-sm-12 col-md-10">
    <div>
      <!--headline news or article-->
      <div class="row py-5">
        <div *ngIf="articles[0]?.displayPic" class="col-sm-12 col-md-4">
          <a [routerLink]="'/article/'+articles[0]?.id">
            <img *ngIf="articles[0]?.displayPic" class="card-img-top"
                 data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
                 alt="header image"
                 [src]="articles[0]?.displayPic"
                 data-holder-rendered="true"
                 style="height: 225px;
                 width: 100%;
                 display: block;">
          </a>
        </div>
        <div class="col-sm-12 col-md-8">
          <a [routerLink]="'/article/'+articles[0]?.id" class="display-4">{{articles[0]?.subject}}</a>
        </div>
      </div>
      <hr/>

      <div class="row">
        <div class="col-sm-12 col-md-9">
          <!--central mid section-->
          <div><h1>Recent articles</h1></div>
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <div class="card mb-4 box-shadow border-0">
                <a *ngIf="articles[1]?.displayPic" [routerLink]="'/article/'+articles[1]?.id">
                  <img class="card-img-top custom-img-fluid shadow"
                       alt="article pic"
                       [src]="articles[1]?.displayPic"
                  >
                </a>
                <div class="card-body pl-0 pr-0 pt-2 pb-0">
                  <a [routerLink]="'/article/'+articles[1]?.id">
                    <h3 class="card-title">
                      {{articles[1]?.subject}}
                    </h3>
                  </a>
                </div>
              </div>

            </div>
            <div class="col-sm-12 col-md-4">
              <div class="card mb-4 box-shadow border-0">
                <a [routerLink]="'/article/'+articles[2]?.id">
                  <img class="card-img-top custom-img-fluid shadow"
                       alt="article pic"
                       [src]="articles[2]?.displayPic"
                  >
                </a>
                <div class="card-body pl-0 pr-0 pt-2 pb-0">
                  <a [routerLink]="'/article/'+articles[2]?.id"><h3
                    class="card-title">{{articles[2]?.subject}}</h3></a>
                </div>
              </div>

            </div>
            <div class="col-sm-12 col-md-4">
              <div class="card mb-4 box-shadow border-0">
                <a [routerLink]="'/article/'+articles[3]?.id">
                  <img class="card-img-top custom-img-fluid shadow"
                       alt="article pic"
                       [src]="articles[3]?.displayPic"
                  >
                </a>
                <div class="card-body pl-0 pr-0 pt-2 pb-0">
                  <a [routerLink]="'/article/'+articles[3]?.id"><h3
                    class="card-title">{{articles[3]?.subject}}</h3></a>
                </div>
              </div>

            </div>
          </div>

          <!--relationship articles-->
          <div>
            <div><h1>Relationship</h1></div>
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a *ngIf="relationshipArticles[0]?.displayPic" [routerLink]="'/article/'+relationshipArticles[0]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="relationshipArticles[0]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+relationshipArticles[0]?.id"><h3
                      class="card-title">{{relationshipArticles[0]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a [routerLink]="'/article/'+relationshipArticles[1]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="relationshipArticles[1]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+relationshipArticles[1]?.id"><h3
                      class="card-title">{{relationshipArticles[1]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a [routerLink]="'/article/'+relationshipArticles[2]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="relationshipArticles[2]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+relationshipArticles[2]?.id"><h3
                      class="card-title">{{relationshipArticles[2]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!--prayer articles-->
          <div>
            <div><h1>Prayer</h1></div>
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a *ngIf="prayerArticles[0]?.displayPic" [routerLink]="'/article/'+prayerArticles[0]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="prayerArticles[0]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+prayerArticles[0]?.id"><h3
                      class="card-title">{{prayerArticles[0]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a [routerLink]="'/article/'+prayerArticles[1]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="prayerArticles[1]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+prayerArticles[1]?.id"><h3
                      class="card-title">{{prayerArticles[1]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a [routerLink]="'/article/'+prayerArticles[2]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="prayerArticles[2]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+prayerArticles[2]?.id"><h3
                      class="card-title">{{prayerArticles[2]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!--music articles-->
          <div>
            <div><h1>Music</h1></div>
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a *ngIf="musicArticles[0]?.displayPic" [routerLink]="'/article/'+musicArticles[0]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="musicArticles[0]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+musicArticles[0]?.id"><h3
                      class="card-title">{{musicArticles[0]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a [routerLink]="'/article/'+musicArticles[1]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="musicArticles[1]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+musicArticles[1]?.id"><h3
                      class="card-title">{{musicArticles[1]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
              <div class="col-sm-12 col-md-4">
                <div class="card mb-4 box-shadow border-0">
                  <a [routerLink]="'/article/'+musicArticles[2]?.id">
                    <img class="card-img-top custom-img-fluid shadow"
                         alt="article pic"
                         [src]="musicArticles[2]?.displayPic"
                    >
                  </a>
                  <div class="card-body pl-0 pr-0 pt-2 pb-0">
                    <a [routerLink]="'/article/'+musicArticles[2]?.id"><h3
                      class="card-title">{{musicArticles[2]?.subject}}</h3></a>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
        <div class="col-sm-12 col-md-3">
          <!--right side section-->
          <div class="row mt-5 mt-md-2 mx-sm-3">
            <div class="col-sm-12 d-flex justify-content-center">
              <a [routerLink]="'/advertise'">
                <img  class="card-img-top custom-img-fluid shadow" src="/assets/host-advert-banner.png"/>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-sm-1"></div>
</div>



