import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ArticleTypeModel} from "../../model/article-type.model";
import {LocationModel} from "../../model/location.model";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private locationApi = "/web/api";
  constructor(private httpClient: HttpClient) { }

  public getLocations(): Observable<LocationModel[]> {
    const url = `${this.locationApi}/country/list`;
    return this.httpClient.get<LocationModel[]>(url);
  }
}
