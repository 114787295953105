<div class="">
  <div class="d-flex flex-column justify-content-center border-bottom border-dark pb-3">
    <h5 class="">
      <span>Your directory listings</span>
    </h5>
    <p class="text-black-50">Listed here are your directory listings if any</p>
    <div>
      <a [routerLink]="'/corporate/add'"
         class="ml-3 btn btn-primary btn-lg d-inline-block"><b>Create new organisation</b></a>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-between mt-3 border border-dark p-3"
       *ngFor="let company of companies">
    <div>
      <a [routerLink]="'/corporate/'+company.id"><span class="f-l text-dark">{{company.name}}</span></a>
    </div>
    <div class="d-flex mt-2">
      <div class="mt-2 d-inline-block">
<!--        <div class="d-inline-block"><a [routerLink]="'/corporate/edit/pic/'+company.id+'/picture/edit'">Add pics</a></div>-->
        <div class="d-inline-block ml-2"><a [routerLink]="'/corporate/edit/'+company.id">Edit</a></div>
      </div>
      <div class="ml-2"><a class="btn btn-secondary" [routerLink]="'/product/manage/add/null'">Add product</a></div>
    </div>

    <!--products-->
    <div class="mt-3 ml-4">
      <div>
        <span class="text-black-50">Products ({{company.products.length}})</span>
      </div>
      <div class="d-flex justify-content-between"
           *ngFor="let product of company.products">
        <div><a [routerLink]="'/product/'+product.id">{{product.name}}</a></div>
        <div class="d-flex">
<!--          <div><a [routerLink]="'/corporate/edit/pic/'+product.id+'/picture/edit'">Add pics</a></div>-->
          <div class="ml-2"><a [routerLink]="'/product/manage/edit/'+product.id">Edit</a></div>
        </div>
      </div>
    </div>
  </div>
</div>


