import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RegistrationRoutingModule} from './registration-routing.module';
import {SignupComponent} from './signup/signup.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EmailVerifyComponent} from './email-verify/email-verify.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";


@NgModule({
  declarations: [SignupComponent, EmailVerifyComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RegistrationRoutingModule,
        FormsModule,
        FontAwesomeModule
    ]
})
export class RegistrationModule {
}
