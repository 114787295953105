import {Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {FellowshipModel} from "../../../model/fellowship.model";
import {Subscription} from "rxjs";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {ForumTypeEnum} from "../../../model/forum-type.enum";
import {faChurch, faMicrophone, faPray, faSpinner, faUsers} from "@fortawesome/free-solid-svg-icons";
import {ForumModel} from "../../../model/forum.model";
import {SetActiveForumTypeAction} from "../forum.action";
import {MatChipSelectionChange} from "@angular/material/chips";
import {UserSearchMode} from "../../search/user-search/user-search.component";
import {UserService} from "../../../service/user/user.service";
import {MatTabChangeEvent} from "@angular/material/tabs/tab-group";

enum FilterBtnEnum {
  FELLOWSHIP = 1,
  PRAYER= 2,
  TESTIMONY= 3,
  NONE= 4
}

@Component({
  selector: 'app-community-home',
  templateUrl: './community-home.component.html',
  styleUrls: ['./community-home.component.scss']
})
export class CommunityHomeComponent implements OnInit, OnDestroy {
  fellowshipMessage: string = null;
  fellowshipPhoto;
  fellowshipListItems: FellowshipModel[] = [];
  fellowshipPageCounter = 0;
  userSub: Subscription;
  userData: UserDataModel;
  errorMsg;
  activeForumType: ForumTypeEnum = ForumTypeEnum.ALL;
  allForumType = ForumTypeEnum.ALL;
  fellowshipForumType = ForumTypeEnum.FELLOWSHIP;
  prayerForumType = ForumTypeEnum.PRAYER;
  testimonyForumType = ForumTypeEnum.TESTIMONY;
  singlesForumType = ForumTypeEnum.DATING;
  postForumType: ForumTypeEnum = ForumTypeEnum.FELLOWSHIP;
  faSpinner: any;
  faPray: any
  faChurch: any;
  faMicrophone: any;
  faImages: any;
  faUsers: any;

  showSpinner: boolean = false;
  showPreview: boolean = false;
  nonSingleSearchMode: UserSearchMode = UserSearchMode.NON_SINGLE;

  @ViewChild('forumStream', {static: false}) forumStream;
  isDatingPostPanel: boolean = false;
  activeSinglesLink = 'People';
  forumSub: Subscription;
  forumModel: ForumModel;
  forumTypeId: ForumTypeEnum = ForumTypeEnum.ALL;
  isSelectedFellowshipChip: boolean = true;
  isSelectedPrayerChip: boolean = false;
  isSelectedTestimonyChip: boolean = false;
  unSelectedEvent = new MouseEvent('unselected', {bubbles: true});
  selectedFilterBtn: FilterBtnEnum = FilterBtnEnum.NONE;

  constructor(private fellowshipService: FellowshipService,
              private store: Store<AppState>,
              public renderer2: Renderer2,
              public userService: UserService) {

    this.faSpinner = faSpinner;
    this.faPray = faPray;
    this.faChurch = faChurch;
    this.faMicrophone = faMicrophone;
    this.faUsers = faUsers;

    this.userSub = this.store.select('user').subscribe((user: UserModel) => {
      if (user.user) {
        this.userData = user.user;
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(new SetActiveForumTypeAction(ForumTypeEnum.FELLOWSHIP));
  }

  submit() {
    if (!this.userData.id) {
      return false;
    }
    if ((!this.fellowshipMessage || this.fellowshipMessage.length == 0) && !this.fellowshipPhoto) {
      this.errorMsg = 'Invalid. You must type a conversation, upload a picture or both';
      return false;
    }

    this.errorMsg = null;

    let form = new FormData();
    if (this.fellowshipPhoto) {
      form.append('fellowshipPic', this.fellowshipPhoto, this.fellowshipPhoto.name);
    }
    form.append('userId', this.userData.id + '');
    form.append('forumType', this.postForumType + '');

    if (this.fellowshipMessage && this.fellowshipMessage.length > 0) {
      form.append('comment', this.fellowshipMessage);
    } else {
      form.append('comment', '');
    }
    this.fellowshipMessage = '';
    this.showSpinner = true;
    this.fellowshipService.postFellowship(form)
      .subscribe((fellowship: FellowshipModel) => {
        this.showSpinner = false;
        this.fellowshipMessage = null;
        this.fellowshipPhoto = null;
        this.fellowshipListItems.unshift(fellowship)
      });
  }

  onChangeFellowshipPhoto(event) {
    this.fellowshipPhoto = event.target.files[0];
    this.doPreview(event.target);
  }

  private doPreview(input) {
    if (input.files && input.files[0]) {
      this.showPreview = true;
      let reader = new FileReader();
      reader.onload = (e: any) => {
        document.getElementById('preview').setAttribute('src', e.target.result);
      };
      reader.readAsDataURL(input.files[0]); // convert to base64 string
    } else {
      this.showPreview = false;
      document.getElementById('preview').setAttribute('src', '');
    }
  }

  getFellowshipItems(page: number, type: ForumTypeEnum) {
    this.fellowshipService.getFellowshipList(page, type)
      .subscribe((fellowshipArray: FellowshipModel[]) => {
        this.fellowshipListItems = this.fellowshipListItems.concat(fellowshipArray);
      })
  }

  nextFellowshipItemsPage() {
    this.fellowshipPageCounter++;
    this.getFellowshipItems(this.fellowshipPageCounter, this.activeForumType);
  }

  ngOnDestroy(): void {
  }

  onNavClick(forumType: ForumTypeEnum) {
    this.activeForumType = forumType;
    this.postForumType = forumType;
    this.fellowshipListItems = [];
    this.fellowshipPageCounter = 0;

    this.store.dispatch(new SetActiveForumTypeAction(forumType));
    if (this.activeForumType === ForumTypeEnum.DATING) {
      this.isDatingPostPanel = true;
    }else{
      this.isDatingPostPanel = false;
    }
  }

  isNavSelected(forumType: ForumTypeEnum) {
    return forumType === this.activeForumType;
  }

  getForumTypeString(forumType: ForumTypeEnum): string {
    switch (forumType) {
      case ForumTypeEnum.ALL:
        return 'All'
      case ForumTypeEnum.FELLOWSHIP:
        return 'Fellowship'
      case ForumTypeEnum.PRAYER:
        return 'Prayer'
      case ForumTypeEnum.TESTIMONY:
        return 'Testimony'
      case ForumTypeEnum.DATING:
        return 'Dating'
      default:
        return 'All'
    }
  }

  getActiveForumTypeFromString(val: string): ForumTypeEnum{
    switch (val) {
      case 'All':
        return ForumTypeEnum.ALL
      case 'Fellowship':
        return ForumTypeEnum.FELLOWSHIP
      case 'Prayer':
        return ForumTypeEnum.PRAYER
      case 'Testimony':
        return ForumTypeEnum.TESTIMONY
      case 'Dating':
        return ForumTypeEnum.DATING
      default:
        return ForumTypeEnum.FELLOWSHIP
    }
  }

  chipSelection($event: MatChipSelectionChange, key: string) {
    if (key === 'fellowship') {
      this.isSelectedFellowshipChip = !this.isSelectedFellowshipChip;
      this.isSelectedPrayerChip = false;
      this.isSelectedTestimonyChip = false;
    }else if (key === 'prayer') {
      this.isSelectedPrayerChip = !this.isSelectedPrayerChip;
    }else{
      this.isSelectedTestimonyChip = !this.isSelectedTestimonyChip;
    }
    console.log($event);
  }

  // @ViewChild('fellowshipFilterBtn') fellowshipFilterBtn: HTMLElement

  applyFilterBtn(key: string) {
    if (key === 'prayer') {
      document.getElementById('fellowshipFilterBtn').dispatchEvent(this.unSelectedEvent);
      document.getElementById('testimonyFilterBtn').dispatchEvent(this.unSelectedEvent);
      if (this.selectedFilterBtn === FilterBtnEnum.PRAYER) {
        document.getElementById('prayerFilterBtn').dispatchEvent(this.unSelectedEvent);
        this.selectedFilterBtn = FilterBtnEnum.NONE;
      }else{
        this.selectedFilterBtn = FilterBtnEnum.PRAYER;
      }
    }else if (key === 'fellowship') {
      document.getElementById('prayerFilterBtn').dispatchEvent(this.unSelectedEvent);
      document.getElementById('testimonyFilterBtn').dispatchEvent(this.unSelectedEvent);
      if (this.selectedFilterBtn === FilterBtnEnum.FELLOWSHIP) {
        document.getElementById('fellowshipFilterBtn').dispatchEvent(this.unSelectedEvent);
        this.selectedFilterBtn = FilterBtnEnum.NONE;
      }else{
        this.selectedFilterBtn = FilterBtnEnum.FELLOWSHIP;
      }
    }else if (key === 'testimony') {
      document.getElementById('fellowshipFilterBtn').dispatchEvent(this.unSelectedEvent);
      document.getElementById('prayerFilterBtn').dispatchEvent(this.unSelectedEvent);
      if (this.selectedFilterBtn === FilterBtnEnum.TESTIMONY) {
        document.getElementById('testimonyFilterBtn').dispatchEvent(this.unSelectedEvent);
        this.selectedFilterBtn = FilterBtnEnum.NONE;
      }else{
        this.selectedFilterBtn = FilterBtnEnum.TESTIMONY;
      }
    }

    switch (this.selectedFilterBtn) {
      case FilterBtnEnum.FELLOWSHIP:
        this.forumTypeId = ForumTypeEnum.FELLOWSHIP;
        break;
      case FilterBtnEnum.PRAYER:
        this.forumTypeId = ForumTypeEnum.PRAYER;
        break;
      case FilterBtnEnum.TESTIMONY:
        this.forumTypeId = ForumTypeEnum.TESTIMONY;
        break;
      case FilterBtnEnum.NONE:
        this.forumTypeId = ForumTypeEnum.ALL;
        break;
      default:
        this.forumTypeId = ForumTypeEnum.ALL;
        break;
    }
  }

  viewerIsLoggedInUser() {
    return this.userService.isUserAuthenticated()
  }

  onSelectionChange(obj: MatTabChangeEvent) {
    console.log(obj);
  }
}
