import {DatingNetwork, UserDataModel, UserModel} from "../../model/userModel";
import * as UserActions from './user.action'
import {SET_AUTHENTICATION, SET_USER_BEING_VIEWED} from './user.action'
import {tassign} from "tassign";
import {FollowModel} from "../../model/follow.model";

export let initialState: UserModel = {
  user: null,
  authentication: {token: null, roles: [], id: null},
  datingNetwork: {
    users: [],
    page: 0
  },
  userInView: null
};

export function reducer(state: UserModel = initialState, action: UserActions.Actions): UserModel {
  switch (action.type) {
    case SET_AUTHENTICATION:
      return tassign(state, {authentication: action.payload.authentication});
    case "[AUTHENTICATION] SET_AUTHENTICATION_TOKEN":
      const mergedToken = tassign(state.authentication, {token: action.payload.token, id:action.payload.id})
      return tassign(state, {authentication: mergedToken});
    case "[USER] SET_USER":
      return tassign(state, {user: action.payload.user});
    case "[USER] DELETE_USER":
      return tassign(state, {user: null, authentication: {token: null, roles: [], id: null}})
    case "[USER] ADD_DATING_NETWORK_USERS":
      const datingNetwork: DatingNetwork =
        tassign(state.datingNetwork, {users: state.datingNetwork.users.concat(action.payload)})
      return tassign(state, {datingNetwork: datingNetwork});
    case "[USER] SET_DATING_NETWORK_PAGE":
      const datingNetworkWithPage: DatingNetwork =
        tassign(state.datingNetwork, {page: action.payload})
      return tassign(state, {datingNetwork: datingNetworkWithPage});
    case "[USER] REMOVE_USER_FOLLOWING":
      let f: FollowModel[] = [...state.user.following];
      let index = f.indexOf(action.payload);
      f.splice(index, 1);
      let u:UserDataModel = tassign(state.user, {following: f});
      return tassign(state, {user: u});
    case "[USER] ADD_USER_FOLLOWING":
      const af: FollowModel[] = [...state.user.following];
      af.push(action.payload);
      return tassign(state, {user: tassign(state.user, {following: af})})
    case "[USER] SET_USER_BEING_VIEWED":
      return tassign(state, {userInView: action.payload})
    default:
      return state;
  }
}
