import {ArticleReplyModel} from "../model/article-reply.model";
import {ArticleModel} from "../model/article.model";

export class ArticleTransfromer {

  static transformToArticleModel(json: any): ArticleModel {
    return {
      id: json.id,
      subject: json.subject,
      comment: json.comment,
      displayPic: json.displayPic,
      editorialPic: json.editorialPic,
      summary: json.summary,
      headerVideoEmbedSrc: json.headerVideoEmbedSrc,
      section1VideoEmbedSrc: json.section1VideoEmbedSrc,
      section2VideoEmbedSrc: json.section2VideoEmbedSrc,
      section3VideoEmbedSrc: json.section3VideoEmbedSrc,

      section2Body: json.section2Body,
      section3Body: json.section3Body,
      section1ImageUrl: json.section1ImageUrl,
      section2ImageUrl: json.section2ImageUrl,
      section3ImageUrl: json.section3ImageUrl,

      authorId: json.user.id,
      authorName: json.user.name,
      articleTypeId: json.articleType.id,
      articleTypeName: json.articleType.name,
      articleReplies: json.articleReplyList,
    }
  }

  static transformToArticleReply(articleReplyJson: any): ArticleReplyModel {
    return {
      id: articleReplyJson.id,
      comment: articleReplyJson.comment,
      userId: articleReplyJson.userId,
      userName: articleReplyJson.userName,
      commentDate: articleReplyJson.commentDate,
      userPic: articleReplyJson.userPic
    }
  }

  static transformToArticleReplyArray(articleJson: any[]): ArticleReplyModel[] {
    let arr: ArticleReplyModel[] = [];
    for (let json of articleJson) {
      arr.push(this.transformToArticleReply(json));
    }
    return arr;
  }
}
