import {CompanyModel} from "../component/company/model/company.model";
import {ProductTransformer} from "./product-transformer";
import {VodcastTransformer} from "./vodcast-transformer";

export class CompanyTransformer{
  static transformCompanyJsonToCompanyModel(company): CompanyModel{
    return {
      id: company.id,
      name: company.name,
      description: company.description,
      locationName: company.country.name,
      locationId: company.country.id,
      logo: company.logo,
      telephone: company.telephone,
      email: company.email,
      website: company.website,
      products: null,
      vlog: company.vodcastList ? VodcastTransformer.transformArrToModel(company.vodcastList) : [],
      systemName: company.systemName,
      websiteLabel: company.websiteLabel
    }
  }
}
