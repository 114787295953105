import {MessageModel} from "../model/message.model";
import {UserTransformer} from "./user-transformer";
import {valueReferenceToExpression} from "@angular/compiler-cli/src/ngtsc/annotations/src/util";

export class MessageTransformer {
  public static transformToMessageModel(json: any):MessageModel {
    return {
      fromUser: UserTransformer.convertToUser(json.fromUser),
      toUser: UserTransformer.convertToUser(json.toUser),
      id: json.id,
      message: json.message,
      datePosted: json.datePosted
    }
  }

  public static transformMessages(json: any): MessageModel[] {
    let arr: MessageModel[] = [];
    json.forEach(value => {
      arr.push(MessageTransformer.transformToMessageModel(value))
    })
    return arr;
  }
}
