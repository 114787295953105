import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {CompanyService} from "../../company/service/company.service";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {DirectoryModel} from "../../directory/model/directory.model";
import {Subscription} from "rxjs";
import {CompanyModel} from "../../company/model/company.model";
import {SetUserOrganisations} from "../../directory/directory.action";
import {FormControl, FormGroup} from "@angular/forms";
import {ForumTypeEnum} from "../../../model/forum-type.enum";
import {SetNewForumPost} from "../forum.action";
import {FellowshipModel} from "../../../model/fellowship.model";

@Component({
  selector: 'app-forum-post-panel',
  templateUrl: './forum-post-panel.component.html',
  styleUrls: ['./forum-post-panel.component.scss']
})
export class ForumPostPanelComponent implements OnInit {

  directorySub: Subscription;
  userOrgs: CompanyModel[];
  userSub: Subscription;
  userData: UserDataModel;
  fellowshipMessage: string = null;

  postAsFormGp = new FormGroup({
    org: new FormControl('', [])
  });
  fellowshipPhoto :File;
  errorMsg;
  isSaving: boolean = false;

  @Input('postForumType')postForumType: ForumTypeEnum = ForumTypeEnum.FELLOWSHIP;
  fellowshipForumType = ForumTypeEnum.FELLOWSHIP;
  prayerForumType = ForumTypeEnum.PRAYER;
  testimonyForumType = ForumTypeEnum.TESTIMONY;
  singlesForumType = ForumTypeEnum.DATING;

  // @Input('isDating') isDating: boolean;
  @Output() onContentPosted: EventEmitter<ForumTypeEnum>;
  @Input('includeSinglesOptions') includeSinglesOptions: boolean

  constructor(private fellowshipService: FellowshipService,
              private store: Store<AppState>,
              private companyService: CompanyService) {
    this.userSub = this.store.select('user').subscribe((user: UserModel) => {
      if (user.user) {
        this.userData = user.user;
      }
    })

    this.directorySub = this.store.select('directory').subscribe((directory: DirectoryModel) => {
      if (directory.userOrganisations && directory.userOrganisations.length > 0) {
        this.userOrgs = directory.userOrganisations;
      }
    })
  }

  ngOnInit(): void {
    this.postForumType = ForumTypeEnum.FELLOWSHIP;
    if (this.userData) {
      this.getUserCompanies(this.userData.id);
    }
  }

  getUserCompanies(userId: number) {
    this.companyService.getCompanies(userId)
      .subscribe((companies: CompanyModel[]) => {
        this.store.dispatch(new SetUserOrganisations({companies: companies}))
      })
  }

  getOrgId(){
    const postAsVal = this.postAsFormGp.get('org').value;
    if (postAsVal && postAsVal > 0) {
      return postAsVal;
    }else{
      return null;
    }
  }

  onChangeFellowshipPhoto(event) {
    this.fellowshipPhoto = event.target.files[0];
  }

  submit() {
    if (!this.userData.id) {
      return false;
    }

    if ((!this.fellowshipMessage || this.fellowshipMessage.length == 0) && !this.fellowshipPhoto) {
      this.errorMsg = 'Invalid. You must type a conversation, upload a picture or both';
      return false;
    }

    this.isSaving = true;
    this.errorMsg = null;

    let form = new FormData();
    if (this.fellowshipPhoto) {
      form.append('fellowshipPic', this.fellowshipPhoto, this.fellowshipPhoto.name);
    }
    form.append('userId', this.userData.id+'');
    form.append('forumType', this.postForumType+'');
    if (this.getOrgId()) {
      form.append('orgId', this.getOrgId());
    }

    if (this.fellowshipMessage && this.fellowshipMessage.length > 0) {
      form.append('comment', this.fellowshipMessage);
    }else{
      form.append('comment', '');
    }
    this.fellowshipService.postFellowship(form)
      .subscribe((fellowship: FellowshipModel) => {
        this.fellowshipMessage = null;
        this.fellowshipPhoto = null;
        this.isSaving = false;
        this.store.dispatch(new SetNewForumPost({type: this.postForumType}))
        // this.emitOnContentPosted();
      }, error => {
        this.isSaving = false;
      });
  }


  hasUserOrgs(): boolean {
    return this.userOrgs && this.userOrgs.length > 0;
  }

  emitOnContentPosted(){
    this.onContentPosted.emit(this.postForumType);
  }

}
