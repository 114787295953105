import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsRoutingModule } from './news-routing.module';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsCreateComponent } from './news-create/news-create.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { NewsViewComponent } from './news-view/news-view.component';
import {Nl2BrPipeModule} from "nl2br-pipe";
import {MomentModule} from "ngx-moment";
import { NewsPhotoEditComponent } from './news-photo-edit/news-photo-edit.component';


@NgModule({
  declarations: [NewsListComponent, NewsCreateComponent, NewsViewComponent, NewsPhotoEditComponent],
  imports: [
    CommonModule,
    NewsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    Nl2BrPipeModule,
    MomentModule
  ]
})
export class NewsModule { }
