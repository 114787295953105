import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserDataModel} from "../../../model/userModel";
import {UserService} from "../../../service/user/user.service";
import * as moment from 'moment'
import {Moment} from "moment";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-user-view-about',
  templateUrl: './user-view-about.component.html',
  styleUrls: ['./user-view-about.component.scss']
})
export class UserViewAboutComponent implements OnInit, OnChanges {

  userBeingViewedData: UserDataModel;

  constructor(private userService: UserService, private store: Store<AppState>) {
    this.store.select(state => state.user.userInView)
      .subscribe(user => this.userBeingViewedData = user)
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  isUserSingleNetworkMember(): boolean {
    return this.userService.isUserInSinglesGroup(this.userBeingViewedData);
  }

  getAge(){
    if (this.userBeingViewedData.dob) {
      return moment().diff(moment.utc(this.userBeingViewedData.dob*1000), 'years');
    }else {
      return NaN;
    }
  }
}
