import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ProductService} from "../../../service/product/product.service";
import {ProductModel} from "../../../model/product.model";

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {

  productId: number;
  product: ProductModel;
  constructor(private activatedRoute: ActivatedRoute,
              private productService: ProductService ) {

  }

  ngOnInit() {
    this.productId = this.activatedRoute.snapshot.params.id;
    this.getProduct();
  }

  getProduct(){
    this.productService.getProduct(this.productId)
      .subscribe((product: ProductModel) => {
        this.product = product;
      })
  }

}
