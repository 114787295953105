import { Component, OnInit } from '@angular/core';
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {ActivatedRoute} from "@angular/router";
import {VodcastService} from "../../../service/vodcast/vodcast.service";
import {VodcastModel} from "../../../model/vodcast.model";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-organisation-vodcast',
  templateUrl: './organisation-vodcast.component.html',
  styleUrls: ['./organisation-vodcast.component.scss']
})
export class OrganisationVodcastComponent implements OnInit {

  organisationId: number;
  vodcasts: VodcastModel[];
  constructor(private activatedRoute: ActivatedRoute,
              private vodcastService: VodcastService,
              private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.organisationId = this.activatedRoute.snapshot.parent.params.id;
    this.getVideos();
  }

  getVideos(){
    this.vodcastService.getAllVodcastForOrganisation(this.organisationId)
      .subscribe((vodcasts: VodcastModel[]) => {
        this.vodcasts = vodcasts;
      })
  }

  sanitiseVideoUrl(vid){
    return this.sanitizer.bypassSecurityTrustResourceUrl(vid);
  }

}
