<div class="row p-2">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
<!--    <div *ngIf="isViewerLoggedIn(); else nonLoggedIn">-->
    <div>
<!--      <app-forum-post-panel *ngIf="isViewerLoggedIn() && isViewerInSinglesNetwork()" [isDating]="true"></app-forum-post-panel>-->
      <div *ngIf="isViewerLoggedIn() && !isViewerInSinglesNetwork()">
        <mat-card class="my-2 d-flex flex-row justify-content-center">
          <mat-card-content>
            <h1>
              You are not a member of the Christian singles network. <a routerLink="/user/{{user.user.id}}/settings/dating-profile">Join here</a>
              to gain the access to post messages on the dating forum and start interacting with Christian singles
            </h1>
          </mat-card-content>
        </mat-card>
      </div>
      <div *ngIf="!isViewerLoggedIn()" class="row mt-1">
        <div class="col-sm-12 col-md-10 mx-3 m-sm-0">
          <div>
            <span> 2 Corinthians 6:14
Do not be unequally yoked with unbelievers. For what partnership has righteousness with lawlessness?
            Or what fellowship has light with darkness?
          </span></div>
          <mat-card class="my-2 d-flex flex-row justify-content-center">
            <mat-card-content >
              <h1>
                <a  routerLink="/login">Login</a> OR <a routerLink="/signup">Signup</a>
                to start interacting with Christian singles
              </h1>
            </mat-card-content>
          </mat-card>

        </div>
      </div>
      <div *ngIf="!isViewerInSinglesNetwork()" class="row">
        <div class="col-sm-12 col-md-4 m-0 m-sm-0">
          <mat-card>
            <img mat-card-image src="https://nigeriachristian.s3-eu-west-1.amazonaws.com/1_2084118863_Fotolia_76119610_XS.jpg"/>
          </mat-card>
        </div>
        <div class="col-sm-12 col-md-8">
          <!--mat table implementation-->
          <mat-table class="mat-elevation-z8" [dataSource]="relationshipFacilitiesDataSource">
            <ng-container matColumnDef="icon">
              <mat-header-cell *matHeaderCellDef>Icon</mat-header-cell>
              <mat-cell *matCellDef="let data" class="icon-col">
                <div class="list-icon">
                  <mat-icon [inline]="true" color="accent">check_circle_outline</mat-icon>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="label">
              <mat-header-cell *matHeaderCellDef>Label</mat-header-cell>
              <mat-cell *matCellDef="let data">{{data.label}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedCols" class="header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedCols" class="icon-row"></mat-row>
          </mat-table>
        </div>
      </div>
      <div *ngIf="isViewerLoggedIn()">
        <!--<nav mat-tab-nav-bar mat-align-tabs="center">
          <a mat-tab-link
             (click)="setActiveLink('People')"
             [active]="activeLink == 'People'"> People </a>
          <a mat-tab-link
             (click)="setActiveLink('Dating')"
             [active]="activeLink == 'Dating'"> Singles forum </a>
        </nav>
        <app-people-gallery *ngIf="activeLink==='People'"></app-people-gallery>
        <app-forum-stream *ngIf="activeLink==='Dating'" [forumTypeId]="this.forumTypeId"></app-forum-stream>-->
        <router-outlet></router-outlet>
      </div>

    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>




