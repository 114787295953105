<div class="d-flex flex-row">
    <img class="card-img-top rounded-circle shadow-lg"
         alt="header image"
         [src]="userData?.userHeaderPic"
         data-holder-rendered="true"
         style="height: 150px;
                 /*width: 100%;*/
                 width: 150px;
                 display: block;"/>
  <div class="d-inline-block ml-3">
    <h2 class="bol">{{userData?.name}}</h2>
    <div>
      <p>Member since: <span>{{userData?.regDate | amFromUnix | amDateFormat:'LL'}}</span></p>
    </div>
    <div>
      <div *ngIf="activatedRoute.routeConfig.path === 'pictures'; else showLink">
        <label class="d-block" for="profile-pic">Update profile photo</label>
        <input ngModel #input id="profile-pic" type="file" class="form-control-lg pl-0"
               (change)="onChangeProfilePhoto($event)">
        <div class="mt-2">
          <button mat-flat-button color="primary"
                  (click)="onUploadClick()">Upload
          </button>
          <app-spinner *ngIf="isSaving" class="ml-2 d-inline-block"
                        [spinner-options]="{isSpinning: isSaving, size: '1px'}">
          </app-spinner>
        </div>
      </div>
      <ng-template #showLink>
        <a [routerLink]="'/user/setting/pictures'">Update profile photo</a>
      </ng-template>

    </div>
  </div>
</div>
