import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../../service/user/user.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {FellowshipModel} from "../../../model/fellowship.model";
import {faAddressCard, faImages, faNewspaper} from "@fortawesome/free-solid-svg-icons";
import {FollowModel} from "../../../model/follow.model";
import {AddUserFollowingRecord, RemoveUserFollowingRecord, SetUserBeingViewed} from "../user.action";
import {UserMessageCreateComponent} from "../user-message-create/user-message-create.component";
import {MatDialog} from "@angular/material/dialog";
import {UserSearchMode} from "../../search/user-search/user-search.component";
import {UserNetworkComponent} from "../user-network/user-network.component";
import {Meta, Title} from "@angular/platform-browser";

enum PageMode {
  HOME_PAGE = 'HOME',
  GALLERY_PAGE = 'GALLERY'
}

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit, OnDestroy, AfterViewInit {

  userViewableData: UserDataModel;
  userViewableId: number;
  fellowshipPage = 0;
  fellowshipListItems: FellowshipModel[] = [];
  faNewspaper; faAddressCard; faImages: any;
  pageMode: PageMode = PageMode.HOME_PAGE;
  activeLink = 'home';
  userData: UserDataModel;
  userSub: Subscription;
  activatedRouteSub: Subscription;
  nonSingleSearchMode: UserSearchMode = UserSearchMode.NON_SINGLE;
  showProfileSettings = false;
  user: UserModel;
  isAuthenticated: boolean;

  constructor(public userService: UserService,
              private store: Store<AppState>,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private titleService: Title,
              private meta: Meta) {
    /*icons*/
    this.faNewspaper = faNewspaper;
    this.faAddressCard = faAddressCard;
    this.faImages = faImages;

    this.userSub = this.store.select('user').subscribe(user => {
      if (user.user) {
        this.userData = user.user;
      }
      this.user = user;
      this.setShowProfileSettings();
      this.addMetaTags();
    });

    this.activatedRouteSub = this.activatedRoute.params.subscribe(value => {
      this.userViewableId = value.id;
      this.getUser(this.userViewableId);
      // this.store.dispatch(new IncrementForumsRevisionCounter());
    });
  }

  ngOnInit() {
    this.isAuthenticated = this.userService.isUserAuthenticated();
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
    this.activatedRouteSub.unsubscribe();
  }

  getUser(userId: number) {
    this.userService.getUser(userId).subscribe((userViewable: UserDataModel) => {
      this.userViewableData = userViewable;
      if (!this.userViewableData.userHeaderPic || this.userViewableData.userHeaderPic.length == 0) {
        this.userViewableData.userHeaderPic = this.userService.userNoPic;
      }
      //set to store
      this.store.dispatch(new SetUserBeingViewed(this.userViewableData));

    })
  }

  isViewerAFollower(userBeingViewedId: number): boolean{
    return this.userData?.following.filter(value => value.followingId === userBeingViewedId).length > 0;
  }

  unfollow(viewerUserId: number) {
    let f: FollowModel[] = this.userData.following.filter(value => value.followingId === viewerUserId);
    this.userService.deleteFollow(f[0].id)
      .subscribe(value => {
        this.store.dispatch(new RemoveUserFollowingRecord(f[0]));
        this.getUser(this.userViewableId);
      });
  }

  follow(userId: number, vieweeUserId){
    let f: FollowModel = {userId: userId, followingId: vieweeUserId} as FollowModel;
    this.userService.follow(f)
      .subscribe(value => {
        this.store.dispatch(new AddUserFollowingRecord(value));
        this.getUser(this.userViewableId);
      })
  }

  viewerIsLoggedInUser() {
    return this.userData?.id === this.userViewableData?.id;
  }

  openPrivateMessagingDialog(): void {
    const dialogRef = this.dialog.open(UserMessageCreateComponent, {
      width: '500px',
      data: {fromId: this.userData.id, toId: this.userViewableData.id},
    });
  }

  openShowNetworkDialog(network: FollowModel[]): void {
    const dialogRef = this.dialog.open(UserNetworkComponent, {width: '500px',data: network});
  }

  setShowProfileSettings(){
    this.showProfileSettings = this.user.authentication.id && this.user.authentication?.id === this.user.userInView?.id
  }

  private addMetaTags(){
    this.titleService.setTitle(`Christian user profile | ${this.userViewableData?.name} | ${this.userViewableData?.location.name}`);
    this.meta.updateTag({ name: 'keywords', content: `User profile of the British Christian Network. The UK Christian community | ${this.userViewableData?.name} in ${this.userViewableData?.location.name}` });
    this.meta.updateTag({name: 'description', content: `User profile of the British Christian Network. The UK Christian community | ${this.userViewableData?.name} in ${this.userViewableData?.location.name}`});

    /*face book open graph*/
    this.meta.updateTag({property: 'og:title', content: `User profile of the British Christian Network. The UK Christian community | ${this.userViewableData?.name}`});
    this.meta.updateTag({property: 'og:description', content: `User profile of the British Christian Network. The UK Christian community | ${this.userViewableData?.name}`});
    this.meta.updateTag({property: 'og:url', content: `${this.userViewableData?.userHeaderPic}`})

    /*for twitter card*/
    this.meta.updateTag({property: 'twitter:card', content: `summary`});
    this.meta.updateTag({property: 'twitter:title', content: `UK Christian community user profile| ${this.userViewableData?.name}`});
    this.meta.updateTag({property: 'twitter:description', content: `User profile of the British Christian Network. The UK Christian community | ${this.userViewableData?.name}`});
  }

}
