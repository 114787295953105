<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div>
      <form>
        <div class="d-flex justify-content-center"><h1>Edit news pictures</h1></div>

        <!--article title-->
        <div><span class="display-4">{{news?.headline}}</span></div>

        <!--header pic edit-->
        <div class="mt-4">
          <div><h6>Update header photo</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="form-group d-flex flex-column">
                  <label for="header-pic">Select picture for header</label>
                  <input id="header-pic"
                         width="200"
                         type="file"
                         class="form-control-lg"
                         size="40"
                         (change)="onChangePhoto($event, headerType)">
                  <button
                    class="btn btn-primary btn-lg"
                    (click)="submit(headerPhoto, headerType)"
                  >Update header pic
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr/>

        <!--display pic edit-->
        <div class="mt-4">
          <div><h6>Update display photo</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="form-group d-flex flex-column">
                  <label for="header-pic">Select picture for display section</label>
                  <input id="display-pic"
                         width="200"
                         type="file"
                         class="form-control-lg"
                         size="40"
                         (change)="onChangePhoto($event, displayType)">
                  <button
                    class="btn btn-primary btn-lg"
                    (click)="submit(displayPhoto, displayType)"
                  >Update display pic
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr/>

        <!--section 1 pic edit-->
        <div class="mt-4">
          <div><h6>Update section 1 photo</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="form-group d-flex flex-column">
                  <label for="section1-pic">Select picture for section 1</label>
                  <input id="section1-pic"
                         width="200"
                         type="file"
                         class="form-control-lg"
                         size="40"
                         (change)="onChangePhoto($event, section1Type)">
                  <button
                    class="btn btn-primary btn-lg"
                    (click)="submit(section1Photo, section1Type)"
                  >Update section 1 pic
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <!--section 2 picture select-->
        <div class="mt-4">
          <div><h6>Update section 2 photo</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="form-group d-flex flex-column">
                  <label for="section2-pic">Select picture for section 2</label>
                  <input id="section2-pic"
                         width="200"
                         type="file"
                         class="form-control-lg"
                         size="40"
                         (change)="onChangePhoto($event, section2Type)">
                  <button
                    class="btn btn-primary btn-lg"
                    (click)="submit(section2Photo, section2Type)"
                  >Update section 2 pic
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>

