import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {UserDataModel, UserModel} from "../../model/userModel";
import {UserService} from "../../service/user/user.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../app.state";
import {faHouseUser, faCog} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  userObservable: Observable<UserModel>;
  userSub: Subscription;
  userData: UserDataModel;

  isLoggedIn: boolean = false;
  faHouseUser; faCog: any;
  constructor(private userService: UserService,
              private router: Router,
              private store: Store<AppState>) {
    this.faCog = faCog;
    this.faHouseUser = faHouseUser;

    this.userSub = this.store.select('user').subscribe(user => {
      this.userData = user.user;
      if (this.userData && this.userData.id) {
        this.isLoggedIn = true;
      }else{
        this.isLoggedIn = false;
      }
    });
  }

  ngOnInit() {
  }

  logout() {
    this.userService.deleteUserCookies();
    this.router.navigate(['/']);
  }
}
