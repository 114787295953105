<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row px-sm-3">
      <div class="col-sm-12">
        <div class=" d-flex flex-row justify-content-center mt-5">
          <div>
            <div class="border border-primary rounded p-4">
              <h5 class="card-title">Update notification settings</h5>
              <div><span class="">Use this form to control how we notify you of new content</span></div>
            </div>
            <div class="form-check mt-5">
              <input class="form-check-input" type="checkbox" value="" id="prayer" [(ngModel)]="prayerNotificationModel">
              <label class="form-check-label" for="prayer">
                Notify of prayers and prayer requests
              </label>
            </div>

            <div class="form-check mt-3">
              <input class="form-check-input" type="checkbox" value="" id="news" [(ngModel)]="newsNotificationModel">
              <label class="form-check-label" for="news">
                Notify me of christian news from the UK and around the world
              </label>
            </div>

            <div class="form-check mt-3">
              <input class="form-check-input" type="checkbox" value="" id="ed" [(ngModel)]="editorialNotificationModel">
              <label class="form-check-label" for="ed">
                Notify me when new articles and messages are published
              </label>
            </div>

            <button class="btn btn-lg btn-secondary btn-block mt-5" (click)="submit()">
              <span class="text-white">Update notification settings</span>
            </button>
          </div>


        </div>

      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>
