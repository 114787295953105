<!--input area-->
<div *ngIf="userData?.id" class="mt-3">
  <div class="card p-4">
    <div>
      <label class="d-block" id="forum-radio-group-label">Select a forum</label>
      <mat-radio-group
        aria-labelledby="forum-radio-group-label"
        class="my-2"
        [(ngModel)]="postForumType">
        <mat-radio-button class="" [value]="fellowshipForumType">
          Fellowship forum
        </mat-radio-button>
        <mat-radio-button class="ml-2" [value]="prayerForumType">
          Prayer forum
        </mat-radio-button>
        <mat-radio-button class="ml-2" [value]="testimonyForumType">
          Testimony forum
        </mat-radio-button>
        <mat-radio-button *ngIf="includeSinglesOptions" class="ml-2" [value]="singlesForumType">
          Singles forum
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <div class="form-group mt-3">
<!--        <div *ngIf="isNavSelected(fellowshipForumType)"><label for="message">Post a message to fellowship
          forum</label></div>
        <div *ngIf="isNavSelected(prayerForumType)"><label for="message">Post a prayer or prayer request</label></div>
        <div *ngIf="isNavSelected(testimonyForumType)"><label for="message">Share a testimony</label></div>-->
        <label for="message"><p>Say something</p></label>
        <textarea id="message" class="form-control" rows="5" [(ngModel)]="fellowshipMessage"></textarea>
      </div>
      <div class="form-group">
        <div><span>Add a picture</span></div>
        <input type="file" class="form-control-lg pl-0"
               (change)="onChangeFellowshipPhoto($event)">
      </div>
      <div *ngIf="errorMsg" class="my-3">
        <span class="text-danger">{{errorMsg}}</span>
      </div>
      <div class="d-flex flex-row">
        <button (click)="submit()" class="btn btn-primary">
          Post <app-spinner *ngIf="isSaving" [spinner-options]="{isSpinning: true}"></app-spinner>
        </button>
        <div *ngIf="hasUserOrgs()" class="d-flex flex-row">
          <div class="px-3">as</div>
          <form [formGroup]="postAsFormGp" class="">
            <select class="form-control" formControlName="org">
              <option [value]="'-1'" [selected]="true">{{userData.name}}</option>
              <option *ngFor="let org of userOrgs" [value]="org.id">{{org.name}}</option>
            </select>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>
