import { Component, OnInit } from '@angular/core';
import {ArticleService} from "../../../service/article/article.service";
import {ArticleModel} from "../../../model/article.model";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  articles: ArticleModel[] = [];
  topArticle: ArticleModel;
  pageNumber:number = 0;
  nextPage:number = 1;
  prevPage:number = 0;
  constructor(private articleService: ArticleService,
              private titleService: Title,
              private meta: Meta,
              private activatedRoute: ActivatedRoute,
              private router: Router) {

  }

  ngOnInit() {
    this.pageNumber = Number.parseInt(this.activatedRoute.snapshot.params['page']);
    this.nextPage = this.pageNumber as number + 1;
    this.prevPage = this.pageNumber > 0 ? this.pageNumber -1 : 0;
    this.addMetaTags();
    this.getNews(this.pageNumber);
  }

  getNews(pageNumber: number) {
    this.articleService.getArticles(pageNumber)
      .subscribe((newsList: ArticleModel[]) => {
        if (!this.topArticle) {
          this.topArticle = newsList.shift();
        }
        this.articles = this.articles.concat(newsList);
      })
  }

  more() {
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
      // this.router.navigate([`/${url}`]).then(()=>{
      this.router.navigate([`articles/${++this.pageNumber}`]).then(()=>{
        console.log(`After navigation I am on:${this.router.url}`)
      })
    })
    // this.router.navigate([`articles/${++this.pageNumber}`])
    // this.getNews(++this.pageNumber)
  }

  private addMetaTags(){
    this.titleService.setTitle(`British Christian devotionals articles, news and publications page ${this.pageNumber}`);
    this.meta.updateTag({ name: 'keywords', content: `British Christian articles, news and publications for the UK Christian community` });
    this.meta.updateTag({name: 'description', content: `British Christian articles, news and publications for the UK Christian community`});

    /*face book open graph*/
    this.meta.updateTag({property: 'og:title', content: `British Christian articles and publications`});
    this.meta.updateTag({property: 'og:description', content: `British Christian articles, news and publications for the UK Christian community`});
    this.meta.updateTag({property: 'og:url', content: `https://www.britishchristian.com/articles`})

    /*for twitter card*/
    this.meta.updateTag({property: 'twitter:card', content: `summary`});
    this.meta.updateTag({property: 'twitter:title', content: `British Christian articles and publications`});
    this.meta.updateTag({property: 'twitter:description', content: `British Christian articles, news and publications for the UK Christian community`});
  }

}
