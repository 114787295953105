<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div *ngIf="news">
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <!--header section-->
          <div>
            <div class="py-5"><span class="display-4">{{news?.headline}}</span></div>
            <div class="mt-3">
              <span>{{news.datePostedEpoch  | amFromUnix | amDateFormat:'LL'}}</span>
              <hr/>
            </div>
            <!--headerphoto or video-->
            <div *ngIf="isNotEmpty(news.headerVid)" class="d-flex justify-content-center">
              <iframe width="560" height="315"
                      [src]="sanitiseVideoUrl(news.headerVid)"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>

              </iframe>
            </div>
            <div *ngIf="isNotEmpty(news.headerPic)" class="mt-3">
              <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
                   alt="news pic"
                   [src]="news.headerPic"
              >
            </div>
          </div>

          <!--summary section-->
          <!--<div *ngIf="isNotEmpty(news.summary)" class="shadow p-5 mt-3">
            <span [innerHTML]="news.summary | nl2br: true"></span>
          </div>-->

          <!--body section one-->
          <div *ngIf="isNotEmpty(news.section1Img)" class="mt-3">
            <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
                 alt="news pic"
                 [src]="news.section1Img"
            >
          </div>
          <div *ngIf="isNotEmpty(news.section1Vid)" class="d-flex justify-content-center mt-3">
            <iframe width="560" height="315"
                    [src]="sanitiseVideoUrl(news.section1Vid)"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>

            </iframe>
          </div>
          <div class="mt-5">
            <p [innerHTML]="news.section1Body | nl2br: true"></p>
          </div>


          <!--body section two-->
          <div>
            <div *ngIf="isNotEmpty(news.section2Img)" class="mt-3">
              <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
                   alt="news pic"
                   [src]="news.section2Img"
              >
            </div>
            <div *ngIf="isNotEmpty(news.section2Vid)" class="d-flex justify-content-center mt-3">
              <iframe width="560" height="315"
                      [src]="sanitiseVideoUrl(news.section2Vid)"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>

              </iframe>
            </div>
            <div class="mt-5">
              <p [innerHTML]="news.section2Body | nl2br: true"></p>
            </div>
          </div>

<!--          &lt;!&ndash;section 3&ndash;&gt;
          <div>
            <div *ngIf="isNotEmpty(news.section3ImageUrl)" class="mt-3">
              <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
                   alt="news pic"
                   [src]="news.section3ImageUrl"
              >
            </div>
            <div *ngIf="isNotEmpty(news.section3VideoEmbedSrc)" class="d-flex justify-content-center mt-3">
              <iframe width="560" height="315"
                      [src]="sanitiseVideoUrl(news.section3VideoEmbedSrc)"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>

              </iframe>
            </div>
            <div class="mt-5">
              <p [innerHTML]="news.section3Body | nl2br: true"></p>
            </div>
          </div>-->
        </div>

        <!--right side section in md screen or lowest in sm screen-->
        <div class="col-sm-0 col-md-4"></div>
      </div>


      <div>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>

