<div *ngIf="isUserAdminRole()" class="card my-3 border-color-secondary  border-secondary">
  <div class="card-body">
    <h5 class="card-title d-inline-block">
      <span>Articles publications</span>
    </h5>
    <a [routerLink]="'/article/create/null'"
       class="ml-3 btn btn-primary btn-sm d-inline-block"><b>New Article</b></a>
    <p>Listed here are your published and pending articles</p>
    <div class="d-flex justify-content-between"
         *ngFor="let article of articles">
      <div>
        <a [routerLink]="'/article/'+article.id"
           class="">{{article.subject}}</a>

      </div>
      <div class="d-flex">
        <div><a [routerLink]="'/article/'+article.id+'/picture/edit'">Add pics</a></div>
        <div class="ml-2"><a [routerLink]="'/article/edit/'+article.id">Edit</a></div>
        <!--            <div class="ml-2">Publish</div>-->
        <!--            <div class="ml-2">Delete</div>-->
      </div>
    </div>
  </div>
</div>
