import {VodcastModel} from "../model/vodcast.model";

export class VodcastTransformer {
  static transformToModel(json): VodcastModel {
    return {
      id: json.id,
      embedUrl: json.embedUrl,
      url: json.videoUrl
    }
  }
  static transformArrToModel(jsonArr: any): VodcastModel[] {
    let arr: VodcastModel[] = [];
    for (let v of jsonArr) {
      arr.push(VodcastTransformer.transformToModel(v));
    }
    return arr;
  }
}
