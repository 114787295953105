<div class="row">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="my-3" *ngFor="let message of messages">
      <div><span class="font-weight-bold">From: <a [routerLink]="'/user/'+message.fromUser.id">{{message.fromUser.name}}</a></span></div>
      <div><span class="mb-2">Date: {{message.datePosted  | amFromUnix | amDateFormat:'LL'}}</span></div>
      <div><span>{{message.message}}</span></div>
    </div>
  </div>
  <div class="col-md-1"></div>
</div>



