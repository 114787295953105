import { Component, OnInit } from '@angular/core';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "../../../service/user/user.service";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {UserModel} from "../../../model/userModel";
import {Router} from "@angular/router";


@Component({
  selector: 'app-user-deactivate',
  templateUrl: './user-deactivate.component.html',
  styleUrls: ['./user-deactivate.component.scss']
})
export class UserDeactivateComponent implements OnInit {
  faArrowLeft: any;
  userId: number;
  constructor(private userService: UserService,
              private store: Store<AppState>,
              private router: Router) {
    store.select('user').subscribe(user => {
      if (user.user) {
        this.userId = user.user.id
      }
    });
    this.faArrowLeft = faArrowLeft;
  }

  ngOnInit() {
  }

  deactivate() {
    this.userService.deactivateUser(this.userId)
      .subscribe(res => {
        this.userService.deleteUserCookies();
        this.router.navigate(['/'])
      })
  }
}
