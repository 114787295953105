<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row px-sm-3">
      <div class="col-sm-12">
        <div class=" d-flex flex-row justify-content-center mt-5">
          <div>
            <div class="border border-primary rounded p-4">
              <h5 class="card-title">Deactivate account</h5>
              <div><span class="">Click the button below to close your account. Account closures cannot be reactivated.</span></div>
            </div>
            <button class="btn btn-lg btn-secondary btn-block mt-5" (click)="deactivate()">
              <span class="text-white">Deactivate subscription</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>
