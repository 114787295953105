<div class="row px-xs-5">
  <div class="cols-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class=" d-flex flex-column justify-content-center mt-5">
      <div class="border border-primary rounded p-4 d-flex justify-content-center">
        <h5 class="card-title">Update your password</h5>
      </div>
      <div class="mt-3">
        <label for="new-password">Enter new password</label>
        <input id="new-password" type="password"
               [(ngModel)]="pw"
               class="form-control"/>
      </div>
      <div>
        <label for="c-password">Confirm password</label>
        <input id="c-password" type="password"
               [(ngModel)]="cpw"
               class="form-control"/>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-lg btn-secondary mt-5" (click)="submit()">
          <span class="text-white">Update password</span>
        </button>
      </div>
      <div class="mt-2 d-flex justify-content-center">
        <span class="text-danger">{{errMsg}}</span>
      </div>
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
