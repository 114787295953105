import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProductAddComponent} from "./product-add/product-add.component";
import {ProductViewComponent} from "./product-view/product-view.component";


const routes: Routes = [
  {
    path: 'product/manage/:mode/:id',
    component: ProductAddComponent
  },
  {
    path: 'product/:id',
    component: ProductViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule { }
