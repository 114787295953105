import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RegistrationService} from "../registration.service";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {Router} from "@angular/router";
import {SignupModel} from "../../../model/signup.model";
import {ArticleTypeModel} from "../../../model/article-type.model";
import {LocationService} from "../../../service/location/location.service";
import {LocationModel} from "../../../model/location.model";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  locations: LocationModel[];
  form = new FormGroup({
    username: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    confirmEmail: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(3)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(3)]),
    locationId: new FormControl(0, [Validators.min(1)])
  });

  faCheck: any;
  errorMessage: string;
  constructor(private registrationService: RegistrationService,
              private router: Router,
              private locationDService: LocationService) {
    this.faCheck = faCheck;
  }

  ngOnInit() {
    this.getLocations();
  }

  get username() {
    return this.form.get('username');
  }

  get email() {
    return this.form.get('email');
  }

  get confirmEmail() {
    return this.form.get('confirmEmail');
  }

  get locationId() {
    return this.form.get('locationId');
  }

  isEmailMatching(): boolean {
    return this.email.value === this.confirmEmail.value;
  }

  submit() {
    this.errorMessage = null;
    if (this.form.valid) {
      const signup: SignupModel = {
        id: null,
        name: this.username.value,
        email: this.email.value,
        password: this.form.get('password').value,
        locationId: this.locationId.value
      };
      this.registrationService.postRegistration(signup)
        .subscribe((user: UserDataModel) => {
            this.router.navigate(['/email-verify', user.id]);
          },
          error => {
            if (error.status == 409) {
              this.errorMessage = "Signup failed. This email is already in use";
            }else{
              this.errorMessage = "Signup failed. Try again later";
            }
          });
    } else {
    }


  }

  getLocations() {
    this.locationDService.getLocations()
      .subscribe((res: LocationModel[]) => {
        this.locations = res;
      });
  }
}


