import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NewsModel} from "../../../model/news.model";
import {NewsService} from "../service/news.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.scss']
})
export class NewsViewComponent implements OnInit {
  id;
  news: NewsModel;

  constructor(private activatedRoute: ActivatedRoute,
              private newsService: NewsService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;
    this.getNews(this.id);
  }

  getNews(id: number) {
    this.newsService.getNews(id)
      .subscribe(news => {
        this.news = news;
      })
  }

  isNotEmpty(videoUrl: string): boolean{
    if (videoUrl && videoUrl.length > 0) {
      return true;
    }
    return false;
  }

  sanitiseVideoUrl(vid){
    return this.sanitizer.bypassSecurityTrustResourceUrl(vid);
  }

}
