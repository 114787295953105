import {Injectable} from '@angular/core';
import {NewsModel} from "../../../model/news.model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PicTypeEnum} from "../../../service/article/article.service";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private httpClient: HttpClient) { }

  postNews(data: NewsModel): Observable<NewsModel> {
    const url = `${environment.webApiNamespace}/news/create`;
    return this.httpClient.post<NewsModel>(url, data)
  }

  getNewsPage(pageNumber: number): Observable<NewsModel[]>{
    const url = `${environment.webApiNamespace}/news/list/${pageNumber}`;
    return this.httpClient.get<NewsModel[]>(url);
  }

  getNews(id: number): Observable<NewsModel> {
    const url = `${environment.webApiNamespace}/news/${id}`;
    return this.httpClient.get<NewsModel>(url);
  }

  editNewsPhotos(newsId: number, formData: FormData, picType: PicTypeEnum): Observable<NewsModel> {
    const url = `${environment.webApiNamespace}/news/photo/${picType}/${newsId}`;
    return this.httpClient.put<NewsModel>(url, formData);
  }

  editNews(news: NewsModel): Observable<NewsModel>{
    const url = `${environment.webApiNamespace}/news/edit`;
    return this.httpClient.put<NewsModel>(url, news)
  }
}
