import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../service/user/user.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {CompanyService} from "../../company/service/company.service";
import {ProductService} from "../../../service/product/product.service";
import {ArticleService} from "../../../service/article/article.service";
import {Router} from "@angular/router";
import {NewsService} from "../../news/service/news.service";
import {ArticleModel} from "../../../model/article.model";
import {Observable, Subscription} from "rxjs";
import {UserDataModel, UserModel} from "../../../model/userModel";

@Component({
  selector: 'app-user-article-mgmt',
  templateUrl: './user-article-mgmt.component.html',
  styleUrls: ['./user-article-mgmt.component.scss']
})
export class UserArticleMgmtComponent implements OnInit {

  articles: ArticleModel[];
  userObservable: Observable<UserModel>;
  userSub: Subscription;
  userData: UserDataModel;
  constructor(private userService: UserService,
              private store: Store<AppState>,
              private articleService: ArticleService,
              private router: Router) {
    this.userSub = this.store.select('user').subscribe(user => {
      if (user.user) {
        this.userData = user.user;
      }
    });
  }

  ngOnInit() {
    this.getUserArticles();
  }

  isUserAdminRole() {
    return this.userData && this.userData.authority.includes('ADMIN');
  }

  getUserArticles() {
    this.articleService.getArticles(0)
      .subscribe(articles => {
        this.articles = articles;
      })
  }
}
