import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UserProfileComponent} from "./user-profile/user-profile.component";
import {UserSettingComponent} from "./user-setting/user-setting.component";
import {UserDeactivateComponent} from "./user-deactivate/user-deactivate.component";
import {UserSettingNotificationComponent} from "./user-setting-notification/user-setting-notification.component";
import {UserViewComponent} from "./user-view/user-view.component";
import {UserProfileEditComponent} from "./user-profile-edit/user-profile-edit.component";
import {UserPictureEditComponent} from "./user-picture-edit/user-picture-edit.component";
import {UserGalleryComponent} from "./user-gallery/user-gallery.component";
import {UserPasswordSettingComponent} from "./user-password-setting/user-password-setting.component";
import {UserAccountComponent} from "./user-account/user-account.component";
import {UserCommunityHomeComponent} from "./user-community-home/user-community-home.component";
import {UserArticleMgmtComponent} from "../article/user-article-mgmt/user-article-mgmt.component";
import {UserDirectoryMgmtComponent} from "../directory/user-directory-mgmt/user-directory-mgmt.component";
import {UserViewHomeComponent} from "./user-view-home/user-view-home.component";
import {UserViewVidsComponent} from "./user-view-vids/user-view-vids.component";
import {UserSettingHomeComponent} from "./user-setting-home/user-setting-home.component";
import {DatingProfileEditComponent} from "./dating-profile-edit/dating-profile-edit.component";
import {ForumStreamComponent} from "../forum/forum-stream/forum-stream.component";
import {PeopleGalleryComponent} from "../people/people-gallery/people-gallery.component";
import {UserViewAboutComponent} from "./user-view-about/user-view-about.component";
import {UserMessageCreateComponent} from "./user-message-create/user-message-create.component";
import {UserMessageHomeComponent} from "./user-message-home/user-message-home.component";
import {UserMessageComponent} from "./user-message/user-message.component";
import {UserNetworkComponent} from "./user-network/user-network.component";
import {UserSearchComponent} from "../search/user-search/user-search.component";


const routes: Routes = [
  {
    path: 'user/:id',
    component: UserViewComponent,
    children: [
      {
        path: '', // child route path
        // pathMatch: 'full',
        component: UserViewHomeComponent,
        children: [
          {
            path: '',
            component: UserCommunityHomeComponent
          },
          {
            path: 'about',
            component: UserViewAboutComponent
          },
          {
            path: 'photos',
            component: UserGalleryComponent,
          },
          /*{
            path: 'videos',
            component: UserViewVidsComponent,
          },*/
          {
            path: 'search',
            component: UserSearchComponent,
          },
        ]
      },

      {
        path: 'message/:toId',
        component: UserMessageCreateComponent
      },
      {
        path: 'settings',
        component: UserSettingHomeComponent,
        children: [
          {
            path: '',
            component: UserSettingComponent
          },
          {
            path: 'general',
            component: UserProfileEditComponent
          },
          {
            path: 'dating-profile',
            component: DatingProfileEditComponent
          },
          {
            path: 'deactivate',
            component: UserDeactivateComponent
          },
          {
            path: 'notifications',
            component: UserSettingNotificationComponent
          },
          {
            path: 'pictures',
            component: UserPictureEditComponent
          },
          {
            path: 'password',
            component: UserPasswordSettingComponent
          }
        ]
      },

      /*{
        path: 'user/:id/following',
        component: UserNetworkComponent
      },*/
      {
        path: 'following',
        component: UserNetworkComponent
      },
      {
        path: 'followers',
        component: UserNetworkComponent
      },
      {
        path: 'articles',
        component: UserArticleMgmtComponent,
      },
      {
        path: 'directory',
        component: UserDirectoryMgmtComponent,
      },
      {
        path: 'messages',
        component: UserMessageHomeComponent
      },
      {
        path: 'message/:user1Id/:user2Id',
        component: UserMessageComponent
      }
    ]
  },
  /*{
    path: 'account',
    component: UserAccountComponent,
    children: [
      {
        path: '',
        component: UserSettingHomeComponent,
        children: [
          {
            path: '',
            component: UserSettingComponent
          },
          {
            path: 'general',
            component: UserProfileEditComponent
          },
          {
            path: 'dating',
            component: DatingProfileEditComponent
          },
          {
            path: 'deactivate',
            component: UserDeactivateComponent
          },
          {
            path: 'notifications',
            component: UserSettingNotificationComponent
          },
          {
            path: 'pictures',
            component: UserPictureEditComponent
          },
          {
            path: 'password',
            component: UserPasswordSettingComponent
          }
        ]
      },

    ]
  },*/



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
