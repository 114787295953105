import {NewForumPostModel} from "../../model/forum.model";
import {Action} from "@ngrx/store";
import {ForumTypeEnum} from "../../model/forum-type.enum";

export let  SetNewForumPostType: string = '[SetNewForumPost]'
export const SET_ACTIVE_FORUM_TYPE = "[SetActiveForumTypeAction]SET_ACTIVE_FORUM_TYPE"
export const INCREMENT_FORUMS_REVISION_COUNTER = "[Forum]SET_INCREMENT_FORUMS_REVISION_COUNTER"

export class SetNewForumPost{
  readonly type = SetNewForumPostType;
  constructor(public payload: NewForumPostModel ) {
  }
}

export class SetActiveForumTypeAction implements Action {
  readonly type = SET_ACTIVE_FORUM_TYPE;

  constructor(public payload: ForumTypeEnum) {
  }
}

export class IncrementForumsRevisionCounter implements Action {
  readonly type = INCREMENT_FORUMS_REVISION_COUNTER;

  constructor(public payload = null) {
  }
}

export type ForumAction = SetNewForumPost | SetActiveForumTypeAction | IncrementForumsRevisionCounter;
