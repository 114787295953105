import {Action} from "@ngrx/store";
import {UserDataModel} from "../../model/userModel";

export const AddSinglesSearchResultType = "[Search]AddSinglesSearchResult"
export const SetSinglesSearchResultType = "[Search]SetSinglesSearchResult"
export const SetNonSinglesSearchResultType = "[Search]SetNonSinglesSearchResultType"
export const AddNonSinglesSearchResultType = "[Search]AddNonSinglesSearchResultType"



export class SetSinglesSearchResult implements Action {
  readonly type: string = SetSinglesSearchResultType;

  constructor(public payload:
                {
                  users: UserDataModel[];
                  pageNumber: number;
                  searchCriteria?: {
                    name: string;
                    location: { id: number, name: string }
                  }
                }){}
}

export class AddSinglesSearchResult implements Action {
  readonly type: string = AddSinglesSearchResultType;

  constructor(public payload: { users: UserDataModel[]; pageNumber: number,
    searchCriteria?: {
      name: string;
      location: { id: number, name: string }
    }}) {}
}

export class SetNonSinglesSearchResult implements Action {
  readonly type: string = SetNonSinglesSearchResultType;

  constructor(public payload: {
    users: UserDataModel[];
    pageNumber: number;
    searchCriteria?: {
      name: string;
      location: { id: number, name: string }
    }
  }) {
  }
}

export class AddNonSinglesSearchResult implements Action {
  readonly type: string = AddNonSinglesSearchResultType;

  constructor(public payload: {
    users: UserDataModel[];
    pageNumber: number;
    searchCriteria?: {
      name: string;
      location: { id: number, name: string }
    }
  }) {
  }
}


export type SearchActions = AddSinglesSearchResult | SetSinglesSearchResult;
