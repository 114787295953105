import {ForumModel} from "../../model/forum.model";
import {ForumAction, INCREMENT_FORUMS_REVISION_COUNTER, SetNewForumPostType} from "./forum.action";
import {tassign} from "tassign";
import {SET_ACTIVE_FORUM_TYPE} from "../forum/forum.action";
import {ForumTypeEnum} from "../../model/forum-type.enum";

let initialState: ForumModel = {
  newPost: null,
  postRevisionCounter: 0,
  activeForumType: ForumTypeEnum.FELLOWSHIP
}
export function reducer(state: ForumModel = initialState, action: ForumAction){
  switch (action.type) {
    case SetNewForumPostType:
      let counter = state.postRevisionCounter+1;
      let newState = tassign(state, {postRevisionCounter: counter});
      return tassign(newState, {newPost: action.payload});
    case SET_ACTIVE_FORUM_TYPE:
      let ns =  tassign(state, {activeForumType: action.payload});
      return ns;
    case INCREMENT_FORUMS_REVISION_COUNTER:
      const nc = state.postRevisionCounter+1;
      return tassign(state, {postRevisionCounter: nc})
    default:
      return state;
  }
}
