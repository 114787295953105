import {Injectable} from '@angular/core';
import {UserDataModel, UserModel} from "../../model/userModel";
import {Observable, Subscription, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {UserTransformer} from "../../transformer/user-transformer";
import {environment} from "../../../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {AppState} from "../../app.state";
import {Store} from "@ngrx/store";
import {DeleteUserAction, SetUserAction} from "../../component/user/user.action";
import {FellowshipModel} from "../../model/fellowship.model";
import {FellowshipTransformer} from "../../transformer/fellowship-transformer";
import {MessageModel} from "../../model/message.model";
import {FollowModel} from "../../model/follow.model";
import {MessageTransformer} from "../../transformer/message-transformer";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly userNoPic = 'https://s3-eu-west-1.amazonaws.com/christianity.ng-system/no_image.gif'
  userSub: Subscription;
  userData: UserDataModel;
  user: UserModel;
  constructor(private httpClient: HttpClient,
              private store: Store<AppState>,
              private cookieService: CookieService) {
    this.userSub = this.store.select('user').subscribe(user => {
      if (user) {
        this.user = user;
        this.userData = user.user;
      }else{
        this.userData = undefined;
      }
    });
  }

  getUser(userId: Number): Observable<UserDataModel> {
    const url = this.isUserAuthenticated() ? `${environment.webApiNamespace}/user/${userId}` : `${environment.webApiNamespace}/user/lite/${userId}`;
    return this.httpClient.get(url)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  getUserByEmail(userEmail: string): Observable<UserDataModel> {
    return this.httpClient.get(`${environment.webApiNamespace}/user/email/${userEmail}`)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  storeUserAndWriteCookie(user: UserDataModel) {
    this.cookieService.delete(environment.userIdCookieName);
    this.store.dispatch(new SetUserAction({user: user}));
    this.cookieService.set(
      environment.userIdCookieName,
      user.id + '',
      30
    );
  }

  writeAuthTokenCookie(token: string) {
    this.cookieService.delete(environment.jwtTokenCookieName);
    this.cookieService.set(
      environment.jwtTokenCookieName,
      token,
      30
    );
  }

  deleteUserCookies() {
    this.cookieService.delete(environment.jwtTokenCookieName);
    this.cookieService.delete(environment.userIdCookieName);
    this.store.dispatch(new DeleteUserAction());
  }

  deactivateUser(userId: number): Observable<UserDataModel> {
    return this.httpClient.put(`${environment.webApiNamespace}/user/deactivate/${userId}`, null)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updateNotificationSettings(prayerNotification: number,
                             newsNotification: number,
                             editorialNotification: number,
                             userId: number): Observable<UserDataModel> {
    const bdy = {
      prayer: prayerNotification,
      news: newsNotification,
      editorial: editorialNotification,
      userId: userId
    }
    return this.httpClient.put(`${environment.webApiNamespace}/user/notification`, bdy)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updateUserProfile(userId: number,
                    data: {
                      name: string;
                      about: string;
                      locationId: number;
                      dob?: number;
                      singlesNetworkStatus?: number;
                      genderId?: number;
                      activities?: string;
                      interests?: string;
                      favScripture?: string;
                      faithStatement?: string;
                      lifeLesson?: string;
                      personalStatement?: string;
                      faithInfluence?: string;
                    }
  ): Observable<UserDataModel> {
    return this.httpClient.put(`${environment.webApiNamespace}/user/${userId}`, data)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updateUserProfilePicture(url: string, userId: number): Observable<UserDataModel> {
    return this.httpClient.put(`${environment.webApiNamespace}/user/${userId}/profilePic`, {profilePicUrl: url})
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  deleteUserPicture(picId: number, userId: number): Observable<UserDataModel> {
    return this.httpClient.get(`${environment.webApiNamespace}/picture/${picId}/${userId}/delete`)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updatePassword(pw: string, userId: number): Observable<UserDataModel>{
    return this.httpClient.put(`${environment.webApiNamespace}/user/${userId}/password/${pw}`, null)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  getDatingNetworkUsers(pageNumber: number): Observable<UserDataModel[]> {
    return this.httpClient.get(`${environment.webApiNamespace}/user`, {
      params: {'singlesOnly': 'true', 'pageNumber': `${pageNumber}`}
    })
      .pipe(
        map((json: any[]) => {
            return UserTransformer.convertToUsers(json);
          },
          catchError(error => throwError(error)))
      );
  }

  leaveSinglesNetwork(userId: number): Observable<UserDataModel> {
    return this.httpClient.put(`${environment.webApiNamespace}/user/singles/leave/${userId}`, null)
      .pipe(
        map(json => {
            return UserTransformer.convertToUser(json);
          },
          catchError(error => throwError(error)))
      );
  }

  updateProfilePhoto(formData: FormData): Observable<UserDataModel> {
    const url = `${environment.webApiNamespace}/user/profile-photo`;
    return this.httpClient.put<UserDataModel>(url, formData)
      .pipe(
        map(response => {
          return UserTransformer.convertToUser(response)
        })
      );
  }


  getUserMessageParticipants(userId: number): Observable<UserDataModel[]> {
    return this.httpClient.get(`${environment.webApiNamespace}/user/message/participants/${userId}`)
      .pipe(
        map((json:any) => {
            return UserTransformer.convertToUsers(json);
          },
          catchError(error => throwError(error)))
      );
  }
  getUserMessages(user1Id: number, user2Id): Observable<MessageModel[]> {
    return this.httpClient.get(`${environment.webApiNamespace}/message/${user1Id}/${user2Id}`)
      .pipe(
        map((json:any) => {
            return MessageTransformer.transformMessages(json);
          },
          catchError(error => throwError(error)))
      );
  }

  postUserMessage(data: {fromId: number; toId: number; message: string}): Observable<MessageModel> {
    const url = `${environment.webApiNamespace}/message`;
    return this.httpClient.post<MessageModel>(url, data)
      .pipe(
        map(response => {
          return MessageTransformer.transformToMessageModel(response)
        })
      );
    return null;
  }

  isUserAuthenticated(): boolean{
    return this.user.authentication.id != undefined && this.user.authentication.token != undefined;
  }

  /*isUserAuthenticated(){
    // return this.userData && this.userData.id && this..;
    try{
      return this.user.authentication.id && this.user.authentication.token.length > 0;
    }
    catch (e) {
      return false;
    }
  }*/

  deleteFollow(followId: number): Observable<any>{
    const url = `${environment.webApiNamespace}/follow/${followId}`
    return this.httpClient.delete(url);
  }

  follow(f: FollowModel): Observable<FollowModel> {
    const url = `${environment.webApiNamespace}/follow`
    return this.httpClient.post(url, f)
      .pipe(
        map(response => UserTransformer.convertToFollowModel(response))
      );
  }

  isUserInSinglesGroup(userData: UserDataModel) {
    return userData && userData.singlesNetworkStatus === 1;
  }

  getUsers(criteria: { name: string, locationId: number, singlesOnly: boolean; pageNumber: number; ageFrom: number; ageTo: number, genderId: number}): Observable<UserDataModel[]> {
    const nameParam = criteria.name && criteria.name.length > 0 ? `&name=${criteria.name}` : '';
    const locationParam = criteria.locationId && criteria.locationId > 0 ? `&locationId=${criteria.locationId}` : '';
    const singlesOnlyParam = criteria.singlesOnly === true ? '&singlesOnly=true' : '';
    const ageFromParam = criteria.ageFrom ? `&ageFrom=${criteria.ageFrom}` : '';
    const ageToParam = criteria.ageTo ? `&ageTo=${criteria.ageTo}` : '';
    const genderIdParam = criteria.genderId ? `&genderId=${criteria.genderId}` : '';
    const pageNumberParam = `&pageNumber=${criteria.pageNumber}`;
    const uri = encodeURI(`${environment.webApiNamespace}/user?${nameParam}${locationParam}${singlesOnlyParam}${pageNumberParam}${ageFromParam}${ageToParam}${genderIdParam}`);
    return this.httpClient.get(uri)
      .pipe(
        map((json: any[]) => {
            return UserTransformer.convertToUsers(json);
          },
          catchError(error => throwError(error)))
      );
  }

  getSignedInUserId(){
    if (this.isUserAuthenticated()) {
      return this.userData.id;
    }
    return null;
  }


  getAuthenticatedUserId(){
    if (this.isUserAuthenticated()) {
      return this.user.authentication.id;
    }
    return undefined;
  }

  isUserBeingViewedSameAsLoggedInUser(userBeingViewedId: number): boolean {
    // return this.getAuthenticatedUserId() === userBeingViewedId;
    return this.user.userInView?.id === this.user.authentication.id;
  }

  isUserAdminRole(): boolean {
    try{
      return this.user.authentication.roles.includes('ROLE_ADMIN');
    }catch (e) {
      return false;
    }
  }
}
