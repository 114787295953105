import { Component, OnInit } from '@angular/core';
import {CompanyModel} from "../../company/model/company.model";
import {CompanyService} from "../../company/service/company.service";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-directory-item',
  templateUrl: './directory-item.component.html',
  styleUrls: ['./directory-item.component.scss']
})
export class DirectoryItemComponent implements OnInit {
  company: CompanyModel;
  directoryId: number;
  faEnvelope: any;

  constructor(private companyService: CompanyService,
              private activatedRoute: ActivatedRoute,
              private store: Store<AppState>,
              public sanitizer: DomSanitizer) {
    this.faEnvelope = faEnvelope;
  }


  ngOnInit() {
    this.directoryId = this.activatedRoute.snapshot.params.id;
    this.companyService.getCompany(this.directoryId)
      .subscribe((company: CompanyModel) => {
        this.company = company;
      })

   /* this.fellowshipService.getFellowshipListForOrganisation(this.directoryId)
      .subscribe((fellowships: FellowshipModel[]) => {
        this.fellowshipList = fellowships;
      })*/
  }

  notNull(value: string) {
    return value;
  }

  /*setPage(page: string) {
    this.selectedPage = page;
  }*/

  /*showPage(page: string = 'home'){
    return this.selectedPage === page;
  }*/

}
