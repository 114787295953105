import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../environments/environment";
import {Store} from "@ngrx/store";
import {AppState} from "../app.state";
import {SetAuthenticationToken} from "../component/user/user.action";

@Injectable({
  providedIn: 'root'
})
export class JwtTokenInterceptorService implements HttpInterceptor {

  constructor(private store: Store<AppState>,
              private cookieService: CookieService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //inject the token in the header here for every request
    let token: string;
    this.store.pipe(take(1)).subscribe(state =>  {
      token = state.user.authentication.token;
    });

    if(token && token.trim().length > 0){
      const headerValue = `Bearer ${token.trim()}`;
      req = req.clone({
        headers: req.headers.set('Authorization', headerValue)
      });
    }

    return next.handle(req);
    /*  .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const jwtToken = event.headers.get('access_token');
            if (jwtToken) {
              this.store.dispatch(new SetAuthenticationToken({token: jwtToken}));
              this.cookieService.set(
                environment.jwtTokenCookieName,
                jwtToken,
                30
              )
            }
          }
          return event;
        })
      )*/
  }


}
