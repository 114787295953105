import { Component, OnInit } from '@angular/core';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss']
})
export class UserSettingComponent implements OnInit {
  faArrowLeft: any;

  constructor() {
    this.faArrowLeft = faArrowLeft;
  }

  ngOnInit() {
  }

}
