import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about/about.component';
import { AdvertiseComponent } from './advertise/advertise.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatCardModule} from "@angular/material/card";


@NgModule({
  declarations: [AboutComponent, AdvertiseComponent],
    imports: [
        CommonModule,
        AboutRoutingModule,
        FontAwesomeModule,
        MatGridListModule,
        MatCardModule
    ]
})
export class AboutModule { }
