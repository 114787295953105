<div class="row px-xs-5 mat-typography">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row my-3 d-flex justify-content-center">
      <div class="col-12-sm">
        <div class="jumbotron">
          <h1 class="display-4">About British Christian</h1>
          <p class="lead">
            Welcome to British Christian. Connecting Christians across the United Kingdom and
            beyond.
          </p>
          <hr class="my-4">
          <p class="lead">
            <a class="btn btn-primary btn-lg" routerLink="/signup" role="button">Sign up free!</a>
          </p>
        </div>
      </div>
    </div>
    <div class="row my-3 px-3 justify-content-center">
      <div class="col-md-12">
        <h1 class="text-secondary">
          Our Purpose
        </h1>
        <p>
          The British Christian Network is intended to
          be a place of sharing, learning, fellowship, ideas, testimonies, prayer, discussion and
          so on - a small extra companion to your bigger life journey.
        </p>
        <p>
          This is a spiritual place in the global expense of the internet where people come to get
          away from the hustle and bustle of daily life to engage with their spiritual life by connecting with the prayers,
          testimonies and articles that are submitted by the community of believers.
        </p>
        <p>
          We have developed specialised data management technologies to service Christian and affiliated organisations. Part
          of our purpose is to use this technology to promote the wonderful contributions of Christians in their communities.
        </p>
      </div>
    </div>

    <!--services-->
    <div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div><span class="display-4">Increase your influence, make an impact</span></div>
          <div><span class="text-black-50">Psalm 133:1 How good and pleasant it is when brothers live together in unity!</span></div>
          <div class="mt-4">
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'cyan', 'color': 'cyan'}" ></fa-icon>
              <span class="ml-3">Post good news, prayer requests and testimonies</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'brown', 'color': 'brown'}" ></fa-icon>
              <span class="ml-3">Upload pictures</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'blue', 'color': 'blue'}" ></fa-icon>
              <span class="ml-3">Gain followers</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'indigo', 'color': 'indigo'}" ></fa-icon>
              <span class="ml-3">Free private messaging</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'grey', 'color': 'grey'}" ></fa-icon>
              <span class="ml-3">Receive regular newsletters with uplifting Bible based messages</span>
            </div>

          </div>
        </div>

        <div class="col-sm-12 col-md-6">
          <div><span class="display-4">Free singles networking. Unlimited searching and messaging</span></div>
          <div><span class="text-black-50">Psalm 133:1 How good and pleasant it is when brothers live together in unity!</span></div>
          <div class="mt-4">
            <div>
              <fa-icon class="text-success" [icon]="faCheck" [styles]="{'stroke': 'purple', 'color': 'purple'}" size="2x"></fa-icon>
              <span class="ml-3">Search for Christians looking for like-minded, long lasting relationship</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'pink', 'color': 'pink'}"></fa-icon>
              <span class="ml-3">Free unlimited private and secure messaging</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'yellow', 'color': 'yellow'}"></fa-icon>
              <span class="ml-3">Upload pictures and describe/search for Christian attributes</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'orange', 'color': 'orange'}"></fa-icon>
              <span class="ml-3">Dedicated singles forum for light hearted chats</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'green', 'color': 'green'}"></fa-icon>
              <span class="ml-3">Follow and gain followers</span>
            </div>

          </div>
        </div>
      </div>
      <div class="row  mt-md-5">
        <div class="col-sm-12 col-md-6">
          <div><span class="display-4">Data management; Promotional services for organisations</span></div>
          <div><span class="text-black-50">Psalm 133:1 How good and pleasant it is when brothers live together in unity!</span></div>
          <div class="mt-4">
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'silver', 'color': 'silver'}" ></fa-icon>
              <span class="ml-3">We have automated data flows for distribution of news and announcements</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'fuchsia', 'color': 'fuchsia'}" ></fa-icon>
              <span class="ml-3">Event listing and promotion on client customised pages</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'teal', 'color': 'teal'}" ></fa-icon>
              <span class="ml-3">Scheduled database driven E-newsletter generation and distribution for clients</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'maroon', 'color': 'maroon'}" ></fa-icon>
              <span class="ml-3">Internation presence for maximum reach</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'navy', 'color': 'navy'}" ></fa-icon>
              <span class="ml-3">Video documentaries production on demand</span>
            </div>

          </div>
        </div>

        <div class="col-sm-12 col-md-6">
          <div><span class="display-4">Regular uplifting inspired articles</span></div>
          <div><span class="text-black-50">Psalm 133:1 How good and pleasant it is when brothers live together in unity!</span></div>
          <div class="mt-4">
            <div>
              <fa-icon class="text-success" [icon]="faCheck" [styles]="{'stroke': 'lightblue', 'color': 'lightblue'}" size="2x"></fa-icon>
              <span class="ml-3">We are not only technologists, we are teachers of Gods Holy word</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'lime', 'color': 'lime'}"></fa-icon>
              <span class="ml-3">Thought provoking Bible based discussions and videos</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'coral', 'color': 'coral'}"></fa-icon>
              <span class="ml-3">Catch up on Christian news and stories from around the world</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'darkkhaki', 'color': 'darkkhaki'}"></fa-icon>
              <span class="ml-3">Varied subject matter from relationship to entrepreneurship based on sound Christian theology</span>
            </div>
            <div>
              <fa-icon class="text-success" [icon]="faCheck" size="2x" [styles]="{'stroke': 'aquamarine', 'color': 'aquamarine'}"></fa-icon>
              <span class="ml-3">Freely we have received, freely we give. To God be the glory</span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row my-5 px-sm-3">
      <div class="col-sm-12">
        <div class="media">
          <div class="media-body">
            <h1 class="text-secondary">Doctrinal Beliefs</h1>
            <ul>
              <li><b>The Bible</b>. The divine inspiration of the bible.</li>
              <li><b>Only one God</b>. There is only one God, the creator of heaven and earth.</li>
              <li><b>Jesus Christ the son of God</b>. Jesus Christ is the only son of God sent to take
                away the sins of the
                world.
              </li>
              <li><b>THE HOLY SPIRIT</b>, The Holy Spirit is the third Person in the TRINITY. He has
                the same power, the same glory with God the Father and God the Son.
              </li>
              <li><b>The Trinity</b>. We believe that God the Father, God the Son, and God the Holy Ghost
                are one God, but
                made of three persons.
              </li>
              <li><b>About the devil</b>. We believe that there is a devil, who seeks the downfall of
                every man.
              </li>
              <li><b>Justification by faith and not by works</b>. This is God's grace whereby we are
                cleansed from our sins
                by which we are able to stand before God as though we have never sinned. This is
                achieved through faith in Jesus Christ
                the son of God.
              </li>
              <li><b>The Second coming</b>. The second coming of Jesus Christ will be in physical form and
                will be visible to
                all in like manner as He was seen ascending up to heavens
              </li>
              <li><b>Holiness</b>. Once saved from sin a Christian cannot go on living the former life but
                must live a
                righteous life through the transforming power of God working in them.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-5 px-sm-3">
      <div class="col-sm-12">
        <div class="media">
          <div class="media-body">
            <h1 class="text-secondary">People</h1>
            <ul>
              <li>
                <p class="mat-body-2">Daniel Nwankwo</p>
                <p>
                  I am founder of this Christian ministry. It is a platform that God directs through his Holy Spirit.
                  My goal is to reduce the distance between Christians through the provision of a dedicated digital
                  platform.
                </p>
                <p>My passion is evangelism, I believe that this good news as Jesus preached it is the hope for
                  humanity,
                  and this message is love. Love for God and love for man in all his stages of conditions. Like
                  everything else,
                  we can learn divine love by accept the Spirit of God as our coach. Through repentance and faith in
                  Jesus,
                  this new life can be achieved.
                </p>
                <p>
                  I have served in various capacities in the church.</p>
                <ul>
                  <li>
                    <p>
                      As a parish pastor of the Redeemed Christian Church of God (RCCG) Open Heavens Christian Centre
                      Aberdeen
                    </p>
                  </li>
                  <li>
                    <p>As an undergraduate student Christian fellowship
                      executive in CASOR (Christ Ambassadors Students Outreach - an arm of the Assemblies of God Church
                      Nigeria)
                    </p>
                  </li>

                </ul>
                <p>
                  Being a software consultant, I have designed and built all the IT systems for the ministry both the
                  online
                  platform and communication systems.
                </p>
                <p>I write articles, record and post-produce videos</p>
              </li>
              <li>
                <p class="mat-body-2">Elizabeth Nwankwo</p>
                <p>
                  I am involved in this ministry with my husband. I lead the prayer department. I offer prayerful
                  support
                  to those with various needs. No matter how dark your environment looks, never stop praying. Prayer and
                  faith
                  produces results. I produce videos and articles on prayer.
                </p>
                <p>
                  I oversee the children's, social and administrative departments. I also see that the follow up
                  strategy of
                  the ministry works well so that new converts are well communicated with.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--contacts-->
    <div class="row my-3 px-sm-3">
      <div class="col-md-6">
        <h1 class="text-secondary">
          Contact
        </h1>
        <p>
          Email: admin@britishchristian.com
        </p>
        <p>
          Telephone: +44 7939313045
        </p>
      </div>
    </div>

  </div>
  <div class="col-sm-1"></div>
</div>





