import { Component, OnInit } from '@angular/core';
import {HomeService} from "../../service/home/home.service";
import {ArticleModel} from "../../model/article.model";
import {ArticleService} from "../../service/article/article.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  articles: ArticleModel[] = [];
  relationshipArticles: ArticleModel[] = [];
  prayerArticles: ArticleModel[] = [];
  musicArticles: ArticleModel[] = [];
  constructor(private articleService: ArticleService) { }

  ngOnInit() {
    this.getLatestArticles();
    this.getRelationshipArticles();
    this.getPrayerArticles();
    this.getMusicArticles();

  }

  getLatestArticles(){
    this.articleService.getArticles(0)
      .subscribe((articles: ArticleModel[]) => {
        this.articles = articles;
      });
  }

  getRelationshipArticles(){
    this.articleService.getArticles(0, 27)
      .subscribe((articles: ArticleModel[]) => {
        this.relationshipArticles = articles;
      });
  }

  getPrayerArticles(){
    this.articleService.getArticles(0, 28)
      .subscribe((articles: ArticleModel[]) => {
        this.prayerArticles = articles;
      });
  }

  getMusicArticles(){
    this.articleService.getArticles(0, 30)
      .subscribe((articles: ArticleModel[]) => {
        this.musicArticles = articles;
      });
  }

}
