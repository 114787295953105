import {DirectoryModel} from "./model/directory.model";
import {DirectoryAction, SetUserOrganisationsType} from "./directory.action";
import {tassign} from "tassign";
import {CompanyModel} from "../company/model/company.model";

let initialState: DirectoryModel = {
  userOrganisations: []
}
export function reducer(state: DirectoryModel = initialState, action: DirectoryAction): DirectoryModel{
  switch (action.type) {
    case SetUserOrganisationsType:
      return tassign(state, {userOrganisations: action.payload.companies})
    default:
      return state;
  }
}
