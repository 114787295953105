<div>
  <div class="row p-5 mt-3 user-header">
    <div class="col-sm-12 col-md-4">
      <img class="card-img-top rounded-circle shadow-lg"
           alt="header image"
           [src]="company?.logo"
           data-holder-rendered="true"
           style="height: 225px;
                 width: 100%;
                 display: block;"/>
    </div>
    <div class="col-sm-12 col-md-8">
      <h2 class="bol">{{company?.name}}</h2>
      <div *ngIf="company?.website"><a target="_blank" href="{{company?.website}}">{{company?.websiteLabel}}</a></div>
      <div *ngIf="company?.telephone">Tel: <span>{{company?.telephone}}</span></div>
      <div *ngIf="notNull(company?.email)" class="mt-3">
        <a [routerLink]="'/corporate/email/enquiry/'+company.id+'/'+company.systemName" class="btn-secondary btn btn-lg">
          <fa-icon [icon]="faEnvelope"></fa-icon>
          <span class="ml-2 text-white">Email</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row mb-5">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <!--overview-->
    <div *ngIf="company?.description && company?.description.length > 0" class="mt-5">
      <b class="">Overview</b>
      <p [innerHTML]="company.description | nl2br: true"></p>
    </div>
  </div>
</div>

<!--vlogs-->
<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="d-flex flex-row justify-content-center">
      <div class="d-flex flex-column justify-content-center h-100">
        <iframe *ngFor="let vodcast of company.vlog" width="560" height="315" class="my-3"
                [src]="sanitizer.bypassSecurityTrustResourceUrl(vodcast.embedUrl)"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>

        </iframe>
      </div>

    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
