<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <!--Header-->
    <div>
      <h1 *ngIf="isAddMode(); else editMode" class="display-4">Add Product</h1>
      <ng-template #editMode>
        <h1 class="display-4">Edit Product</h1>
      </ng-template>
    </div>

    <!--body-->
    <form [formGroup]="form">
      <div id="company" class="form-group">
        <div class="label">Select a company</div>
        <select class="form-control" formControlName="company">
          <option value="-1">Please Select</option>
          <option *ngFor="let company of ( companies$ | async )" value="{{company.id}}">
            {{company.name}}
          </option>
        </select>
        <div *ngIf="companyCtl.touched && companyCtl.invalid" class="alert-danger"><span>Select a location</span></div>
      </div>
      <div id="category" class="form-group">
        <div class="label">Select a product category</div>
        <select class="form-control" formControlName="category">
          <option value="-1">Please Select</option>
          <option *ngFor="let category of ( productCategories$ | async )" value="{{category.id}}">
            {{category.name}}
          </option>
        </select>
        <div *ngIf="categoryCtl.touched && categoryCtl.invalid" class="alert-danger"><span>Select a location</span>
        </div>
      </div>
      <div class="form-group">
        <div class="label">Name</div>
        <div>
          <input class="form-control" type="text" formControlName="name"/>
        </div>
        <div *ngIf="nameCtl.touched && nameCtl.invalid" class="alert-danger"><span>Enter company name</span></div>
      </div>
      <div class="form-group">
        <div class="label">Description</div>
        <div>
          <textarea class="form-control" type="text"
                    formControlName="description"
                    rows="5"></textarea>
        </div>
        <div *ngIf="descriptionCtl.touched && descriptionCtl.invalid" class="alert-danger"><span>Enter company description</span>
        </div>
      </div>

      <div class="form-group">
        <div *ngIf="isAddMode(); else editSubmit">
          <button class="btn btn-primary" (click)="submit()">Submit</button>
        </div>
        <ng-template #editSubmit>
          <button class="btn btn-primary" (click)="edit()">Edit</button>
        </ng-template>
      </div>
    </form>

    <!--message panel-->
    <div class="row" *ngIf="!isUserLoggedIn">
      <div class="col-sm-12">
        <h1>User is not logged in. You must log in before adding company details</h1>
      </div>
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>

