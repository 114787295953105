import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {Subscription} from "rxjs";
import {UserDataModel} from "../../../model/userModel";
import {FellowshipModel} from "../../../model/fellowship.model";
import {UserService} from "../../../service/user/user.service";
import {SetUserAction} from "../user.action";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-user-header-private',
  templateUrl: './user-header-private.component.html',
  styleUrls: ['./user-header-private.component.scss']
})
export class UserHeaderPrivateComponent implements OnInit {

  userSub: Subscription;
  userData: UserDataModel;
  errorMsg: string = '';
  isSaving: boolean = false;
  @ViewChild('input')photoInput: ElementRef<HTMLInputElement>;
  constructor(private store: Store<AppState>,
              private userService: UserService,
              public activatedRoute: ActivatedRoute) {
    this.userSub = this.store.select('user').subscribe(user => {
      if (user.user) {
        this.userData = user.user;
      }
    });
  }

  ngOnInit(): void {
  }

  onChangeProfilePhoto(event) {
    // this.photo = event.target.files[0];
// event.target.files = [];
  }

  onUploadClick(){
    const photo = this.photoInput.nativeElement.files[0];

    if (!this.userData.id) {
      return false;
    }

    if (!photo) {
      this.errorMsg = 'Select a picture to upload';
      return false;
    }
    this.isSaving = true;

    let form = new FormData();
    if (photo) {
      form.append('profilePic', photo, photo.name);
    }
    form.append('userId', this.userData.id+'');

    this.userService.updateProfilePhoto(form)
      .subscribe((user: UserDataModel) => {
        this.store.dispatch(new SetUserAction({user}));
        this.photoInput.nativeElement.value = '';
        this.isSaving = false;
      }, error => {
          this.photoInput.nativeElement.value = '';
        this.isSaving = false;
      });
  }
}
