import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ForumStreamComponent} from "./forum-stream/forum-stream.component";
import {ForumItemComponent} from "./forum-item/forum-item.component";
import {CommunityHomeComponent} from "./community-home/community-home.component";
import {DatingHomeComponent} from "./dating-home/dating-home.component";
import {PeopleGalleryComponent} from "../people/people-gallery/people-gallery.component";

const routes: Routes = [
  {
    path: 'community',
    component: CommunityHomeComponent,
    children:[
      {
        path: '', // child route path
        redirectTo: 'fellowship/0',
        pathMatch: 'full',
      },
      {
        // path: 'forum/:forum', // child route path
        path: 'fellowship/:forumType/:page', // child route path
        pathMatch: 'full',
        component: ForumStreamComponent, // child route component that the router renders
      },{
        // path: 'forum/:forum', // child route path
        path: 'people', // child route path
        pathMatch: 'full',
        component: PeopleGalleryComponent, // child route component that the router renders
      }
    ]
  },
  {
    path: 'forum/:forumId',
    component: ForumItemComponent,
    pathMatch: 'full'
  },
  {
    path: 'singles',
    component: DatingHomeComponent,
    children: [
      {
        path: '', // child route path
        redirectTo: 'people',
        pathMatch: 'full',
      },
      {
        // path: 'forum/:forum', // child route path
        path: 'fellowship/:forumType/:page', // child route path
        pathMatch: 'full',
        component: ForumStreamComponent, // child route component that the router renders
      },
      {
        path: 'people', // child route path
        pathMatch: 'full',
        component: PeopleGalleryComponent, // child route component that the router renders
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ForumRoutingModule {
}
