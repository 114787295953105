<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div *ngIf="article">
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <!--header section-->
          <div>
            <div class="py-5"><span class="display-4">{{article.subject}}</span></div>
            <div class="mt-3">
              <hr/>
              <span>Author: {{article.authorName}} in </span><span class="">{{article.articleTypeName}}</span>
              <hr/>
            </div>
            <!--headerphoto or video-->
            <div *ngIf="isNotEmpty(article.headerVideoEmbedSrc)" class="d-flex justify-content-center">
              <iframe width="560" height="315"
                      [src]="sanitiseVideoUrl(article.headerVideoEmbedSrc)"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>

              </iframe>
            </div>
            <div *ngIf="isNotEmpty(article.editorialPic)" class="mt-3">
              <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
                   alt="article pic"
                   [src]="article.editorialPic"
              >
            </div>
          </div>

          <!--summary section-->
          <div *ngIf="isNotEmpty(article.summary)" class="shadow p-5 mt-3">
            <span [innerHTML]="textFormatService.autoLinkText(article.summary) | nl2br: true"></span>
          </div>

          <!--body section one-->
          <div *ngIf="isNotEmpty(article.section1ImageUrl)" class="mt-3">
            <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
                 alt="article pic"
                 [src]="article.section1ImageUrl"
            >
          </div>
          <div *ngIf="isNotEmpty(article.section1VideoEmbedSrc)" class="d-flex justify-content-center mt-3">
            <iframe width="560" height="315"
                    [src]="sanitiseVideoUrl(article.section1VideoEmbedSrc)"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>

            </iframe>
          </div>
          <div class="mt-5">
            <p [innerHTML]="textFormatService.autoLinkText(article.comment) | nl2br: true"></p>
          </div>


          <!--body section two-->
          <div>
            <div *ngIf="isNotEmpty(article.section2ImageUrl)" class="mt-3">
              <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
                   alt="article pic"
                   [src]="article.section2ImageUrl"
              >
            </div>
            <div *ngIf="isNotEmpty(article.section2VideoEmbedSrc)" class="d-flex justify-content-center mt-3">
              <iframe width="560" height="315"
                      [src]="sanitiseVideoUrl(article.section2VideoEmbedSrc)"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>

              </iframe>
            </div>
            <div class="mt-5">
              <p [innerHTML]="textFormatService.autoLinkText(article.section2Body) | nl2br: true"></p>
            </div>
          </div>

          <!--section 3-->
          <div>
            <div *ngIf="isNotEmpty(article.section3ImageUrl)" class="mt-3">
              <img class="card-img-top img-fluid custom-img-fluid mx-auto d-block"
                   alt="article pic"
                   [src]="article.section3ImageUrl"
              >
            </div>
            <div *ngIf="isNotEmpty(article.section3VideoEmbedSrc)" class="d-flex justify-content-center mt-3">
              <iframe width="560" height="315"
                      [src]="sanitiseVideoUrl(article.section3VideoEmbedSrc)"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>

              </iframe>
            </div>
            <div class="mt-5">
              <p [innerHTML]="textFormatService.autoLinkText(article.section3Body) | nl2br: true"></p>
            </div>
          </div>

          <!--Reply box-->
          <div *ngIf="userData else instructions"  class="form-group">
            <label for="reply"><b>Add a reply</b></label>
            <textarea id="reply" class="form-control" rows="10"
            [(ngModel)]="articleReply"></textarea>
            <div *ngIf="showInvalidEntry"><span class="text-danger">This field is required</span></div>
            <button class="btn btn-block btn-secondary mt-3" (click)="submitReply()">Post reply</button>
            <app-spinner *ngIf="isSpinnerShowing" class="mt-3 d-flex justify-content-center" ></app-spinner>
          </div>
          <ng-template #instructions>
            <b><a routerLink="/login">Log in</a> or <a routerLink="/signup">sign up</a> to add a reply</b>
          </ng-template>

          <!--replies-->
          <div class="mt-5">
            <div *ngFor="let reply of article.articleReplies" class="media my-3">
              <img class="mr-3 rounded-circle"
                   [src]="reply.userPic"
                   alt="image" width="75" height="75">

              <div class="media-body">
                <a [routerLink]="'/user/'+reply.userId">
                  <h5 class="mt-0">{{reply.userName}}</h5>
                </a>

                <span>{{reply.commentDate  | amFromUnix | amDateFormat:'LL'}}</span>
                <p class="mt-2" [innerHTML]="textFormatService.autoLinkText(reply.comment) | nl2br"></p>
              </div>
            </div>
          </div>
        </div>

        <!--right side section in md screen or lowest in sm screen-->
        <div class="col-sm-0 col-md-4"></div>
      </div>


      <div>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>

