import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../service/user/user.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {CompanyService} from "../../company/service/company.service";
import {ProductService} from "../../../service/product/product.service";
import {ArticleService} from "../../../service/article/article.service";
import {Router} from "@angular/router";
import {NewsService} from "../../news/service/news.service";
import {Observable, Subscription} from "rxjs";
import {AuthenticationModel, UserDataModel, UserModel} from "../../../model/userModel";
import {CompanyModel} from "../../company/model/company.model";
import {SetUserOrganisations} from "../directory.action";
import {DirectoryModel} from "../model/directory.model";

@Component({
  selector: 'app-user-directory-mgmt',
  templateUrl: './user-directory-mgmt.component.html',
  styleUrls: ['./user-directory-mgmt.component.scss']
})
export class UserDirectoryMgmtComponent implements OnInit {
  authentication: Observable<AuthenticationModel>;
  authenticationSub: Subscription;
  authenticationData;

  userObservable: Observable<UserModel>;
  userSub: Subscription;
  userData: UserDataModel;

  companies: CompanyModel[];
  directorySub: Subscription;

  constructor(private userService: UserService,
              private store: Store<AppState>,
              private companyService: CompanyService,
              private productService: ProductService,
              private articleService: ArticleService,
              private router: Router) {
    this.authenticationSub = this.store.select('user').subscribe(user => {
      this.authenticationData = user.authentication;
    });

    this.userSub = this.store.select('user').subscribe(user => {
      if (user.user) {
        this.userData = user.user;
      }
    });

    this.directorySub = this.store.select('directory')
      .subscribe((directory: DirectoryModel) => {
        this.companies = directory.userOrganisations;
      })


  }

  ngOnInit() {

  }

  isUserAdminRole() {
    return this.userData && this.userData.authority.includes('ADMIN');
  }
}
