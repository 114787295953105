<div>
  <!--search panel-->
  <div class="mb-3">
    <form [formGroup]="form" class="d-flex flex-column">
      <mat-form-field appearance="fill">
        <mat-label>Enter user name (optional)</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Select a users location</mat-label>
        <mat-select formControlName="location">
          <mat-option *ngFor="let location of locations" [value]="location">{{location.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="searchMode == singleSearchMode" class="d-flex flex-column">
        <mat-form-field appearance="fill">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option *ngFor="let gender of genders" [value]="gender">{{gender.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <mat-form-field class="mr-3">
            <mat-label>Age From</mat-label>
            <input matInput type="number" formControlName="ageFrom">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Age To</mat-label>
            <input matInput type="number" formControlName="ageTo">
          </mat-form-field>
        </div>

      </div>


      <div class="d-flex flex-row justify-content-center">
        <fa-icon *ngIf="isSearching" [icon]="faSpinner" spin="true"></fa-icon>
        <button *ngIf="!isSearching" mat-flat-button color="accent" (click)="onSearch()" class="mr-2">Search</button>
        <button mat-flat-button  (click)="onCancel()">Clear</button>
      </div>
    </form>
  </div>

  <!--results-->
  <div *ngIf="searchMode == nonSingleSearchMode; else singlesSearchDisplay">
    <mat-card *ngFor="let user of users" class="mb-3 mat-elevation-z0" (click)="routeToUserView(user.id)">
      <mat-card-header>
        <img mat-card-avatar [src]="user?.userHeaderPic"/>
        <mat-card-title>{{user.name}}</mat-card-title>
        <mat-card-subtitle>{{user.location.name}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

      </mat-card-content>
    </mat-card>
  </div>
  <ng-template #singlesSearchDisplay>
    <div class="row">
      <div *ngFor="let datingUser of users" class="col-sm-12 col-md-4">
        <div class="card mb-4 box-shadow border-0">
          <a [routerLink] ="'/user/'+datingUser?.id">
            <img class="card-img-top custom-img-fluid shadow"
                 alt="article pic"
                 [src]="datingUser?.userHeaderPic"
                 width="200px"
                 height="200px"
                 style="min-width: 150px; min-height: 150px"
            >
          </a>
          <div class="card-body pl-0 pr-0 pt-2 pb-0">
            <a [routerLink]="'/user/'+datingUser.id">
              <h5 class="card-title">{{datingUser?.name}}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="d-flex flex-row justify-content-center">
    <fa-icon *ngIf="isSearching" [icon]="faSpinner" spin="true"></fa-icon>
    <button *ngIf="!isSearching && users.length > 0" [disabled]="this.isEndOfSearchResult" mat-flat-button color="accent" (click)="onNext()"> Next</button>
  </div>
</div>
