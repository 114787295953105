import {Component, OnInit} from '@angular/core';
import {RegistrationService} from "../registration.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {
  verificationCode;
  userid: number;

  constructor(private registrationService: RegistrationService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.userid = +paramMap.get('id');
    })
  }

  ngOnInit() {
    this.registrationService.doRegCompleteReminder(this.userid)
      .subscribe(user => {});
  }

  submit() {
    this.registrationService.postEmailVerificationCode(this.userid, this.verificationCode)
      .subscribe(data => {
          this.router.navigate(['/login'])
        },
        error => {
        });
  }

}
