<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div>
      <form [formGroup]="formGroup">
        <div class="d-flex justify-content-center"><h1>Create an article</h1></div>

        <!--article type-->
        <div>
          <div>Article type</div>
          <div>
            <select class="custom-select custom-select-lg mb-3"
                    formControlName="articleTypeId">
              <option selected value="0"
              >Select article type</option>
              <option *ngFor="let type of articleTypes "
                      [ngValue]="type.id"
              >{{type.name}}</option>
            </select>
          </div>
        </div>

        <!--user-->
        <div>
          <div><h6>Select a user</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="form-group d-flex flex-column">
                  <label for="title">User email</label>
                  <input id="user-email"
                         width="200"
                         type="text"
                         class="form-control-lg"
                         size="40"
                         formControlName="emailForSearch">
                </div>
                <div class="ml-5">
                  <button class="btn btn-block btn-primary user-search-btn"
                          (click)="findUsers()">Search for user</button>
                </div>
              </div>

              <div class="form-group">
                <label>Select a user</label>
                <select class="custom-select custom-select-lg mb-3"
                        formControlName="userId">
                  <option selected value="0"
                  >Select a user</option>
                  <option *ngFor="let user of users"
                          [ngValue]="user.id"
                  >{{user.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--Header Section-->
        <div>
          <div><h6>Header</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="form-group d-flex flex-column">
                <label for="title">Title</label>
                <input id="title"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="title">
              </div>
              <div class="form-group">
                <label>Summary</label>
                <textarea
                  rows="7"
                  class="form-control"
                  formControlName="summary"></textarea>
              </div>
              <div class="form-group d-flex flex-column">
                <label for="title">Header video embed url</label>
                <input id="video-embed-header"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="headerVideoEmbedUrl">
              </div>

              <!--editorial pic-->
              <div class="form-group d-flex flex-column">
                <label for="title">Editorial picture</label>
                <input id="editorial-pic"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="editorialPic">
              </div>

              <!--display pic-->
              <div class="form-group d-flex flex-column">
                <label for="title">Display picture</label>
                <input id="display-pic"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="displayPic">
              </div>
            </div>
          </div>
        </div>

        <!--Section One-->
        <div class="mt-5">
          <div><h6>Section one</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label>Section one text</label>
                <textarea
                  rows="7"
                  class="form-control"
                  formControlName="sectionOneText"></textarea>
              </div>
              <div class="form-group d-flex flex-column">
                <label for="para1-video-embed-header">Section one video embed url src</label>
                <input id="para1-video-embed-header"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="sectionOneVideoEmbedSrc">
              </div>
              <!--section 2 pic-->
              <div class="form-group d-flex flex-column">
                <label for="title">Section 1 picture</label>
                <input id="section1-pic"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="section1ImageUrl">
              </div>
            </div>
          </div>
        </div>

        <!--Section Two-->
        <div class="mt-5">
          <div><h6>Section two</h6></div>
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label>Section two text</label>
                <textarea
                  rows="7"
                  class="form-control"
                  formControlName="sectionTwoText"></textarea>
              </div>
              <div class="form-group d-flex flex-column">
                <label for="para1-video-embed-header">Section 2 video embed src url</label>
                <input id="para2-video-embed-header"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="sectionTwoVideoEmbedSrc">
              </div>

              <!--section 2 pic-->
              <div class="form-group d-flex flex-column">
                <label for="title">Section 2 picture</label>
                <input id="section2-pic"
                       type="text"
                       class="form-control-lg"
                       width="100%"
                       formControlName="section2ImageUrl">
              </div>
            </div>
          </div>
        </div>

        <!--Submit Section-->
        <div class="form-group d-flex justify-content-center mt-5">
          <button *ngIf="mode == 'edit'; else templateRef"  class="btn btn-primary btn-lg"
                  (click)="edit()">Edit
          </button>
          <ng-template #templateRef>
            <button  class="btn btn-primary btn-lg"
                     (click)="submit()">Submit
            </button>
          </ng-template>

        </div>
      </form>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>

