<div [ngSwitch]="activity.activityTypeId">
  <div *ngSwitchCase="1"> <!--wall posts-->
    <mat-card class="mb-3 mat-elevation-z0">
      <mat-card-header class="header">
        <img mat-card-avatar [src]="activity?.userPicUrl"/>
        <mat-card-title>
          <a [routerLink]= "'/user/'+activity.userId">{{activity.userName}}</a>
          <fa-icon *ngIf="activity.forumTypeId === 1" [icon]="faBible" [size]="'xs'"></fa-icon>
          <fa-icon *ngIf="activity.forumTypeId === 2" [icon]="faPray" [size]="'xs'"></fa-icon>
          <fa-icon *ngIf="activity.forumTypeId === 3" [icon]="faBullHorn" [size]="'xs'"></fa-icon>
        </mat-card-title>
        <mat-card-subtitle>{{activity.datePosted  | amFromUnix | amDateFormat:'LL'}}</mat-card-subtitle>
      </mat-card-header>
      <div class="body">
        <mat-card-content>
            <a [routerLink]="'/forum/'+activity.originalForumId">
              <div class="col-sm-12 d-flex justify-content-center mt-2">
                <img *ngIf="activity.forumPicUrl?.length>1" mat-card-image [src]="activity.forumPicUrl"
                     alt="comment picture" class="custom-img-fluid"/>
              </div>
              <p [innerHTML]="textFormatService.autoLinkText(activity.forumMessage) | nl2br"></p>
            </a>
<!--          </div>-->

          <div class="item-interaction-container">
            <div *ngIf="isViewerOwner(activity.userId)" class="d-flex flex-row justify-content-start mr-3">
              <div  class="item-interaction-btn mr-3" matTooltip="Delete" (click)="onDeleteClick(activity.originRowId, activity.forumTypeId)">
                <fa-icon [icon]="faTrash"></fa-icon>
                <span class="ml-1 item-interaction-btn">Delete</span>
              </div>
            </div>
            <div class="item-interaction-btn"  matTooltip="Comment">
              <a [routerLink]="'/forum/'+activity.originalForumId">
                <fa-icon [icon]="faComment"></fa-icon>
                <span class="ml-1">Comment</span>
              </a>
            </div>
          </div>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
  <div *ngSwitchCase="2"> <!--wall posts replies-->
    <mat-card class="mb-3 mat-elevation-z0">
      <mat-card-header>
        <img mat-card-avatar [src]="activity?.userPicUrl"/>
        <mat-card-title><span [routerLink]="['/user', activity.userId]">{{activity.userName}}</span> replied
          <span [routerLink]="['/user', activity.originUserId]">{{activity.originUserName}}</span></mat-card-title>
        <mat-card-subtitle>{{activity.datePosted  | amFromUnix | amDateFormat:'LL'}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <a [routerLink]="'/forum/'+activity.originalForumId">
          <p [innerHTML]="textFormatService.autoLinkText(activity.forumMessage) | nl2br"></p>
        </a>
        <div class="item-interaction-container">

          <div class="item-interaction-btn"  matTooltip="Comment">
            <a [routerLink]="'/forum/'+activity.originalForumId">
              <fa-icon [icon]="faComment"></fa-icon>
              <span class="ml-1">Comment</span>
            </a>
          </div>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
