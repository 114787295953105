import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CompanyService} from "../service/company.service";
import {CompanyModel} from "../model/company.model";
import {FellowshipModel} from "../../../model/fellowship.model";
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {TextFormatService} from "../../../service/text-format/text-format.service";

@Component({
  selector: 'app-organisation-body',
  templateUrl: './organisation-body.component.html',
  styleUrls: ['./organisation-body.component.scss']
})
export class OrganisationBodyComponent implements OnInit {

  organisation: CompanyModel;
  organisationId: number;
  fellowshipList: FellowshipModel[];

  constructor(
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private fellowshipService: FellowshipService,
    public textFormatService: TextFormatService
  ) { }

  ngOnInit() {
    this.organisationId = this.activatedRoute.snapshot.parent.params.id;
    this.getOrganisation(this.organisationId)


    this.fellowshipService.getFellowshipListForOrganisation(this.organisationId)
      .subscribe((fellowships: FellowshipModel[]) => {
        this.fellowshipList = fellowships;
      })
  }

  getOrganisation(id: number) {
    this.companyService.getCompany(id)
      .subscribe((org: CompanyModel) => {
        this.organisation = org;
      })
  }
}
