import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DirectoryHomeComponent} from "./directory-home/directory-home.component";
import {DirectoryItemComponent} from "./directory-item/directory-item.component";


const routes: Routes = [
  {
    path: '',
    redirectTo: 'directory',
    pathMatch: 'full'
  },
  {
    path: 'directory',
    component: DirectoryHomeComponent
  },
  {
    path: 'directory/:id/:name',
    component: DirectoryItemComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DirectoryRoutingModule { }
