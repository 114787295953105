import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserModel} from "../../../model/userModel";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {SetActiveForumTypeAction} from "../forum.action";
import {ForumTypeEnum} from "../../../model/forum-type.enum";

@Component({
  selector: 'app-dating-home',
  templateUrl: './dating-home.component.html',
  styleUrls: ['./dating-home.component.scss']
})
export class DatingHomeComponent implements OnInit, OnDestroy {

  activeLink = 'People';
  userSub: Subscription;
  user: UserModel;
  relationshipData: {icon: string; label: string;}[] = [
    {icon: '', label: 'Join the Christian singles network to meet like minded believers looking for love and marriage'},
    {icon: '', label: 'Post in the dating forum to interact with others in the dating network'},
    {icon: '', label: 'Prayerfully search for your potential spouse using our custom built platform for Christian singles'},
    {icon: '', label: 'Send private messages securely to other members'},
    {icon: '', label: 'Add photos to your profile and describe your Christian and personal values to stand out from the crowd'}
  ];
  relationshipFacilitiesDataSource = new MatTableDataSource([]);
  displayedCols = ['icon', 'label'];
  forumTypeId: ForumTypeEnum = ForumTypeEnum.DATING;
  constructor(private store: Store<AppState>,
              private router: Router) {
    this.userSub = this.store.select('user').subscribe(user => {
      this.user = user;
    });

    this.relationshipFacilitiesDataSource.data = this.relationshipData;
  }

  ngOnInit() {
    this.store.dispatch(new SetActiveForumTypeAction(ForumTypeEnum.DATING));
  }

  ngOnDestroy() {
  }

  isViewerInSinglesNetwork(): boolean {
    return (this.user.user && this.user.user.singlesNetworkStatus === 1)
  }

  isViewerLoggedIn(): boolean {
    return (this.user.user && this.user.user.id != undefined)
  }

  setActiveLink(link: string) {
    this.activeLink = link;
  }
}
