import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ArticleModel} from "../../../model/article.model";
import {ActivatedRoute} from "@angular/router";
import {ArticleService, PicTypeEnum} from "../../../service/article/article.service";
import {Subscription} from "rxjs";
import {UserDataModel} from "../../../model/userModel";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-article-picture-edit',
  templateUrl: './article-picture-edit.component.html',
  styleUrls: ['./article-picture-edit.component.scss']
})
export class ArticlePictureEditComponent implements OnInit {

  formGroup: FormGroup;
  article: ArticleModel;
  headerPhoto: File;
  displayPhoto: File;
  section1Photo: File;
  section2Photo: File;
  section3Photo: File;
  picType: PicTypeEnum;

/*  @select((s: IAppState) => s.user)
  userObservable: Observable<UserModel>;*/

  userSub: Subscription;
  userData: UserDataModel;

  constructor(private activatedRoute: ActivatedRoute,
              private articleService: ArticleService,
              private store: Store<AppState>) {

    store.select('user').subscribe(user => {
      if (user.user) {
        this.userData = user.user;
      }
    })
/*    this.userSub = this.userObservable.subscribe(user => {
      if (user.user) {
        this.userData = user.user;
      }
    })*/
  }

  ngOnInit() {
    this.getArticle(this.activatedRoute.snapshot.params.id);
  }

  private getArticle(id: number) {
    this.articleService.getArticle(id)
      .subscribe((article: ArticleModel) => {
        this.article = article;
      })
  }

  onChangeHeaderPhoto(event) {
    this.headerPhoto = event.target.files[0];
  }

  onChangeDisplayPhoto(event) {
    this.displayPhoto = event.target.files[0];
  }

  onChangeSection1Photo(event) {
    this.section1Photo = event.target.files[0];
  }

  onChangeSection2Photo(event) {
    this.section2Photo = event.target.files[0];
  }

  onChangeSection3Photo(event) {
    this.section3Photo = event.target.files[0];
  }
  submit(photo: File){
    let form = new FormData();
    if (photo) {
      form.append('pic', photo, photo.name);
    }else{
      // form.append('pic', undefined, undefined);
    }

    this.articleService.editArticlePhotos(this.article.id, form, this.picType)
      .subscribe(res => {
      })
  }

  updateHeader() {
    this.picType = PicTypeEnum.HEADER;
    this.submit(this.headerPhoto);
  }

  updateDisplay() {
    this.picType = PicTypeEnum.DISPLAY;
    this.submit(this.displayPhoto);
  }

  updateSection1() {
    this.picType = PicTypeEnum.SECTION_1;
    this.submit(this.section1Photo);
  }

  updateSection2() {
    this.picType = PicTypeEnum.SECTION_2;
    this.submit(this.section2Photo);
  }

  updateSection3() {
    this.picType = PicTypeEnum.SECTION_3;
    this.submit(this.section3Photo);
  }
}
