import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../login.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    code: new FormControl('', [Validators.required])
  });
  resetFail = false;
  resetSuccess = false;
  faSpinner;
  spinnerOn = false;
  constructor(private activatedRoute: ActivatedRoute,
              private loginService: LoginService) {
    this.faSpinner = faSpinner;
  }

  ngOnInit() {
    this.email.setValue(this.activatedRoute.snapshot.params.email);
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get code(): AbstractControl {
    return this.form.get('code');
  }

  submit() {
    if (this.form.invalid) {
      return false;
    }
    this.spinnerOn = true;
    this.resetFail = false;
    this.resetSuccess = false;
    this.loginService.resetPassword(this.form.value)
      .subscribe(res => {
          this.resetSuccess = true;
          this.spinnerOn = false;
      },
        error => {
        this.spinnerOn = false;
          this.resetFail = true;
        })

  }

}
