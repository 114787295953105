import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FollowModel} from "../../../model/follow.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {Subscription} from "rxjs";
import {UserDataModel} from "../../../model/userModel";
import {ActivatedRoute, UrlSegment} from "@angular/router";

@Component({
  selector: 'app-user-network',
  templateUrl: './user-network.component.html',
  styleUrls: ['./user-network.component.scss']
})
export class UserNetworkComponent implements OnInit {

  displayedColumns: string[] = ['id', 'userId'];
  dataSource: FollowModel[] = [];
  userBeingViewedSub: Subscription;
  userBeingViewed: UserDataModel;
  networkMode: string;
  constructor(private store: Store<AppState>, private activatedRoute: ActivatedRoute) {
    this.store.select(state => state.user.userInView)
      .subscribe(user => {
        this.userBeingViewed = user;
      })
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((segment: UrlSegment[]) => {
      this.networkMode = segment[0].path;
    });
  }

}
