import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "../../../service/user/user.service";
import {MessageModel} from "../../../model/message.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface IdData{
  fromId: number;
  toId: number;
}

@Component({
  selector: 'app-user-message-create',
  templateUrl: './user-message-create.component.html',
  styleUrls: ['./user-message-create.component.scss']
})
export class UserMessageCreateComponent implements OnInit {

  messageForm = new FormGroup({
    message: new FormControl('', [Validators.required]),
    }
  )
  errorMsg = 'Enter a message';
  constructor(private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private store: Store<AppState>,
              private _snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<UserMessageCreateComponent>,
              @Inject(MAT_DIALOG_DATA) public idData: IdData,) {
    /*store.select('user')
      .subscribe((userModel: UserModel) => {
        if (userModel) {
          this.from = userModel.user.id;
        }
      })*/
  }

  ngOnInit(): void {
    // this.to = this.activatedRoute.snapshot.params.toId;
  }
/*
  set from(fromId: number){
    this.messageForm.get('from').setValue(fromId);
  }

  get from(): number{
    return +this.messageForm.get('from').value;
  }

  set to(toId: number) {
    this.messageForm.get('to').setValue(toId);
  }

  get to():number {
    return +this.messageForm.get('to').value;
  }*/

  get message(): string {
    return this.messageForm.get('message').value.trim();
  }

  set message(val: string) {
    this.messageForm.get('message').setValue(val);
  }

  postMessage(){
    if (this.messageForm.invalid) {
      this.messageForm.markAllAsTouched();
      this.messageForm.markAsDirty();
      this.errorMsg = 'There was a problem sending message';
      return;
    }
    if ((this.idData.fromId === this.idData.toId) || this.messageForm.invalid || this.message.length < 1) {
      this.messageForm.markAllAsTouched();
      this.messageForm.markAsDirty();
      this.errorMsg = 'There was a problem sending message';
      return;
    }
    this.userService.postUserMessage({toId: this.idData.toId, fromId: this.idData.fromId, message: this.message})
      .subscribe((message: MessageModel) => {
          this.message = '';
          this.messageForm.markAsUntouched();
          this.messageForm.markAsPristine();
          this.dialogRef.close();
          this.openSnackBar('Message was successfully sent');
      },
      error => {
        this.message = '';
        this.messageForm.markAllAsTouched();
        this.messageForm.markAsDirty();
        this.errorMsg = 'There was an error sending your message.';
        this.openSnackBar('There was an error sending your message.');
      });
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(msg: string){
    this._snackBar.open(msg,'', {
      duration: 4000
    });
  }

}
