import { Component, OnInit } from '@angular/core';
import {NewsModel} from "../../../model/news.model";
import {NewsService} from "../service/news.service";

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  news: NewsModel[] = [];
  topNews: NewsModel;
  pageNumber = 0;
  constructor(private newsService: NewsService) {

  }

  ngOnInit() {
    this.getNews(this.pageNumber);
  }

  getNews(pageNumber: number) {
    this.newsService.getNewsPage(pageNumber)
      .subscribe((newsList: NewsModel[]) => {
        if (!this.topNews) {
          this.topNews = newsList.shift();
        }
        this.news = this.news.concat(newsList);
      })
  }

  more() {
    this.getNews(++this.pageNumber)
  }
}
