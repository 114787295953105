<div class="mt-5">
  <app-forum-post-panel *ngIf="userData?.id === userBeingViewedData?.id"
                        [includeSinglesOptions]="userService.isUserInSinglesGroup(userData)"></app-forum-post-panel>
  <div *ngFor="let activity of activities">
    <app-activity-item [activityItem]="activity"></app-activity-item>
  </div>
  <div class=" d-flex justify-content-center my-5">
    <button class="btn btn-lg btn-secondary" (click)="nextActivitiesPage()">More</button>
  </div>
</div>



