<div>
  <div class="row p-5 mt-3 user-header">
    <div class="col-sm-12 col-md-4">
      <img class="card-img-top rounded-circle shadow-lg"
           alt="header image"
           [src]="userViewableData?.userHeaderPic"
           data-holder-rendered="true"
           style="height: 225px;
                 width: 100%;
                 display: block;"/>
    </div>
    <div class="col-sm-12 col-md-8">




<!--      <div *ngIf="userService.isUserAuthenticated() && userService.isUserBeingViewedSameAsLoggedInUser(userViewableData?.id)"-->
      <div *ngIf="this.showProfileSettings"
           class="d-flex flex-row justify-content-start mt-sm-3">
        <button mat-fab aria-label="Home" color="primary" routerLink=".">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-fab aria-label="Settings" color="primary" class="ml-3" routerLink="settings">
          <mat-icon>settings</mat-icon>
        </button>
        <!--<button mat-fab aria-label="Notification" color="primary" class="ml-3">
          <mat-icon>notifications</mat-icon>
        </button>-->
        <button mat-fab aria-label="Mail" color="primary" class="ml-3" routerLink="messages">
          <mat-icon>mail</mat-icon>
        </button>
        <button *ngIf="userService.isUserAuthenticated() && userService.isUserAdminRole()"
                mat-fab aria-label="Manage articles"
                color="primary" class="ml-3"
                routerLink="articles">
          <mat-icon>feed</mat-icon>
        </button>
        <button *ngIf="userService.isUserAuthenticated() && userService.isUserAdminRole()"
                mat-fab aria-label="Directory"
                color="primary" class="ml-3"
                routerLink="directory">
          <mat-icon>storefront</mat-icon>
        </button>
      </div>


      <h1  class="bol mat-h1">{{userViewableData?.name}}</h1>
      <div>{{userViewableData?.location.name}}</div>
      <br/>
      <div class="d-flex flex-row justify-content-start">
        <div>
          <div class="mr-2" *ngIf="userService.isUserAuthenticated() && userData.id != userViewableData?.id && !isViewerAFollower(userViewableData?.id)">
            <button mat-flat-button color="primary" (click)="follow(userData.id, userViewableData.id)">Follow</button>
          </div>
          <div class="mr-2" *ngIf="userService.isUserAuthenticated() && userData.id != userViewableData?.id && isViewerAFollower(userViewableData?.id)">
            <button mat-flat-button color="warn" (click)="unfollow(userViewableData.id)">Unfollow</button>
          </div>
        </div>

        <div *ngIf="userService.isUserAuthenticated()">
          <button mat-flat-button (click)="openPrivateMessagingDialog()" color="primary">Message</button>
        </div>
      </div>

      <div *ngIf="isAuthenticated" class="d-flex flex-row mt-3">
        <div class="">
          <div class="d-inline" *ngIf="userViewableData?.followers?.length > 0; else noLinkFollow">
            <a mat-flat-button class="p-0 followBtn" routerLink="followers">
              <span class="ml-1 mat-body">{{userViewableData?.followers?.length}} Followers</span>
            </a>
          </div>
          <ng-template #noLinkFollow>
            <span class="ml-1 mat-body">No followers yet</span>
          </ng-template>
        </div>
        <div class="ml-3">
<!--          <span class="mat-body">{{userViewableData?.following.length}}</span>-->
          <div class="d-inline" *ngIf="userViewableData?.following?.length > 0; else noLinkFollowing">
            <a mat-flat-button class="p-0 followBtn" routerLink="following">
              <span class="ml-1 mat-body">{{userViewableData?.following?.length}} Followed</span>
            </a>
          </div>
          <ng-template #noLinkFollowing>
            <span class="ml-1 mat-body">Following no one yet</span>
          </ng-template>
        </div>
        <div class="ml-3">
          <span class="mat-body">{{userViewableData?.viewCount}} Views</span>
        </div>
      </div>

    </div>
  </div>





<!--  <div class="row">
    <div class="col-sm-0 col-md-1"></div>
    <div class="col-sm-12 col-md-10">
      <mat-tab-group mat-align-tabs="center">
        <mat-tab>
          <ng-template mat-tab-label>
            <fa-icon [icon]="faNewspaper"></fa-icon>
            <span class="ml-1">Posts</span>
          </ng-template>
          <app-user-view-home [userBeingViewedData]="userViewableData"></app-user-view-home>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <fa-icon [icon]="faAddressCard"></fa-icon>
            <span class="ml-1">About</span>
          </ng-template>
          <app-user-view-about [userBeingViewedData]="userViewableData"></app-user-view-about>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <fa-icon [icon]="faImages"></fa-icon>
            <span class="ml-1">Photos</span>
          </ng-template>
          <app-user-gallery [userBeingViewedData]="userViewableData"></app-user-gallery>
        </mat-tab>
        <mat-tab *ngIf="viewerIsLoggedInUser()">
          <ng-template mat-tab-label>
            <fa-icon [icon]="faImages"></fa-icon>
            <span class="ml-1">Search</span>
          </ng-template>
          <app-user-search [searchMode]="nonSingleSearchMode"></app-user-search>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="col-sm-0 col-md-1"></div>
  </div>-->





  <div class="row mx-3 mx-md-0">
    <div class="col-sm-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
