import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ActivityTransformer} from "../../transformer/activity-transformer";
import {ActivityModel} from "../../model/activity.model";
import {ForumTypeEnum} from "../../model/forum-type.enum";

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private activityApi = "/web/api";
  constructor(private httpClient: HttpClient) {

  }

  getActivities(userId: number, page: number, includeUserNetwork: boolean, forumTypeId: ForumTypeEnum): Observable<ActivityModel[]>{
    const userParam: string = userId ? `&userId=${userId}` : '';
    const includeUserNetworkParam: string = `&includeUserNetwork=${includeUserNetwork}`;
    const forumTypeParam = forumTypeId == ForumTypeEnum.ALL ? '' : `&forumTypeId=${forumTypeId}`
    const url: string = encodeURI(`${this.activityApi}/activity?pageNumber=${page}${userParam}${includeUserNetworkParam}${forumTypeParam}`);
    return this.httpClient.get<ActivityModel[]>(url)
      .pipe(
        map(response => {
          return ActivityTransformer.convertArrayToActivityModel(response)
        })
      );
  }
}
