<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div>
      <!--headline news-->
      <div class="row py-5">
        <div class="col-sm-12 col-md-4">
          <a [routerLink]="'/article/'+topArticle?.id">
            <img class="card-img-top"
                 data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
                 alt="header image"
                 [src]="topArticle?.displayPic"
                 data-holder-rendered="true"
                 style="height: 225px;
                 width: 100%;
                 display: block;">
          </a>
        </div>
        <div class="col-sm-12 col-md-8">
          <a [routerLink]="'/article/'+topArticle?.id" class="display-4">{{topArticle?.subject}}</a>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-sm-12 col-md-9">
          <!--central mid section-->
          <div class="row">
            <div *ngFor="let articleItem of articles" class="col-sm-12 col-md-4">
              <div class="card mb-4 box-shadow border-0">
                <a [routerLink]="'/article/'+articleItem?.id">
                  <img class="card-img-top custom-img-fluid shadow"
                       alt="article pic"
                       [src]="articleItem?.displayPic"
                  >
                </a>
                <div class="card-body pl-0 pr-0 pt-2 pb-0">
                  <a [routerLink]="'/article/'+articleItem?.id"><h5
                    class="card-title">{{articleItem?.subject}}</h5></a>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center w-100">
              <a *ngIf="pageNumber > 0" mat-button mat-raised-button [href]="'/articles/'+prevPage" class="mr-3">Back</a>
              <a mat-button mat-raised-button [href]="'/articles/'+nextPage">Next</a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-3">
          <!--events/adverts etc-->
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>



