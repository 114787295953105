<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="d-flex flex-row justify-content-center">
      <div *ngIf="networkMode === 'followers'; else following">
        <div class="mb-3">
          <a [routerLink]="'/user/'+userBeingViewed.id"><h2 class="d-inline">{{userBeingViewed.name}}</h2></a>  is followed by
        </div>
        <table>
          <tr *ngFor="let follower of userBeingViewed.followers" class="mb-3">
            <td>
              <mat-card>
                <mat-card-title>
                  <a [routerLink]="'/user/'+follower.userId"><span>{{follower.name}}</span></a>
                </mat-card-title>
                <mat-card-content></mat-card-content>
              </mat-card>
            </td>
          </tr>
        </table>
      </div>
      <ng-template #following>
        <div>
          <div class="mb-3">
            <a [routerLink]="'/user/'+userBeingViewed.id"><h2 class="d-inline">{{userBeingViewed.name}}</h2></a>  is following
          </div>
          <table>
            <tr *ngFor="let following of userBeingViewed.following" class="mb-3">
              <td>
                <mat-card>
                  <mat-card-title>
                    <a [routerLink]="'/user/'+following.followingId"><span>{{following.name}}</span></a>
                  </mat-card-title>
                  <mat-card-content>
                  </mat-card-content>
                </mat-card>
              </td>
            </tr>
          </table>
        </div>
      </ng-template>
    </div>


  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
