<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="d-flex flex-row justify-content-center">
      <div class="d-flex flex-column justify-content-center h-100">
        <iframe *ngFor="let vodcast of vodcasts" width="560" height="315" class="my-3"
                [src]="sanitiseVideoUrl(vodcast.embedUrl)"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>

        </iframe>
      </div>

    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
