import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectoryRoutingModule } from './directory-routing.module';
import { DirectoryHomeComponent } from './directory-home/directory-home.component';
import { UserDirectoryMgmtComponent } from './user-directory-mgmt/user-directory-mgmt.component';
import {StoreModule} from "@ngrx/store";
import {reducer} from "./directory.reducer";
import {MatButtonModule} from "@angular/material/button";
import { DirectoryItemComponent } from './directory-item/directory-item.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {Nl2BrPipeModule} from "nl2br-pipe";


@NgModule({
  declarations: [DirectoryHomeComponent, UserDirectoryMgmtComponent, DirectoryItemComponent],
    imports: [
        CommonModule,
        DirectoryRoutingModule,
        StoreModule.forFeature('directory', reducer),
        MatButtonModule,
        FontAwesomeModule,
        Nl2BrPipeModule
    ],
  exports: [UserDirectoryMgmtComponent]
})
export class DirectoryModule { }
