import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ArticleModel} from "../../model/article.model";
import {HttpClient} from "@angular/common/http";
import {ArticleCreateModel} from "../../model/article-create.model";
import {ArticleTypeModel} from "../../model/article-type.model";
import {ArticleReplyModel} from "../../model/article-reply.model";
import {ArticleTransfromer} from "../../transformer/article-transfromer";
import {map} from "rxjs/operators";

export enum PicTypeEnum {
  DISPLAY = 'DISPLAY',
  HEADER = 'HEADER',
  SECTION_1 = 'SECTION_1',
  SECTION_2 = 'SECTION_2',
  SECTION_3 = 'SECTION_3'
}
@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private articleApi = "/web/api";
  constructor(private httpClient: HttpClient) { }

  public getArticles(pageNumber: number, articleTypeId?: number): Observable<ArticleModel[]>{
    const homePageUrl = `${this.articleApi}/article/list/${pageNumber}`;
    let params = {};
    if (articleTypeId == undefined) {
      params = null;
    }else{
      params = {'articleTypeId': articleTypeId + ''};
    }

    return this.httpClient.get<ArticleModel[]>(homePageUrl, {
      params
    });
  }

  public getArticle(id: number): Observable<ArticleModel>{
    const homePageUrl = `${this.articleApi}/article/${id}`;
    return this.httpClient.get<ArticleModel>(homePageUrl)
      .pipe(
        map(res=>{
          return ArticleTransfromer.transformToArticleModel(res);
        })
      );
  }

  public postArticles(article: ArticleCreateModel): Observable<ArticleModel> {
    const url = `${this.articleApi}/article`;
    return this.httpClient.post<ArticleModel>(url, article);
  }

  public getArticleTypes(): Observable<ArticleTypeModel[]> {
    const url = `${this.articleApi}/article-type/all`;
    return this.httpClient.get<ArticleTypeModel[]>(url);
  }

/*  public editArticlePhotos(articleId: number,userId: number, formData: FormData): Observable<ArticleModel> {
    const url = `/${environment.webApiNamespace}/article/photo/${articleId}/user/${userId}`;
    return this.httpClient.put<ArticleModel>(url, formData);
  }*/

  public editArticlePhotos(articleId: number, formData: FormData, picType: PicTypeEnum): Observable<ArticleModel> {
    const url = `${this.articleApi}/article/photo/${picType}/${articleId}`;
    return this.httpClient.put<ArticleModel>(url, formData);
  }

  public editArticle(articleId: number, articleData: ArticleCreateModel): Observable<ArticleModel> {
    const url = `${this.articleApi}/article/${articleId}`;
    return this.httpClient.put<ArticleModel>(url, articleData);
  }

  public postArticleReply(articleId: number, userId: number, comment: string): Observable<ArticleReplyModel> {
    const url = `${this.articleApi}/article_reply`;
    const data = {
      userId: userId,
      comment: comment,
      articleId: articleId
    };
    return this.httpClient.post<ArticleReplyModel>(url, data)
      .pipe(
        map(jsonResponse => {
          return ArticleTransfromer.transformToArticleReply(jsonResponse);
        })
      );
  }
}
