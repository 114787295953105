<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <!--<mat-tab-group color="primary" (selectedTabChange)="onSelectionChange($event)" animationDuration="0ms"
                   mat-align-tabs="center">
      <mat-tab (click)="applyFilterBtn('fellowship')" label="fello">
        <ng-template mat-tab-label>
          <fa-icon [icon]="faChurch"></fa-icon>
          <span class="ml-1">Forum</span>
        </ng-template>
        <app-forum-stream #forumStream [forumTypeId]="forumTypeId"></app-forum-stream>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon [icon]="faUsers"></fa-icon>
          <span class="ml-1">People</span>
        </ng-template>
        <app-user-search [searchMode]="nonSingleSearchMode"></app-user-search>
      </mat-tab>
    </mat-tab-group>-->
    <router-outlet></router-outlet>
  </div>


  <!--<div class="row px-xs-5">
    <div class="col-md-1"></div>
    <div class="col-sm-12 col-md-10">
      <div>
        <div *ngIf="activeForumType === singlesForumType">
          <app-dating-home></app-dating-home>
        </div>

        &lt;!&ndash;content area&ndash;&gt;
        <div class="row">
        </div>

      </div>
    </div>
    <div class="col-sm-1"></div>
  </div>-->
</div>



